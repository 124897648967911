<div appBsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #editForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header pb-0">
                    <h2 class="modal-title">
                        <span>{{ 'NotificationSettings' | localize }}</span>
                    </h2>
                    <button type="button" class="btn-modal-help" (click)="showHelp()" [title]="'Help' | localize" [attr.aria-label]="'Help' | localize">
                        <i class="ds-icon-default-circle-question"></i>
                    </button>
                    <button type="button" class="btn-close" (click)="close()" [title]="'Close' | localize" [attr.aria-label]="'Close' | localize" [disabled]="saving"></button>
                </div>
                <div class="modal-body pt-0 pb-3" *ngIf="settings">
                    <div class="row my-1">
                        <div class="col-12">
                            <h4>{{ 'ReceiveNotifications' | localize }}</h4>
                            <div class="form-group">
                                <label class="form-check form-check-custom form-check-solid form-switch py-1">
                                    <input type="checkbox" name="ReceiveNotifications" class="form-check-input"
                                        [(ngModel)]="settings.receiveNotifications" value="true" />
                                    <span class="form-check-label">
                                        ({{ 'ReceiveNotifications_Definition' | localize }})
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="col-12">
                            <h4 *ngIf="settings.notifications.length" class="mt-4">{{ 'NotificationTypes' | localize }}</h4>
                            <p *ngIf="settings.notifications.length && !settings.receiveNotifications" class="text-danger">
                                <small>{{ 'ReceiveNotifications_DisableInfo' | localize }}</small>
                            </p>
                            <div class="form-group">
                                <label class="form-check form-check-custom form-check-solid py-1" *ngFor="let notification of settings.notifications">
                                    <input type="checkbox" id="Notification_{{ notification.name }}" name="Notification_{{ notification.name }}"
                                        class="form-check-input" [(ngModel)]="notification.isSubscribed" [disabled]="!settings.receiveNotifications" />
                                    <span class="form-check-label">
                                        {{ notification.displayName }}
                                        <span *ngIf="notification.description" class="help-block">
                                            {{ notification.description }}
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="d-inline-block">
                        <button type="button" class="ds outlined dark me-3 min-width" [disabled]="saving" (click)="close()">
                            {{ 'Cancel' | localize }}
                        </button>
                        <button type="submit" class="ds min-width">
                            {{ 'Save' | localize }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
