import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class SideMenuService {

  private collapseAction$ = new Subject<any>();
  collapse$ = this.collapseAction$.asObservable();
  private expandAction$ = new Subject<any>();
  expand$ = this.expandAction$.asObservable();
  
  constructor() {
  }

  collapseMenu() {
    this.collapseAction$.next(null);
  }

  expandMenu() {
    this.expandAction$.next(null);
  }
}