import { _isNumberValue } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomComponentBase } from '@shared/common/custom-component-base';

@Component({
    selector: 'eleco-pattern-picker',
    templateUrl: './eleco-pattern-picker.component.html',
    styleUrls: ['./eleco-pattern-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ElecoPatternPickerComponent),
            multi: true
        }
    ]
})
export class ElecoPatternPickerComponent extends CustomComponentBase implements OnInit {

    sampleBgImage: string = '';
    sampleBgColor: string = '';
    sampleText: string = '';

    private _value: number = -1;
    @Input() get value(): number {
        return this._value;
    }
    set value(val: number) {
        this._value = val;
        this.updateSample();
    }

    @Input() id: string = '';
    @Input() name: string = '';

    private _foreColor: string = 'black'
    @Input() get foreColor(): string {
        return this._foreColor;
    }
    set foreColor(val: string) {
        this._foreColor = val;
        this.updateSample();
    }

    private _bgColor: string = 'white';
    @Input() get bgColor(): string {
        return this._bgColor;
    }
    set bgColor(val: string) {
        this._bgColor = val;
        this.updateSample();
    }

    @Output() change = new EventEmitter<number>();

    patterns: BarPattern[] = [];

    constructor(private injector: Injector, private _renderer: Renderer2, private _elementRef: ElementRef) {
        super(injector);
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
    }

    ngOnInit(): void {
        this.sampleText = this.l('BarPatternSampleText');
        for (let i = 0; i < 42; i++) {
            this.patterns.push(new BarPattern(i, `/assets/common/images/BarPatterns/BarPattern_${i}.png`));
        }
    }

    //BEGIN: ControlValueAccessor implementation
    onChange: any = () => { }
    onTouch: any = () => { }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: number) {
        this.value = value;
    }
    //END: ControlValueAccessor implementation

    updateSample(): void {
        this.sampleBgColor = this.bgColor;

        if (this.isNullOrUndefinedOrNaN(this.value)) {
            this.sampleBgImage = 'none';
            return;
        }

        switch (this.value) {
            case 0: //pattern 0 = Background colour (no pattern)
                this.sampleBgImage = 'none';
                break;

            case 8: //pattern 8 = Foreground colour (no pattern)
                this.sampleBgColor = this.foreColor;
                this.sampleBgImage = 'none';
                break;

            case 41: //pattern 41 = transparent (no pattern)
                this.sampleBgColor = 'transparent';
                this.sampleBgImage = 'none';
                break;

            default:
                this.sampleBgImage = `url('${this.patterns[this.value].patternImgUrl}')`;
        }
    }

    patternClick(pattern: BarPattern): void {
        this.value = pattern.patternId;
        this.onChange(this.value); //<-- this is vital to pushing the changes out to the model binding
        this.change.emit(this.value);
    }
}

export class BarPattern {
    patternId: number;
    patternImgUrl: string;
    constructor(id: number, imgUrl: string) {
        this.patternId = id;
        this.patternImgUrl = imgUrl;
    }
}
