<div class="user-select-dropdown" dropdown #dropdown="bs-dropdown" [insideClick]="true" 
    [autoClose]="true" (isOpenChange)="dropdownChange($event)">
    <div class="input-group">
        <input type="text" [id]="id ? id : null" [name]="name ? name : null" class="form-control"
            [(ngModel)]="displayCount" (click)="readonly || disabled ? undefined : dropdownToggleButton.click()" 
            readonly [disabled]="disabled ? true : null" [attr.isreadonly]="readonly" />
        <button type="button" [class]="disabled || readonly ? 'hidden' : null" dropdownToggle #dropdownToggleButton 
            class="dropdown-toggle" aria-controls="projecttag-dropdown">
            <i class="ds-icon-default-chevron-down font-bold"></i>
        </button>
        <div class="singleselect-user" *ngIf="!multiSelect && selectedId && selectedId != 0">
            <user-monogram [userName]="selectedUser.fullName" [compact]="true"></user-monogram>
            <span class="username" >{{ selectedUser.fullName }}</span>
        </div>
    </div>
    <div id="user-dropdown" *dropdownMenu class="dropdown-menu">
        <div class="user-container">
            <div class="user-list">
                <div *ngFor="let user of sortedUserList;">
                    <label class="list-entry">
                        <input type="checkbox" [id]="id ? id + '_chkUser' : 'chkUser'" name="chkUser" *ngIf="multiSelect" [(ngModel)]="user.selected" (change)="selectChanged()" />
                        <input type="radio" [id]="id ? id + '_' : '' + 'radUser_' + user.id" name="radUser" [value]="user.id" *ngIf="!multiSelect" 
                            [(ngModel)]="selectedId" (change)="selectChanged()" />
                        <user-monogram [userName]="user.fullName"></user-monogram>
                        <span class="username">{{ user.fullName }}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>