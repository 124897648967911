import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProjectTagDto } from '@shared/service-proxies/service-proxies';
import { ProjectTagSelectDropdownComponent } from '@app/shared/common/projecttag-select-dropdown/projecttag-select-dropdown.component';

@Component({
    selector: 'projecttag-link-manager',
    templateUrl: './projecttag-link-manager.component.html',
    styleUrls: ['./projecttag-link-manager.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectTagLinkManagerComponent),
            multi: true
        }
    ]
})
export class ProjectTagLinkManagerComponent implements OnInit { //OnChanges

    //this component won't add/remove/create links, it will either
    //just display the existing links, or also allow the user to
    //add/remove links from the collection - it is up to the host
    //component to update the links from the collection if the user
    //chooses to save the changes

    @ViewChild('projectTagSelectControl', { static: true }) projectTagSelectControl: ProjectTagSelectDropdownComponent;

    private MIN_HEIGHT: string = '250px';

    @Input() id: string = '';
    @Input() name: string = '';
    @Input() readonly: boolean = false;
    @Input() showMultiSelectCount: boolean = true;
    @Input() height: string = this.MIN_HEIGHT;

    private _linkedProjectId: number = 0;
    @Input() get linkedProjectId() {
        return this._linkedProjectId;
    }
    set linkedProjectId(value: number) {
        this._linkedProjectId = value;
        this.refreshProjectTagList();
    }

    private _linkedProjectTags: ProjectTagDto[] = [];
    @Input() get linkedProjectTags() {
        return this._linkedProjectTags;
    }
    set linkedProjectTags(value: ProjectTagDto[]) {
        this._linkedProjectTags = value;
        this.refreshProjectTagList();
    }

    private _allProjectTags: ProjectTagDto[] = [];
    @Input() get allProjectTags() {
        return this._allProjectTags;
    }
    set allProjectTags(value: ProjectTagDto[]) {
        this._allProjectTags = value;
        this.refreshProjectTagList();
    }

    @Output() changesMade = new EventEmitter();

    projectTagsList: ProjectTagDto[] = [];
    addSelectedProjectTagId: number = 0;

    public get dropdownOpen(): boolean {
        return this.projectTagSelectControl ? this.projectTagSelectControl.dropdownOpen : false;
    }

    constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
    }

    ngOnInit(): void {

    }

    // ngOnChanges(changes: SimpleChanges): void {

    // }

    refreshProjectTagList(): void {
        //get the difference after intesecting the two arrays
        if (this._linkedProjectTags == null) { this._linkedProjectTags = []; }
        if (this._allProjectTags == null) { this._allProjectTags = []; }
        try {
            this.projectTagsList = this._allProjectTags.filter(x => !this._linkedProjectTags.map(t => t.id).includes(x.id));
        } catch (error) {
        }
    }

    projectTagSelectionChanged(data: number) {
        //this.addSelectedProjectTagId = data;
        //this.refreshProjectTagList();
        this.addSelectedProjectTag();
        //setTimeout(() => this.addSelectedProjectTag(), 5);
    }

    formatHeight(): string {
        if (this.height == null || typeof this.height === 'undefined') {
            this.height = this.MIN_HEIGHT;
        }

        this.height = this.height.trim().toLowerCase();

        if (this.height.indexOf('px') == this.height.length - 2) { return this.height; }
        if (this.height.indexOf('%') == this.height.length - 1) { return this.height; }
        if (!isNaN(parseInt(this.height, 10))) { return `${this.height}px`; }

        return this.MIN_HEIGHT;
    }

    getProjectTagById(id: number): ProjectTagDto {
        let tag = this._allProjectTags.find(t => t.id == id);
        if (tag == null || typeof tag == 'undefined') { return null; }
        return tag;
    }

    getSelectedTags(): ProjectTagDto[] {
        return this.projectTagsList.filter(tag => tag.selected);
    }

    addSelectedProjectTag(): void {
        if (this.projectTagSelectControl.multiSelect) {
            let tags = this.getSelectedTags();
            if (tags.length == 0) { return; }
            tags.forEach(tag => this._linkedProjectTags.push(tag));
        } else {
            if (this.addSelectedProjectTagId == 0) { return; }
            let tag = this.getProjectTagById(this.addSelectedProjectTagId);
            if (tag == null) { return; }
            this._linkedProjectTags.push(tag);
        }

        this.projectTagSelectControl.clearSelections();
        this.refreshProjectTagList();

        this.changesMade.emit();
    }

    removeProjectTag(id: number): void {
        let idx = -1;
        for (let i = 0; i < this._linkedProjectTags.length; i++) {
            if (this._linkedProjectTags[i].id == id) {
                idx = i;
                i = this._linkedProjectTags.length;
            }
        }

        if (idx < 0) { return; }

        this._linkedProjectTags.splice(idx, 1);

        this.projectTagSelectControl.clearSelections();
        this.refreshProjectTagList();

        this.changesMade.emit();
    }
}
