import { Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomComponentBase } from '@shared/common/custom-component-base';
import { LinkTypeEnum } from '@shared/service-proxies/service-proxies';
import { SizeAndPositionModel } from '@shared/SizeAndPositionModel';

@Component({
    selector: 'gantt-activity-link',
    templateUrl: './gantt-activity-link.component.html',
    styleUrls: ['./gantt-activity-link.component.scss']
})
export class GanttActivityLinkComponent extends CustomComponentBase implements OnInit, OnChanges {

    @Input() id: number;
    @Input() activityId: number;
    @Input() parentActivityId: number;
    @Input() linkType: number; //same link type values as ActivityLinkDto
    @Input() linkLag: number;
    @Input() bringToForeground: boolean = false;
    @Input() highlight: boolean = false;
    @Input() visible: boolean = false;

    linkLinePath: string = 'M0 0'; // 'M200 50 Q 63.41640786499875 10.83281572999748 100 100';
    childArrowPoints: string = '';
    svgWidth: string = '100%';
    svgHeight: string = '100%';
    lineColor: string = 'black';
    

    parentPointX: number = 0;
    parentPointY: number = 0;
    parentSPM: SizeAndPositionModel;
    childPointX: number = 0;
    childPointY: number = 0;
    childSPM: SizeAndPositionModel;

    readonly MarkerDot = 0;
    readonly MarkerLeftArrow = 1;
    readonly MarkerRightArrow = 2;
    startMarker: number = this.MarkerDot;
    endMarker: number = this.MarkerDot;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes) { return; }

        if (changes.highlight) {
            this.lineColor = (this.highlight ? 'orange' : 'black');
        }
    }

    public drawLink(parentPosition: SizeAndPositionModel, childPosition: SizeAndPositionModel): void {
        switch (this.linkType) {

            case LinkTypeEnum.FS:
                //from the finish of the parent task to the start of the child task
                //dot marker on the finish of the parent task
                //arrow marker on the start of the child task
                this.startMarker = this.MarkerDot;
                this.endMarker = this.MarkerRightArrow;
                break;

            case LinkTypeEnum.SF:
                //from the start of the parent task to the finish of the child task
                //dot marker on the start of the parent task
                //arrow marker on the finish of the child task
                this.startMarker = this.MarkerDot;
                this.endMarker = this.MarkerLeftArrow;
                break;

            case LinkTypeEnum.SS:
                //from the start of the parent task to the start of the child task
                //dot marker on the start of the parent task
                //arrow marker on the start of the child task
                this.startMarker = this.MarkerDot;
                this.endMarker = this.MarkerRightArrow;
                break;

            case LinkTypeEnum.FF:
                //from the finish of the parent task to the finish of the child task
                //dot marker on the finish of the parent task
                //arrow marker on the finish of the child task
                this.startMarker = this.MarkerDot;
                this.endMarker = this.MarkerLeftArrow;
                break;
        }
        
        this.setParentXY(parentPosition);
        this.setChildXY(childPosition);
        this.setSVG();

        this.visible = true;
    }

    private setParentXY(pos: SizeAndPositionModel): void {
        this.parentSPM = pos;
        switch (this.linkType) {
            case LinkTypeEnum.FS:
            case LinkTypeEnum.FF:
                this.parentPointX = pos.left + pos.width + pos.xOffset;
                break;
            case LinkTypeEnum.SF:
            case LinkTypeEnum.SS:
                this.parentPointX = pos.left + pos.xOffset;
                break;
        }
        this.parentPointY = pos.top + pos.yOffset + (pos.height / 2);
    }

    private setChildXY(pos: SizeAndPositionModel): void {
        this.childSPM = pos;
        switch (this.linkType) {
            case LinkTypeEnum.FS:
            case LinkTypeEnum.SS:
                this.childPointX = pos.left + pos.xOffset;
                break;
            case LinkTypeEnum.SF:
            case LinkTypeEnum.FF:
                this.childPointX = pos.left + pos.width + pos.xOffset;
                break;
        }
        this.childPointY = pos.top + pos.yOffset + (pos.height / 2);
    }

    private setSVG(): void {
        let ext = 100; //bezier curve extension
        if (this.parentPointY == this.childPointY && this.parentPointX < this.childPointX) {
            ext = 0;
        } else if (Math.abs(this.parentPointX - this.childPointX) <= ext) {
            ext = ext * 0.4;
        }

        if ((this.linkType == LinkTypeEnum.FF || this.linkType == LinkTypeEnum.SS)
            && Math.abs(this.parentPointX - this.childPointX) <= 30) {
            ext = 30;
        }

        let leader = 10;

        //starting coordinate
        this.linkLinePath = `M${this.parentPointX},${this.parentPointY} `;

        switch (this.linkType) {
            case LinkTypeEnum.FS:
                this.linkLinePath += `L${this.parentPointX + leader},${this.parentPointY} `;
                this.linkLinePath += `C${this.parentPointX + ext},${this.parentPointY} ${this.childPointX - ext},${this.childPointY} ${this.childPointX - leader},${this.childPointY} `;
                break;
            case LinkTypeEnum.FF:
                this.linkLinePath += `L${this.parentPointX + leader},${this.parentPointY} `;
                this.linkLinePath += `C${this.parentPointX + ext},${this.parentPointY} ${this.childPointX + ext},${this.childPointY} ${this.childPointX + leader},${this.childPointY} `;
                break;
            case LinkTypeEnum.SF:
                this.linkLinePath += `L${this.parentPointX - leader},${this.parentPointY} `;
                this.linkLinePath += `C${this.parentPointX - ext},${this.parentPointY} ${this.childPointX + ext},${this.childPointY} ${this.childPointX + leader},${this.childPointY} `;
                break;
            case LinkTypeEnum.SS:
                this.linkLinePath += `L${this.parentPointX - leader},${this.parentPointY} `;
                this.linkLinePath += `C${this.parentPointX - ext},${this.parentPointY} ${this.childPointX - ext},${this.childPointY} ${this.childPointX - leader},${this.childPointY} `;
                break;
        }

        //finishing line
        this.linkLinePath += `L${this.childPointX},${this.childPointY}`;

        if (this.endMarker == this.MarkerRightArrow) {
            this.childArrowPoints = `${this.childPointX},${this.childPointY} ${this.childPointX - 10},${this.childPointY - 5} ${this.childPointX - 10},${this.childPointY + 5}`;
        } else if (this.endMarker == this.MarkerLeftArrow) {
            this.childArrowPoints = `${this.childPointX},${this.childPointY} ${this.childPointX + 10},${this.childPointY - 5} ${this.childPointX + 10},${this.childPointY + 5}`;
        }

        //adapt container width & height
        // this.svgWidth = `${this.chooseGreater(this.parentSPM.left + this.parentSPM.width + (ext / 2), this.childSPM.left + this.childSPM.width + (ext / 2))}px`;
        // this.svgHeight = `${this.chooseGreater(this.parentSPM.top + this.parentSPM.height + (ext / 2), this.childSPM.top + this.childSPM.height + (ext / 2))}px`;
        this.svgWidth = `${this.chooseGreater(this.parentPointX + 10, this.childPointX + 10) + (ext / 2) + 1}px`;
        this.svgHeight = `${this.chooseGreater(this.parentPointY, this.childPointY) + 10}px`;
    }

    private chooseGreater(a: number, b: number): number {
        if (a > b) { return a; }
        return b;
    }

}
