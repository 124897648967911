export class TaskStatus {
    static ToDo = 0;
    static InProgress = 1;
    static AtRiskOrBlocked = 2;
    static Complete = 3;
}

export class TaskSnapshotComparisonState {
    static NoChange = 0;
    static Added = 1;
    static Edited = 2;
}
