import { IGanttData } from "@app/shared/common/interfaces/IGanttData";
import { IGanttLink, GanttLinkConstants } from "@app/shared/common/interfaces/IGanttLink";
import { IGanttTask } from "@app/shared/common/interfaces/IGanttTask";
import { GetMasterPlanForProjectDto, ActivityLinkDto, ActivityDto, ActivityTypeEnum, IActivityDto, ICreateOrEditActivityDto, ProjectTagLinksToActivityDto, ProjectTagDto, PullPlanActivityLinkDto, PullPlanTaskDto } from "@shared/service-proxies/service-proxies";
import { DateTime } from "luxon";
import { CommonHelpers, CommonHelpersInstance } from "@shared/common/common.helpers";
import { gantt } from "dhtmlx-gantt";

export class ProjectMasterPlanHelpers {

    private static commonHelpers: CommonHelpersInstance = new CommonHelpersInstance();

    public static readonly SUBTYPE_GANTT_ACTIVITY: string = 'gantt-activity';
    public static readonly SUBTYPE_WHITEBOARD_TASK: string = 'whiteboard-task';
    public static readonly SUBTYPE_SPLIT_TASK_PARENT: string = 'split-task-parent';

    public static getParentId(task: ActivityDto, tasks: ActivityDto[]): string {
        //parent/child is determined by outlineNumber (e.g. 1.2.4)
        //and outlineLevel (e.g. 2) - in this scenario, the parent Id
        //would be the one with an outlineNumber of 1.2
        let parentId = 0;
        let parentOutlineNumber = "";
        let childOutlines = task.activityOutlineNumber.split('.');
        for (let i = 0; i < childOutlines.length && i < task.activityOutlineLevel - 1; i++) {
            if (i > 0) { parentOutlineNumber += '.'; }
            parentOutlineNumber += childOutlines[i];
        }
        var result = tasks.find(t => t.activityOutlineNumber == parentOutlineNumber);
        if (result) { parentId = result.id; }
        return (parentId == 0 ? '' : parentId.toString());
    }

    public static mapToGanttData(data: GetMasterPlanForProjectDto): IGanttData {
        let tasks = this.sortGanttTasks(data.activities.map(t => this.mapToGanttTask(t, data.activities)));

        //aggregate out all the links into their own array
        let links = data.activities
            .map(a => a.links)
            .flat()
            .map(l => this.mapToGanttLink(l));

        let startdate = this.commonHelpers.dateIfNullOrEmpty(data.projectStartDate, this.commonHelpers.today).minus({ days: 3 });
        let finishdate = this.commonHelpers.dateIfNullOrEmpty(data.projectFinishDate, this.commonHelpers.today).plus({ days: 3 });

        let ganttData: IGanttData = {
            tasks: tasks,
            links: links,
            start: startdate.toJSDate(),
            finish: finishdate.toJSDate()
        };

        return ganttData;
    }

    public static mapPullPlanActivitiesToGanttData(activityLinks: PullPlanActivityLinkDto[]): IGanttData {

        let activities: ActivityDto[] = [];
        let startdate: DateTime = null;
        let finishdate: DateTime = null;

        for(var i = 0; i < activityLinks.length; i++) {
            let act = activityLinks[i].linkActivityFk;
            activities.push(act);
            if (startdate == null || act.activityStart < startdate) {
                startdate = act.activityStart;
            }
            if (finishdate == null || act.activityFinish > finishdate) {
                finishdate = act.activityFinish;
            }
        }

        startdate = this.commonHelpers.dateIfNullOrEmpty(startdate, this.commonHelpers.today).minus({ days: 3 });
        finishdate = this.commonHelpers.dateIfNullOrEmpty(finishdate, this.commonHelpers.today).plus({ days: 3 });

        let tasks = this.sortGanttTasks(activities.map(t => this.mapToGanttTask(t, activities)));
        
        //aggregate out all the links into their own array
        let links = activities
            .map(a => a.links)
            .flat()
            .map(l => this.mapToGanttLink(l));

        let ganttData: IGanttData = {
            tasks: tasks,
            links: links,
            start: startdate.toJSDate(),
            finish: finishdate.toJSDate()
        };
        
        return ganttData;
    }

    public static MapPullPlanTasksToGanttTasks(tasks: PullPlanTaskDto[]): IGanttTask[] {
        return tasks.filter(task => !this.commonHelpers.isNullOrUndefinedOrNaNOrZero(task.taskProjectTeamId)
            && !this.commonHelpers.isNullOrUndefinedOrNaNOrZero(task.taskPullPlanActivityLinkId))
            .map(task => this.MapPullPlanTaskToGanttTask(task));
    }

    public static MapPullPlanTaskToGanttTask(task: PullPlanTaskDto): IGanttTask {
        return this.createNewGanttTask(
            `T:${task.id}`,
            //task.id.toString(),
            task.id.toString(),
            task.taskTitle,
            task.taskDate.set({ hour: 5, minute: 0, second: 0, millisecond: 0 }).toJSDate(), //helps position the task better
            task.taskDate.set({ hour: 16, minute: 0, second: 0, millisecond: 0 }).toJSDate(), //helps make the parent swimlane task wider
            undefined,
            task.taskProgress,
            false,
            `${task.taskPullPlanActivityLinkId}`,
            'task',
            false,
            false,
            this.commonHelpers.arrayHasItems(task.projectTagLinks) ? task.projectTagLinks.map(link => link.linkProjectTagFk) : [],
            false, //hide the icon
            `${task.taskProjectTeamId}.${task.taskColumnCellIndex}`,
            '',
            task.taskProjectTeamId,
            task.swimlaneIndex,
            task.swimlaneTeamTitle,
            task.swimlaneTeamColour,
            task.taskStatusId.toString(), //use key for Task Status
            this.SUBTYPE_WHITEBOARD_TASK,
            task.taskColumnCellIndex,
            false,
            task.taskLocationTitle
        );
    }

    public static sortGanttTasks(tasks: IGanttTask[]): IGanttTask[] {
        if (!this.commonHelpers.arrayHasItems(tasks)) { return tasks; }
        return tasks.sort((a: IGanttTask, b: IGanttTask) => this.sortByOutlineNumber(a.outlineNumber, b.outlineNumber));
    }

    public static sortByOutlineNumber(a: string, b: string): number {
        //return a > b ? 1 : (a < b ? -1 : 0);
        
        //we need to sort tasks by the outline details e.g. 1.1, 1.2, 1.3, 2.1, 2.2, 3.1 etc
        let aParts = a.split('.');
        let bParts = b.split('.');
        let maxParts = aParts.length > bParts.length ? aParts.length : bParts.length;
        let result = 0;

        for (let i = 0; i < maxParts; i++) {
            if (aParts.length > i && bParts.length > i) {
                if (parseInt(aParts[i], 10) > parseInt(bParts[i], 10)) {
                    result = 1;
                    break;
                } else if (parseInt(bParts[i], 10) > parseInt(aParts[i], 10)) {
                    result = -1;
                    break;
                }
            } else {
                if (aParts.length > bParts.length) {
                    result = 1;
                    break;
                } else if (bParts.length > aParts.length) {
                    result = -1;
                    break;
                }
            }
        }

        return result;
    }

    public static mapToGanttTask(task: ActivityDto, tasks: ActivityDto[]): IGanttTask {
        return this.createNewGanttTask(
            task.id.toString(),
            //task.id.toString(),
            task.id.toString(),
            task.activityName, //debug: + ' ' + task.activityOutlineNumber,
            task.activityStart.toJSDate(),
            task.activityFinish.toJSDate(),
            this.calculateGanttDuration(task.activityStart, task.activityFinish), //task.activityDuration,
            task.activityProgress,
            task.activityIsCritical,
            this.getParentId(task, tasks),
            (task.activityType == ActivityTypeEnum.Summary ? 'project' : task.activityType == ActivityTypeEnum.Milestone ? 'milestone' : ''),
            true,
            false,
            this.commonHelpers.arrayHasItems(task.projectTagLinks) ? task.projectTagLinks.map(link => link.linkProjectTagFk) : [],
            task.activityIsInActivePullPlan,
            task.activityOutlineNumber,
            '',
            0,
            0,
            '',
            '',
            '',
            this.SUBTYPE_GANTT_ACTIVITY,
            0,
            false,
            task.activityLocationTitle
        );
    }

    public static createNewGanttTask(id: string, taskId: string, text: string, startDate: Date, endDate: Date,
        duration: number, progress: number, critical: boolean, parent: string, type: string, open: boolean,
        selected: boolean, projectTags: ProjectTagDto[], isInActivePullPlan: boolean, outlineNumber: string, 
        render: string, projectTeamId: number, swimlaneIndex: number, swimlaneTeamTitle: string, swimlaneTeamColour: string, 
        key: string, subtype: string, sortIndex: number, hasSplitTasks: boolean, buildLocationTitle: string): IGanttTask {
        return {
            id: id,
            //uid: task.id.toString(),
            taskId: taskId,
            text: text,
            start_date: startDate,
            end_date: endDate,
            duration: duration,
            progress: progress,
            critical: critical,
            parent: parent,
            type: type,
            open: open,
            selected: selected,
            projectTags: projectTags,
            isInActivePullPlan: isInActivePullPlan,
            outlineNumber: outlineNumber,
            render: render,
            projectTeamId: projectTeamId,
            swimlaneIndex: swimlaneIndex,
            swimlaneTeamTitle: swimlaneTeamTitle,
            swimlaneTeamColour: swimlaneTeamColour,
            key: key,
            subtype: subtype,
            sortIndex: sortIndex,
            hasSplitTasks: hasSplitTasks,
            location: buildLocationTitle
        };
    }

    public static mapToGanttLink(link: ActivityLinkDto): IGanttLink {
        return {
            id: link.id.toString(),
            source: link.linkPredecessorId.toString(),
            target: link.linkActivityId.toString(),
            type: this.mapMPLinkType(link.linkType)
        };
    }

    public static createNewGanttLink(id: string, source: string, target: string, type: string): IGanttLink {
        //use GanttLinkConstants for type values
        return { id, source, target, type };
    }

    public static calculateGanttDuration(dt1: DateTime, dt2: DateTime) {
        var ms = this.commonHelpers.dateDiff(dt1, dt2);
        return ms / (24 * 60 * 60 * 1000);
    }

    public static mapMPLinkType(mspLinkType: number): string {
        //maps Microsoft Project Task Link Types to DHTMLX Gantt Link Types
        //https://docs.microsoft.com/en-us/office-project/xml-data-interchange/type-element-multiple-parents?view=project-client-2016
        switch (mspLinkType) {
            case ActivityLinkTypeConstants.FF: return GanttLinkConstants.finish_to_finish;
            case ActivityLinkTypeConstants.FS: return GanttLinkConstants.finish_to_start;
            case ActivityLinkTypeConstants.SF: return GanttLinkConstants.start_to_finish;
            case ActivityLinkTypeConstants.SS: return GanttLinkConstants.start_to_start;
            default: return GanttLinkConstants.finish_to_start;
        }
    }

    public static mapGanttLinkType(ganttLinkType: string): number {
        //maps DHTMLX Gantt Link Types to Microsoft Project Task Link Types
        //https://docs.microsoft.com/en-us/office-project/xml-data-interchange/type-element-multiple-parents?view=project-client-2016
        switch (ganttLinkType) {
            case GanttLinkConstants.finish_to_finish: return ActivityLinkTypeConstants.FF;
            case GanttLinkConstants.finish_to_start: return ActivityLinkTypeConstants.FS;
            case GanttLinkConstants.start_to_finish: return ActivityLinkTypeConstants.SF;
            case GanttLinkConstants.start_to_start: return ActivityLinkTypeConstants.SS;
            default: return 1;
        }
    }

    public static mapActivity(data?: IActivityDto | ICreateOrEditActivityDto): ActivityDto {
        let activity: ActivityDto = new ActivityDto();
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property)) {
                    activity[property] = data[property];
                }
            }
        }
        return activity;
    }

    public static cloneGanttTask(task: IGanttTask): IGanttTask {
        return {
            id: task.id,
            //uid: task.uid,
            taskId: task.taskId,
            text: task.text,
            start_date: task.start_date,
            end_date: task.end_date,
            duration: task.duration,
            progress: task.progress,
            critical: task.critical,
            parent: task.parent,
            type: task.type,
            open: task.open,
            selected: task.selected,
            projectTags: task.projectTags.map(tag => new ProjectTagDto(tag)),
            isInActivePullPlan: task.isInActivePullPlan,
            outlineNumber: task.outlineNumber,
            render: task.render,
            projectTeamId: task.projectTeamId,
            swimlaneIndex: task.swimlaneIndex,
            swimlaneTeamTitle: task.swimlaneTeamTitle,
            swimlaneTeamColour: task.swimlaneTeamColour,
            key: task.key,
            subtype: task.subtype,
            sortIndex: task.sortIndex,
            hasSplitTasks: task.hasSplitTasks,
            location: task.location
        };
    }

    public static cloneGanttLink(link: IGanttLink): IGanttLink {
        return {
            id: link.id,
            source: link.source,
            target: link.target,
            type: link.type
        };
    }
}

export class ActivityLinkTypeConstants {
    public static readonly FF = 0;
    public static readonly FS = 1;
    public static readonly SF = 2;
    public static readonly SS = 3;
}
