<div appBsModal #userDelegationModal="bs-modal" class="modal fade" tabindex="-1" 
    role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true"
    [config]="{ backdrop: 'static' }">
    <div class="modal-dialog">
        <div class="modal-content">
            <form *ngIf="active" #userDelegationForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h2 class="modal-title">
                        <span>{{ 'DelegateNewUser' | localize }}</span>
                    </h2>
                    <button type="button" class="btn-close" (click)="close()" [attr.aria-label]="'Close' | localize" [disabled]="saving"></button>
                </div>
                <div class="modal-body pt-0 pb-3">
                    <div class="mb-5">
                        <label for="UsernameOrEmailAddress">{{ 'Username' | localize }}</label>
                        <div class="input-group" (click)="showCommonLookupModal()">
                            <input type="text" name="UsernameOrEmailAddress" class="form-control"
                                readonly="readonly" disabled="disabled" [value]="selectedUsername" />
                            <div class="input-group-append">
                                <button class="ds" type="button">{{ 'Search' | localize }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5">
                        <label for="StartTime">{{ 'StartTime' | localize }}</label>
                        <input type="text" id="StartTime" name="StartTime" class="form-control"
                            bsDatepicker datePickerLuxonModifier
                            [(date)]="userDelegation.startTime"
                            [(ngModel)]="userDelegation.startTime"
                            [maxDate]="userDelegation.endTime"
                            [bsConfig]="{ adaptivePosition: true }"
                            autocomplete="new-password" required />
                    </div>
                    <div class="mb-5">
                        <label for="EndTime">{{ 'EndTime' | localize }}</label>
                        <input type="text" id="EndTime" name="EndTime" class="form-control"
                            bsDatepicker datePickerLuxonModifier
                            [(date)]="userDelegation.endTime"
                            [(ngModel)]="userDelegation.endTime"
                            [minDate]="userDelegation.startTime"
                            [bsConfig]="{ adaptivePosition: true }"
                            autocomplete="new-password" required />
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="ds outlined dark me-3 min-width" [disabled]="saving" (click)="close()">
                        {{ 'Cancel' | localize }}
                    </button>
                    <button type="submit" class="ds min-width"
                        [disabled]="!userDelegation.targetUserId || !userDelegationForm.form.valid"
                        [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')">
                        {{ 'Save' | localize }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<commonLookupModal #userLookupModal (itemSelected)="userSelected($event)"></commonLookupModal>
