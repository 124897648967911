<div appBsModal #mySettingsModal="bs-modal"  class="modal fade" tabindex="-1"
    role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true"
    [config]="{ backdrop: 'static' }" (onShown)="onShown()">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #mySettingsModalForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h2 class="modal-title">
                        <span>{{ 'MySettings' | localize }}</span>
                    </h2>
                    <button type="button" class="btn-modal-help" (click)="showHelp()" [title]="'Help' | localize" [attr.aria-label]="'Help' | localize">
                        <i class="ds-icon-default-circle-question"></i>
                    </button>
                    <button type="button" class="btn-close" (click)="close()" [title]="'Close' | localize" [attr.aria-label]="'Close' | localize" [disabled]="saving"></button>
                </div>
                <div class="modal-body pt-0 pb-3" *ngIf="user">
                    <tabset>
                        <tab heading="{{ 'Profile' | localize }}">
                            <div class="row pt-6">
                                <div class="{{ bsGridCols(6, 6, 6, 6, 6) }}">
                                    <div class="form-group">
                                        <label class="form-label" for="Name">{{ 'FirstName' | localize }} *</label>
                                        <input type="text" class="form-control w-100" required maxlength="64"
                                            id="Name" name="Name" #nameInput="ngModel" [(ngModel)]="user.name" />
                                        <validation-messages [formCtrl]="nameInput"></validation-messages>
                                    </div>
                                </div>
                                <div class="{{ bsGridCols(6, 6, 6, 6, 6) }}">
                                    <div class="form-group">
                                        <label class="form-label" for="Surname">{{ 'Surname' | localize }} *</label>
                                        <input type="text" class="form-control w-100" required maxlength="64"
                                            id="Surname" name="Surname" #surnameInput="ngModel" [(ngModel)]="user.surname" />
                                        <validation-messages [formCtrl]="surnameInput"></validation-messages>
                                    </div>
                                </div>
                                <div class="{{ bsGridCols(6, 6, 6, 6, 6) }}">
                                    <div class="form-group">
                                        <label class="form-label" for="EmailAddress">{{ 'EmailAddress' | localize }} *</label>
                                        <input type="email" class="form-control w-100" required email maxlength="256"
                                            id="EmailAddress" name="EmailAddress" #emailAddressInput="ngModel"
                                            [(ngModel)]="user.emailAddress" [disabled]="user.b2CLoginAllowed" />
                                        <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="smsEnabled" class="row">
                                <div class="{{ bsGridCols(6, 6, 6, 6, 6) }}">
                                    <div class="form-group">
                                        <label class="form-label" for="PhoneNumber">{{ 'PhoneNumber' | localize }}</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="PhoneNumber" name="PhoneNumber" maxlength="32"
                                                [(ngModel)]="user.phoneNumber" />

                                            <span id="SpanSmsVerificationUnverified" class="input-group-append">
                                                <button type="button" class="ds dark outlined"
                                                    id="btnSmsVerification" name="btnSmsVerification"
                                                    [disabled]="isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber"
                                                    tooltip="{{ 'YourPhoneNumberIsNotVerified' | localize }}" (click)="smsVerify()">
                                                    <i class="ds-icon-default-warning"></i>
                                                    {{ 'Verify' | localize }}
                                                </button>
                                            </span>
                                            <div *ngIf="isPhoneNumberConfirmed && savedPhoneNumber == user.phoneNumber"
                                                class="input-group-append" id="SpanSmsVerificationVerified">
                                                <span class="input-group-text" tooltip="{{ 'YourPhoneNumberIsVerified' | localize }}">
                                                    <i class="ds-icon-default-check" [attr.aria-label]="l('Verified')"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="{{ bsGridCols(6, 6, 6, 6, 6) }}">
                                    <div class="form-group">
                                        <label class="form-label" for="UserName">{{ 'UserName' | localize }} *</label>
                                        <input type="text" class="form-control w-100" required maxlength="256"
                                            id="UserName" name="UserName" #userNameInput="ngModel" [(ngModel)]="user.userName"
                                            [disabled]="!canChangeUserName" />
                                        <span class="form-text text-muted" *ngIf="!canChangeUserName">
                                            {{ 'CanNotChangeAdminUserName' | localize }}
                                        </span>
                                        <validation-messages [formCtrl]="userNameInput"></validation-messages>
                                    </div>
                                </div>

                                <div *ngIf="showTimezoneSelection" class="{{ bsGridCols(6, 6, 6, 6, 6) }}">
                                    <div class="form-group">
                                        <label class="form-label" for="Timezone">{{ 'Timezone' | localize }}</label>
                                        <timezone-combo name="Timezone" [(ngModel)]="user.timezone"
                                            defaultTimezoneScope="{{ defaultTimezoneScope }}">
                                        </timezone-combo>
                                    </div>
                                </div>
                            </div>
                        </tab>

                        <tab *ngIf="!isMultiTenancyEnabled || isTwoFactorLoginEnabledForApplication" heading="{{ 'TwoFactorLogin' | localize }}">
                            <div>
                                <h4>Google Authenticator</h4>

                                <div [hidden]="!isGoogleAuthenticatorEnabled">
                                    <div>{{ 'ScanQrCodeWithYourMobileApp' | localize }}</div>
                                    <div class="text-center">
                                        <img [src]="user.qrCodeSetupImageUrl" />
                                    </div>
                                    <small>
                                        {{ 'GoogleAuthenticatorReferrerLink' | localize }}:
                                        <a href="https://support.google.com/accounts/answer/1066447" target="_blank" rel="noopener noreferrer">Google Authenticator</a>
                                    </small>
                                </div>

                                <div [hidden]="isGoogleAuthenticatorEnabled">
                                    <button type="button" class="btn btn-success" (click)="updateQrCodeSetupImageUrl()">
                                        {{ 'Enable' | localize }}
                                    </button>
                                </div>

                                <div [hidden]="!isGoogleAuthenticatorEnabled">
                                    <button type="button" class="btn btn-danger" (click)="disableGoogleAuthenticator()">
                                        {{ 'Disable' | localize }}
                                    </button>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                    <div class="alert alert-warning">
                        <em>{{ 'UserSettingsRefresh' | localize }}</em>
                    </div>
                </div>
                <div class="modal-footer pt-0">
                    <div class="d-inline-block">
                        <button type="button" class="ds outlined dark me-3 min-width" [disabled]="saving" (click)="close()">
                            {{ 'Cancel' | localize }}
                        </button>
                        <button type="submit" class="ds min-width" [disabled]="!mySettingsModalForm.form.valid || saving">
                            {{ 'Save' | localize }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<smsVerificationModal *ngIf="user" #smsVerificationModal
    [newPhoneNumber]="user.phoneNumber" (modalSave)="changePhoneNumberToVerified()">
</smsVerificationModal>
