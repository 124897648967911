export interface IGanttLink {
    id: string;
    source: string;
    target: string;
    type: string;
}

export class GanttLinkConstants {
    public static readonly finish_to_start = '0';
    public static readonly start_to_start = '1';
    public static readonly finish_to_finish = '2';
    public static readonly start_to_finish = '3';
}
