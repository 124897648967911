<div class="dropdown-checklist-container" dropdown #dropdown="bs-dropdown" 
    [insideClick]="true" [autoClose]="true" (isOpenChange)="dropdownChange($event)">
    <div class="input-group">
        <input type="text" [id]="id ? id : null" [name]="name ? name : null" class="form-control text-black" (click)="dropdownToggleButton.click()"
            readonly [ngModel]="selectionText" />
        <button type="button" dropdownToggle #dropdownToggleButton class="dropdown-toggle"
            aria-controls="dropdown-checklist-dropdown">
            <i class="ds-icon-default-chevron-down font-bold"></i>
        </button>
    </div>
    <div id="dropdown-checklist-dropdown" *dropdownMenu class="dropdown-menu">
        <div class="dropdown-checklist-dropdown-container">
            <div class="dropdown-checklist-dropdown-list">
                <div class="list-entry" *ngFor="let item of items">
                    <input type="checkbox" (change)="selectChanged($event, item.id)"
                        [id]="'chkItem_' + item.id.toString()"
                        [name]="'chkItem_' + item.id.toString()" [(ngModel)]="item.selected" />
                    <label [for]="'chkItem_' + item.id.toString()">{{ item.name }}</label>
                </div>
            </div>
        </div>
    </div>
</div>