import { Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserListDto } from '@shared/service-proxies/service-proxies';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { CustomComponentBase } from '@shared/common/custom-component-base';

@Component({
    selector: 'user-select-dropdown',
    templateUrl: './user-select-dropdown.component.html',
    styleUrls: ['./user-select-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserSelectDropdownComponent),
            multi: true
        }
    ]
})
export class UserSelectDropdownComponent extends CustomComponentBase implements OnInit, OnChanges, ControlValueAccessor {

    @ViewChild('dropdown', { static: true }) dropdown: BsDropdownDirective;

    private _userList: UserListDto[] = [];
    @Input() get userList() {
        return this._userList;
    }
    set userList(val: UserListDto[]) {
        this._userList = val;
        if (val && typeof val != 'undefined') {
            this.sortUserList();
            this.updateSelectedCount();
        } else {
            this.sortedUserList = [];
        }
    }

    @Input() id: string = '';
    @Input() name: string = '';
    @Input() multiSelect: boolean = false;
    @Input() showMultiSelectCount: boolean = true;
    @Input() projectId: number = 0;
    @Input() selectedId: number = 0; //only when multiSelect=false
    @Input() readonly: boolean = false;
    @Input() disabled: boolean = false;

    @Output() selectionChanged: EventEmitter<number> = new EventEmitter(); //count of selected items passed out

    sortedUserList: UserListDto[] = [];
    displayCount: string = '';
    listCategory: string = '';
    showGlobal: boolean = true;
    countPhrase: string = '{0} selected';
    selectPhrase: string = '-- select --';
    selectedCount: number = 0;
    changesMade: boolean = false;

    private _dropdownOpen: boolean = false;
    public get dropdownOpen(): boolean {
        return this._dropdownOpen;
    }

    get selectedUser() {
        let user: UserListDto = null;
        if (this.selectedId != null || typeof this.selectedId != 'undefined') {
            user = this._userList.find(t => t.id == this.selectedId);
        }
        return user;
    }

    constructor(private injector: Injector, private _renderer: Renderer2, private _elementRef: ElementRef) {
        super(injector);
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
    }

    ngOnInit(): void {
        //get the "-- select --" phrase translation
        let phrase = this.l('UserDropdownSelect');
        if (phrase != null && phrase != 'UserDropdownSelect') {
            this.selectPhrase = phrase;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.isNullOrUndefined(changes)) { return; }

        if (changes.showMultiSelectCount) {
            this.updateSelectedCount(); //this force setting the correct label

        } else if (changes.disabled) {
            this.updateSelectedCount();

        } else if (changes.readonly) {
            this.updateSelectedCount();
        }
    }

    //BEGIN: ControlValueAccessor implementation
    onChange: any = () => { }
    onTouch: any = () => { }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: UserListDto[]) {
        this.userList = value;
    }
    //END: ControlValueAccessor implementation

    sortUserList(): void {
        if (!this.arrayHasItems(this._userList)) {
            this.sortedUserList = [];
            return;    
        }
        this.sortedUserList = this._userList.sort((a, b) => (a.fullName.localeCompare(b.fullName)));
    }

    selectChanged(): void {
        this.changesMade = true;

        //check for single-select - if so, ensure only this id is selected
        if (!this.multiSelect) {
            this.sortedUserList.forEach((user) => {
                user.selected = (user.id == this.selectedId);
            });
            this.dropdown.hide();
        }

        //map the selections to the _userList input param
        this.sortedUserList.forEach((user) => {
            let result = this._userList.find(t => t.id == user.id);
            if (result) { result.selected = user.selected; }
        });

        this.selectedCount = this.updateSelectedCount();
        this.onChange(this._userList);
    }

    updateSelectedCount(): number {
        if (this.disabled) {
            this.selectedId = undefined;
            this.displayCount = '';
            return;
        }

        //show number of selections
        let count = 0;
        if (this.sortedUserList != null || typeof this.sortedUserList != 'undefined') {
            count = this.sortedUserList.filter(t => t.selected).length;
        }

        if (this.multiSelect) {
            if (this.showMultiSelectCount) {
                this.displayCount = this.countPhrase.replace('{0}', count.toString());
            } else {
                this.displayCount = this.selectPhrase;
            }
        } else if (count == 0 && !this.readonly) {
            this.displayCount = this.selectPhrase;
        } else if (count == 1) {
            let selection = this.sortedUserList.filter(t => t.selected)[0];
            this.selectedId = selection.id;
            this.displayCount = '';
        } else {
            this.displayCount = '';
        }

        return count;
    }

    dropdownChange(ev: boolean): void {
        //ev is a boolean where true=opened, false=closed
        if (ev) {
            //opened
            this.changesMade = false;
            this._dropdownOpen = true;
        } else {
            //closed
            this._dropdownOpen = false;
            if (this.changesMade) {
                this.selectionChanged.emit(this.multiSelect ? this.selectedCount : this.selectedId);
            }
        }
    }

    public clearSelections(): void {
        this.selectedId = 0;
        if (!this.isNullOrUndefined(this._userList) && this._userList.length > 0) {
            this._userList.forEach(user => user.selected = false);
            this.writeValue(this._userList);
        }
        this.sortUserList();
    }
}
