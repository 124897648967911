import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class UIDragService {

  private dragStartAction$ = new Subject<any>();
  dragStart$ = this.dragStartAction$.asObservable();
  
  constructor() {
  }

  dragStart() {
    this.dragStartAction$.next(null);
  }
}