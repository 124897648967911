import { DateTime } from "luxon";

export abstract class CommonHelpers {
    public emptyDate: DateTime = DateTime.fromFormat('1900-01-01', 'yyyy-MM-dd');

    public isNullOrUndefined(value: any): boolean {
        return (value == null || typeof value == 'undefined');
    }

    public isNullOrUndefinedOrNaN(value: any): boolean {
        if (this.isNullOrUndefined(value)) { return true; }
        let val = parseInt(value, 10);
        if (isNaN(val)) { return true; }
        return false;
    }

    public isNullOrUndefinedOrNaNOrZero(value: any): boolean {
        if (this.isNullOrUndefinedOrNaN(value)) { return true; }
        if (value == 0) { return true; }
        return false;
    }

    public isNullOrUndefinedOrEmptyString(value: any): boolean {
        if (this.isNullOrUndefined(value)) { return true; }
        if (typeof value != 'string') { return true; }
        if (value == '') { return true; }
        return false;
    }

    public arrayHasItems(arr: any[]): boolean {
        if (this.isNullOrUndefined(arr)) { return false; }
        return (arr && arr.length > 0);
    }

    public isNumberInArray(array: number[], value: number): boolean {
        if (!this.arrayHasItems(array)) { return false; }
        let check = array.find(x => x == value);
        return !this.isNullOrUndefined(check);
    }

    public isStringInArray(array: string[], value: string): boolean {
        if (!this.arrayHasItems(array)) { return false; }
        let check = array.find(x => x == value);
        return !this.isNullOrUndefined(check);
    }

    public isDateTimeValid(date: DateTime): boolean {
        return !(this.isNullOrUndefined(date) || isNaN(date.valueOf()) || date.valueOf() <= this.emptyDate.valueOf());
    }

    public dateIfNullOrEmpty(date: DateTime | null | undefined, defaultDate: DateTime): DateTime {
        if (!this.isNullOrUndefined(date) || date <= this.emptyDate) {
            return defaultDate;
        } else {
            return date;
        }
    }

    public get today(): DateTime {
        let now = DateTime.now();
        return DateTime.utc(now.year, now.month, now.day);
    }

    public dateDiff(dt1: DateTime, dt2: DateTime): number {
        return dt2.valueOf() - dt1.valueOf(); //return milliseconds difference
    }

    public dateDiffInDays(dt1: DateTime, dt2: DateTime): number {
        return this.dateDiff(dt1, dt2) / (1000 * 60 * 60 * 24);
    }

    public zeroTime(dt: DateTime): DateTime {
        return dt.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    public areDatesEqual(dt1: DateTime, dt2: DateTime) {
        if (dt1 == null && dt2 == null) { return true; }
        if (dt1 == null || dt2 == null) { return false; }
        return dt1.day === dt2.day && dt1.month === dt2.month && dt1.year === dt2.year;
    }

    public areDateTimesEqual(dt1: DateTime, dt2: DateTime) {
        if (dt1 == null && dt2 == null) { return true; }
        if (dt1 == null || dt2 == null) { return false; }
        return dt1.day === dt2.day && dt1.month === dt2.month && dt1.year === dt2.year
            && dt1.hour === dt2.hour && dt1.minute === dt2.minute && dt1.second === dt2.second;
    }

    public isDateTimeEarlierThan(dt: DateTime, compareTo: DateTime): boolean {
        return this.dateDiff(dt, compareTo) > 0;
    }

    public isDateTimeLaterThan(dt: DateTime, compareTo: DateTime): boolean {
        return this.dateDiff(dt, compareTo) < 0;
    }
    
    public parseBoolean(value: string): boolean {
        if (this.isNullOrUndefined(value)) { return false; }
        if (typeof(value) == 'boolean') { return value; }
        switch (value.toUpperCase().trim()) {
            case 'Y': case 'YES': case '1': case 'TRUE': return true;
            default: return false;
        }
    }
    
    public parseIntOrZero(value: any): number {
        if (this.isNullOrUndefinedOrNaN(value)) { return 0; }
        return parseInt(value, 10);
    }

    public parseIntOrUndefined(value: any): number {
        if (this.isNullOrUndefinedOrNaN(value)) { return undefined; }
        return parseInt(value, 10);
    }
    
    // public removeDateTimeOffset(value: DateTime): DateTime {
    //     value.setZone('utc');
    //     (value as any).o = 0;
    //     return value;
    // }

    public dateTimeToUtc(value: DateTime): DateTime {
        return value.toUTC();

        // let zoneUtc = value.setZone('utc');
        // let zeroOffset = value;
        // (zeroOffset as any).o = 0;
        // let toUtc = value.toUTC();

        //console.log(`dateTimeToUtc(value: [${value.toISO()}]), zoneUtc: [${zoneUtc.toISO()}], zeroOffset: [${zeroOffset.toISO()}], toUtc: [${toUtc.toISO()}]`);

        //return value;
    }
}

export class CommonHelpersInstance extends CommonHelpers {

}