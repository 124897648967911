import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    templateUrl: './pending-changes-confirmation.component.html'
})
export class PendingChangesConfirmationComponent extends AppComponentBase {

    public onClose: Subject<boolean>;

    constructor(injector: Injector, private _bsModalRef: BsModalRef) {
        super(injector);
    }

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public onConfirm(): void {
        this.onClose.next(true);
        this._bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(false);
        this._bsModalRef.hide();
    }
}