import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Injector, Input,
    OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BuildLocationDto } from '@shared/service-proxies/service-proxies';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { AppComponentBase } from '@shared/common/app-component-base';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
    selector: 'buildlocation-select-dropdown',
    templateUrl: './buildlocation-select-dropdown.component.html',
    styleUrls: ['./buildlocation-select-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BuildLocationSelectDropdownComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildLocationSelectDropdownComponent extends AppComponentBase implements OnInit, ControlValueAccessor { //OnChanges

    @ViewChild('dropdown', { static: true }) dropdown: BsDropdownDirective;
    @ViewChild('globalLocationsList', { read: ElementRef }) globalLocationsList: ElementRef;
    @ViewChild('projectLocationsList', { read: ElementRef }) projectLocationsList: ElementRef;

    private _buildLocationList: BuildLocationDto[] = [];
    @Input() get buildLocationList() {
        return this._buildLocationList;
    }
    set buildLocationList(val: BuildLocationDto[]) {
        this._buildLocationList = val;
        this.splitLocationLists();
    }

    @Input() id: string = '';
    @Input() name: string = '';
    @Input() showMultiSelectCount: boolean = true;
    @Input() projectId: number = 0;
    @Input() required: boolean = false;
    @Input() disabled: boolean = false;
    @Input() placeholder: string = '';

    private _value: number = null;
    @Input() get value() {
        return this._value;
    }
    set value(val: number) {
        this._value = val;
        this.updateSelectedTitle();
    }

    @Output() selectionChanged: EventEmitter<number> = new EventEmitter();

    _globalLocations: BuildLocationDto[] = [];
    _projectLocations: BuildLocationDto[] = [];
    displayCount: string = '';
    locationGroupId: number = null;
    locationGroupName: string = '';
    showGlobal: boolean = true;
    selectPhrase: string = '-- select --';
    changesMade: boolean = false;
    selectedLocationTitle: string = '';
    
    get hasGlobalLocations(): boolean {
        return this.arrayHasItems(this._globalLocations);
    }
    get hasProjectLocations(): boolean {
        return this.arrayHasItems(this._projectLocations);
    }

    private _dropdownOpen: boolean = false;
    public get dropdownOpen(): boolean {
        return this._dropdownOpen;
    }

    get selectedLocation() {
        let location: BuildLocationDto = null;
        if (!this.isNullOrUndefinedOrNaNOrZero(this.value)) {
            location = this._buildLocationList.find(t => t.id == this.value);
        }
        return location;
    }

    constructor(private _renderer: Renderer2, private _elementRef: ElementRef,
        injector: Injector, private _change: ChangeDetectorRef) {
        super(injector);
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
    }

    ngOnInit(): void {
        this.selectPhrase = this.l('SelectAProjectTag');
    }

    // ngOnChanges(changes: SimpleChanges): void {
    //     if (changes.disabled) {
    //         console.log('changes', changes);
    //     }
    // }

    //BEGIN: ControlValueAccessor implementation
    onChange: any = () => { }
    onTouch: any = () => { }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: number) {
        this.value = value;
    }
    //END: ControlValueAccessor implementation

    splitLocationLists(): void {
        let context = this;
        if (this.isNullOrUndefined(this._buildLocationList)) {
            this._globalLocations = [];
            this._projectLocations = [];
        } else {
            this._globalLocations = this._buildLocationList
                .filter(loc => context.isNullOrUndefinedOrNaN(loc.locationGroupProjectId))
                .sort((a, b) => context.textSort(a.locationGroupName, b.locationGroupName) || context.specialSort(a.locationTitle, b.locationTitle));
            this._projectLocations = this._buildLocationList
                .filter(loc => !context.isNullOrUndefinedOrNaN(loc.locationGroupProjectId))
                .sort((a, b) => context.textSort(a.locationGroupName, b.locationGroupName) || context.specialSort(a.locationTitle, b.locationTitle));
        }
        this.updateSelectedTitle();
    }

    private updateSelectedTitle(): void {
        let loc = this._buildLocationList.find(l => l.id == this.value);
        if (this.isNullOrUndefined(loc)) {
            this.selectedLocationTitle = '';
        } else {
            this.selectedLocationTitle = loc.locationTitle;
        }
    }

    private textSort(a: string, b: string): number {
        //performs a standard alpha-numeric sort
        if (this.isNullOrUndefined(a)) { a = ''; }
        if (this.isNullOrUndefined(b)) { b = ''; }
        return a.localeCompare(b);
    }

    private specialSort(a: string, b: string): number {
        //performs a special sort where number prefixes are sorted numerically
        if (this.isNullOrUndefined(a)) { a = ''; }
        if (this.isNullOrUndefined(b)) { b = ''; }
        var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        return collator.compare(a, b);
    }

    trackLocationGroup(): string {
        this.locationGroupId = null;
        this.locationGroupName = '';
        return '';
    }

    isNewLocationGroup(loc: BuildLocationDto): boolean {
        if (loc == null || typeof loc == 'undefined') { return false; }
        if (loc && loc.locationGroupId != this.locationGroupId) {
            this.locationGroupId = loc.locationGroupId;
            this.locationGroupName = loc.locationGroupName;
            return true;
        } else {
            return false;
        }
    }

    selectChanged(): void {
        this.changesMade = true;

        this._globalLocations.forEach((loc) => {
            loc.selected = (loc.id == this.value);
            if (loc.selected) {
                this.selectedLocationTitle = loc.locationTitle;
                this.value = loc.id;
                this.onChange(this.value); //<-- this is vital to pushing the changes out to the model binding
            }
            let result = this._buildLocationList.find(l => l.id == loc.id);
            if (result) { result.selected = loc.selected; }
        });
        this._projectLocations.forEach((loc) => {
            loc.selected = (loc.id == this.value);
            if (loc.selected) {
                this.selectedLocationTitle = loc.locationTitle;
                this.value = loc.id;
                this.onChange(this.value); //<-- this is vital to pushing the changes out to the model binding
            }
            let result = this._buildLocationList.find(l => l.id == loc.id);
            if (result) { result.selected = loc.selected; }
        });

        this.dropdown.hide();
    }

    dropdownChange(ev: boolean): void {
        //ev is a boolean where true=opened, false=closed
        if (ev) {
            //opened
            this.changesMade = false;
            this._dropdownOpen = true;
            //set the default tab in the list to Global
            this.showGlobal = true;
        } else {
            //closed
            this._dropdownOpen = false;
            if (this.changesMade) {
                this.selectionChanged.emit(this.value);
            }
        }
    }

    public clearSelections(): void {
        this.value = undefined;
        this.selectedLocationTitle = '';
        if (!this.isNullOrUndefined(this._buildLocationList)) {
            this._buildLocationList.forEach(loc => loc.selected = false);
        }
        this.splitLocationLists();
        this._change.markForCheck(); //neede to force update of the input displayCount value
        this.onChange(this.value); //<-- this is vital to pushing the changes out to the model binding
        this.selectionChanged.emit(this.value);
    }

    switchToGlobalTab(): void {
        this.showGlobal = true;
    }

    switchToProjectTab(): void {
        this.showGlobal = false;
    }

    dropdownClick(): void {
        if (!this.isNullOrUndefinedOrNaNOrZero(this.value)) {
            let location = this.buildLocationList.find(l => l.id == this.value);
            if (location != null) {
                if (this.isNullOrUndefinedOrNaNOrZero(location.locationGroupProjectId)) {
                    this.switchToGlobalTab();
                    // let items = this.globalLocationsList.nativeElement.querySelectorAll('.dropdown-item');
                    // if (this.arrayHasItems(items)) {
                    //     let item = items.find(i => i.getAttribute('data-id') == this.value.toString());
                    //     if (item) {
                    //         item.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    //     }
                    // }
                } else {
                    this.switchToProjectTab();
                    // let items = this.projectLocationsList.nativeElement.querySelectorAll('.dropdown-item');
                    // if (this.arrayHasItems(items)) {
                    //     let item = items.find(i => i.getAttribute('data-id') == this.value.toString());
                    //     if (item) {
                    //         item.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    //     }
                    // }
                }
            }
        }
    }

    getLocationGroupName(loc: BuildLocationDto): string {
        if (loc.locationGroupName == '[default]') {
            return this.l('BuildLocationDefaultGroupName');
        } else {
            return loc.locationGroupName;
        }
    }
}
