<div class="buildlocation-select-dropdown" dropdown #dropdown="bs-dropdown" [isDisabled]="disabled" [insideClick]="true" [autoClose]="true"
    (isOpenChange)="dropdownChange($event)">
    <div class="input-group" [class.readonly]="disabled">
        <input type="text" [id]="id ? id : null" [name]="name ? name : null" class="form-control text-black"
            [(ngModel)]="selectedLocationTitle" (click)="!disabled ? dropdownToggleButton.click() : null" 
            [placeholder]="placeholder" readonly [attr.disabled]="disabled ? true : null" />
        <button type="button" class="clear-button" (click)="clearSelections()" [hidden]="required || disabled || isNullOrUndefinedOrEmptyString(selectedLocationTitle)">
            <i class="pi pi-times"></i>
        </button>
        <button type="button" dropdownToggle #dropdownToggleButton class="dropdown-toggle"
            aria-controls="buildlocation-select-dropdown" (click)="!disabled ? dropdownClick() : null" [attr.disabled]="disabled ? true : null">
            <i class="ds-icon-default-chevron-down font-bold"></i>
        </button>
    </div>
    <div id="projecttag-dropdown" *dropdownMenu class="dropdown-menu">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <p class="nav-link" #navGlobal [class.active]="showGlobal" role="button" (click)="showGlobal = true">{{
                    'Global' | localize }}</p>
            </li>
            <li class="nav-item">
                <p class="nav-link" #navProject [class.active]="!showGlobal"
                    [class.disabled]="!hasProjectLocations" role="button"
                    (click)="showGlobal = false">{{ 'ProjectSpecific' | localize }}</p>
            </li>
        </ul>
        <div class="buildlocation-container">
            <div class="projecttag-list">
                <div [hidden]="!hasGlobalLocations || !showGlobal">
                    {{ trackLocationGroup() }}
                    <div *ngFor="let loc of _globalLocations;" [hidden]="loc.locationGroupProjectId" #globalLocationsList>
                        <p *ngIf="isNewLocationGroup(loc)" class="location-group noselect">{{ getLocationGroupName(loc) }}</p>
                        <label class="list-entry">
                            <input type="radio" id="{{ 'radBuildLocation_' + loc.id }}" name="radBuildLocation"
                                [attr.data-id]="loc.id" [value]="loc.id" [(ngModel)]="value" (change)="selectChanged()" />
                            <span class="noselect text-ellipsis">{{ loc.locationTitle }}</span>
                        </label>
                    </div>
                    <p [hidden]="hasGlobalLocations">{{ 'NoneFound' | localize }}</p>
                </div>
                <div [hidden]="!hasProjectLocations || showGlobal">
                    {{ trackLocationGroup() }}
                    <div *ngFor="let loc of _projectLocations;" [hidden]="!loc.locationGroupProjectId" #projectLocationsList>
                        <p *ngIf="isNewLocationGroup(loc)" class="location-group noselect">{{ getLocationGroupName(loc) }}</p>
                        <label class="list-entry">
                            <input type="radio" id="{{ 'radBuildLocation' + loc.id }}" name="radBuildLocation"
                                [attr.data-id]="loc.id" [value]="loc.id" [(ngModel)]="value" (change)="selectChanged()" />
                            <span class="noselect text-ellipsis">{{ loc.locationTitle }}</span>
                        </label>
                    </div>
                    <p [hidden]="hasProjectLocations">{{ 'NoneFound' | localize }}</p>
                </div>
            </div>
        </div>
    </div>
</div>