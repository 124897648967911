import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class HelpTopicService {

    private lasturl: string = '';
    private helpTopic$ = new BehaviorSubject<any>({});
    topic$ = this.helpTopic$.asObservable();
    private launchHelp$ = new Subject<any>();
    launch$ = this.launchHelp$.asObservable();

    constructor(private router: Router) {
        router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                if (ev.url != this.lasturl) {
                    this.lasturl = ev.url;
                    this.setHelpTopic(0);
                }
            }
        });
    }

    setHelpTopic(helpId: number) {
        let topic = this.lookupHelpTopicById(helpId);
        this.helpTopic$.next(topic);
    }

    showHelpPage(helpId: number) {
        let topic = this.lookupHelpTopicById(helpId);
        this.launchHelp$.next(topic);
    }

    lookupHelpTopicById(helpId: number): string {
        switch (helpId) {
            case 10: return 'User_account/Sign_in.htm';
            case 20: return 'Dashboard/View_dashboard.htm';
            case 30: return 'Projects/Working_with_projects.htm';
            case 40: return 'Projects/Import_projects.htm';
            case 50: return 'Projects/Create_projects.htm';
            case 60: return 'Projects/View_edit_projects.htm';
            case 70: return 'Projects/View_edit_master_plan.htm';
            case 80: return 'Projects/Create_master_plan_activities.htm';
            case 90: return 'Projects/Edit_master_plan_activities.htm';
            case 100: return 'Projects/View_project_pull_plans.htm';
            case 110: return 'Projects/Attach_documents.htm';
            case 120: return 'Projects/View_audit_logs.htm';
            case 130: return 'Project_tags/Working_with_project_tags.htm';
            case 140: return 'Project_tags/Create_project_tags.htm';
            case 150: return 'Project_tags/View_edit_project_tags.htm';
            case 160: return 'Pull_plans/Working_with_pull_plans.htm';
            case 165: return 'Pull_plans/Create_pull_plans.htm'; // 14/06/2023 Pmc #3189
            case 170: return 'Pull_plans/View_edit_pull_plans.htm';
            case 180: return 'Pull_plans/Working_with_pull_plan_tasks.htm';
            case 190: return 'Pull_plans/Create_pull_plan_tasks.htm';
            case 200: return 'Pull_plans/View_edit_pull_plan_tasks.htm';
            case 210: return 'Pull_plans/Pull_plan_revisions.htm';
            case 220: return 'Pull_plans/Using_whiteboard.htm';
            case 230: return 'Pull_plans/Using_kanban.htm';
            case 240: return 'Pull_plans/Using_Gantt_chart_view.htm';
            case 250: return 'Pull_plans/Using_kanban.htm';
            case 260: return 'Projects/Export_master_plan.htm';
            case 270: return 'Project_teams/Working_with_project_teams.htm';
            case 280: return 'Project_teams/Create_project_teams.htm';
            case 290: return 'Project_teams/View_edit_project_teams.htm';
            case 300: return 'Administration/Working_with_users.htm';
            case 310: return 'Administration/Create_users.htm';
            case 320: return 'Administration/View_edit_users.htm';
            case 330: return 'Administration/Working_with_user_roles.htm';
            case 340: return 'Administration/Create_user_roles.htm';
            case 350: return 'Administration/View_edit_user_roles.htm';
            case 360: return 'Administration/Configuring_security.htm';
            case 370: return 'Administration/Configuring_user_permissions.htm';
            case 380: return 'Notifications/Working_with_notifications.htm';
            case 390: return 'Administration/Working_with_currencies.htm';
            case 400: return 'Administration/Create_currencies.htm';
            case 410: return 'Administration/View_edit_currencies.htm';
            case 420: return 'User_account/Change_password.htm';
            case 430: return 'User_account/Change_settings.htm';
            case 440: return 'User_account/Change_profile_picture.htm';
            case 450: return 'User_account/Login_attempts.htm';
            case 460: return 'Elecosoft_admin/Configuring_security_Elecosoft.htm';
            case 470: return 'Elecosoft_admin/Working_with_customers.htm';
            case 480: return 'Elecosoft_admin/Create_customers.htm';
            case 490: return 'Elecosoft_admin/View_edit_customers.htm';
            case 500: return 'Dashboard/Configure_dashboard.htm';
            case 510: return 'Risk_categories/Working_with_risk_categories.htm';
            case 520: return 'Risk_categories/Create_risk_category.htm';
            case 530: return 'Risk_categories/View_edit_risk_categories.htm';
            case 540: return 'Pull_plans/Edit_multiple_pull_plan_tasks.htm';
            case 550: return 'Projects/Configure_project_settings.htm';
            case 560: return 'Elecosoft_admin/Mapping_Users_To_Multiple_Customers.htm';
            case 570: return 'Projects/Managing_project_baselines.htm'
            case 580: return 'Build_locations/Working_with_build_locations.htm';
            case 590: return 'Build_locations/Create_build_locations.htm';
            case 600: return 'Build_locations/View_edit_build_locations.htm';
            case 610: return 'Build_locations/Create_build_location_groups.htm';
            case 620: return 'Build_locations/View_edit_build_location_groups.htm';
            default: return 'Asta_Connect_Help.htm';
        }
    }
}