import { Component, EventEmitter, Injector, Output, ViewChild, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CurrentUserProfileEditDto, SettingScopes, ProfileServiceProxy, UpdateGoogleAuthenticatorKeyOutput, SendVerificationSmsInputDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SmsVerificationModalComponent } from './sms-verification-modal.component';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'mySettingsModal',
    templateUrl: './my-settings-modal.component.html',
})
export class MySettingsModalComponent extends AppComponentBase implements OnInit {

    @ViewChild('mySettingsModal', { static: true }) modal: ModalDirective;
    @ViewChild('smsVerificationModal') smsVerificationModal: SmsVerificationModalComponent;
    
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public active = false;
    public saving = false;
    public isGoogleAuthenticatorEnabled = false;
    public isPhoneNumberConfirmed: boolean;
    public smsEnabled: boolean;
    public user: CurrentUserProfileEditDto;
    public showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
    public canChangeUserName: boolean;
    public defaultTimezoneScope: SettingScopes = SettingScopes.User;
    private _initialTimezone: string = undefined;
    public savedPhoneNumber: string;
    public newPhoneNumber: string;
    isMultiTenancyEnabled: boolean = this.multiTenancy.isEnabled;
    isTwoFactorLoginEnabledForApplication = false;

    constructor(
        injector: Injector, 
        private _profileService: ProfileServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.isTwoFactorLoginEnabledForApplication = abp.setting.getBoolean('Abp.Zero.UserManagement.TwoFactorLogin.IsEnabled');
    }

    show(): void {
        this.active = true;
        this._profileService.getCurrentUserProfileForEdit().subscribe({
            next: (result) => {
                this.smsEnabled = this.setting.getBoolean('App.UserManagement.SmsVerificationEnabled');
                this.user = result;
                this._initialTimezone = result.timezone;
                this.canChangeUserName = this.user.userName !== AppConsts.userManagement.defaultAdminUserName;
                this.modal.show();
                this.isGoogleAuthenticatorEnabled = result.isGoogleAuthenticatorEnabled;
                this.isPhoneNumberConfirmed = result.isPhoneNumberConfirmed;
                this.savedPhoneNumber = result.phoneNumber;
            },
            // error: (err) => {

            // }
        });
    }

    updateQrCodeSetupImageUrl(): void {
        this._profileService.updateGoogleAuthenticatorKey().subscribe({
            next: (result: UpdateGoogleAuthenticatorKeyOutput) => {
                this.user.qrCodeSetupImageUrl = result.qrCodeSetupImageUrl;
                this.isGoogleAuthenticatorEnabled = true;
            },
            // error: (err) => {

            // }
        });
    }

    disableGoogleAuthenticator(): void {
        this._profileService.disableGoogleAuthenticator().subscribe({
            next: () => {
                this.isGoogleAuthenticatorEnabled = false;
            },
            // error: (err) => {

            // }
        });
    }

    smsVerify(): void {
        let input = new SendVerificationSmsInputDto();
        input.phoneNumber = this.user.phoneNumber;
        this._profileService.sendVerificationSms(input).subscribe({
            next: () => {
                this.smsVerificationModal.show();
            },
            // error: (err) => {

            // }
        });
    }

    changePhoneNumberToVerified(): void {
        this.isPhoneNumberConfirmed = true;
        this.savedPhoneNumber = this.user.phoneNumber;
    }

    onShown(): void {
        document.getElementById('Name').focus();
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        this.saving = true;
        let context = this;
        this._profileService.updateCurrentUserProfile(this.user).subscribe({
            next: () => {
                context.appSession.user.name = context.user.name;
                context.appSession.user.surname = context.user.surname;
                context.appSession.user.userName = context.user.userName;
                if (!context.user.b2CLoginAllowed) {
                    context.appSession.user.emailAddress = context.user.emailAddress;
                }
                
                context.saving = false;
                context.notify.success(context.l('SavedSuccessfully'));
                context.close();
                context.modalSave.emit(null);

                window.location.reload();
                
                // if (abp.clock.provider.supportsMultipleTimezone && context._initialTimezone !== context.user.timezone) {
                //     context.message.info(this.l('TimeZoneSettingChangedRefreshPageNotification')).then(() => {
                //         window.location.reload();
                //     });
                // }
            },
            // error: (err) => {

            // }
        });
    }

    showHelp(): void {
        this.showModalHelp(430);
    }
}
