<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
        <div
            id="kt_aside"
            class="aside"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="auto"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_toggle"
        >
            <div class="aside-logo flex-column-auto pt-10 pt-lg-20" id="kt_aside_logo">
                <theme9-brand class="d-flex flex-column"></theme9-brand>
            </div>
            <div class="aside-menu flex-column-fluid pt-0 pb-7 py-lg-10" id="kt_aside_menu">
                <div
                    id="kt_aside_menu_wrapper"
                    class="w-100 hover-scroll-overlay-y scroll-ps d-flex"
                    data-kt-scroll="true"
                    data-kt-scroll-height="auto"
                    data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                    data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu"
                    data-kt-scroll-offset="0"
                >
                    <side-bar-menu [iconMenu]="true"></side-bar-menu>
                </div>
            </div>
        </div>
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div class="header-mobile py-3">
                <!--begin::Container-->
                <div class="container d-flex flex-stack">
                    <!--begin::Mobile logo-->
                    <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                        <theme9-brand></theme9-brand>
                    </div>
                    <!--end::Mobile logo-->
                    <!--begin::Aside toggle-->
                    <button class="btn btn-icon btn-active-color-primary" id="kt_aside_toggle">
                        <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                        <span class="svg-icon svg-icon-2x me-n1">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                    fill="black"
                                ></path>
                                <path
                                    opacity="0.3"
                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                    fill="black"
                                ></path>
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </button>
                    <!--end::Aside toggle-->
                </div>
                <!--end::Container-->
            </div>
            <div
                id="kt_header"
                class="header py-6 py-lg-0"
                data-kt-sticky="true"
                data-kt-sticky-name="header"
                data-kt-sticky-offset="{lg: '300px'}"
            >
                <div class="header-container container-xxl">
                    <div
                        class="
                            page-title
                            d-flex
                            flex-column
                            align-items-start
                            justify-content-center
                            flex-wrap
                            me-lg-20
                            py-3 py-lg-0
                            me-3
                        "
                    ></div>
                    <div class="d-flex align-items-center flex-wrap">
                        <div class="d-flex align-items-center py-3 py-lg-0">
                            <active-delegated-users-combo
                                [customStyle]="'btn btn-hover-transparent-white btn-lg me-1'"
                            ></active-delegated-users-combo>
                            <subscription-notification-bar
                                [customStyle]="
                                    'btn btn-icon btn-custom btn-active-color-primary position-relative me-2'
                                "
                            ></subscription-notification-bar>
                            <quick-theme-selection
                                [customStyle]="'btn btn-icon btn-custom btn-active-color-primary me-2'"
                            ></quick-theme-selection>
                            <language-switch-dropdown
                                [customStyle]="'btn btn-icon btn-custom btn-active-color-primary me-2'"
                            ></language-switch-dropdown>
                            <header-notifications
                                [customStyle]="'btn btn-icon btn-custom btn-active-color-primary position-relative me-2'"
                            ></header-notifications>
                            <chat-toggle-button
                                [customStyle]="'btn btn-icon btn-custom btn-active-color-primary position-relative me-2'"
                            ></chat-toggle-button>
                            <user-menu
                                [togglerCssClass]="'btn btn-icon btn-custom btn-active-color-primary w-auto px-5'"
                                [textCssClass]="'text-white font-weight-bolder font-size-sm d-none d-md-inline me-2'"
                                [symbolCssClass]="'symbol symbol-30px ms-2'"
                                [symbolTextCssClass]="'symbol-label fs-2 fw-bold text-primary text-white'"
                            ></user-menu>
                        </div>
                    </div>
                </div>
                <div class="header-offset"></div>
            </div>
            <div style="flex: 1; margin-top: -50px">
                <router-outlet></router-outlet>
            </div>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
