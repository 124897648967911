import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'eleco-colour-picker',
    templateUrl: './eleco-colour-picker.component.html',
    styleUrls: ['./eleco-colour-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ElecoColourPickerComponent),
            multi: true
        }
    ]
})
export class ElecoColourPickerComponent implements OnInit {

    private _orientation: string = 'vertical';
    @Input()
    get orientation(): string {
        return this._orientation;
    }
    set orientation(val: string) {
        if (val == null || typeof val == 'undefined') { val = ''; }
        val = val.toLowerCase().trim();
        if (val == 'v' || val == 'ver') {
            val = 'vertical';
        } else if (val == 'h' || val == 'hor') {
            val = 'horizontal';
        }
        if (val != 'vertical' && val != 'horizontal') {
            val = 'vertical';
        }
        this._orientation = val;
    }

    @Input() id: string = '';
    @Input() name: string = '';
    @Input() customColours: IElecoColourPickerCustomColour[] = [];
    @Input() value: string = '';
    @Input() dropdownHeight: number | undefined;

    @Output() change = new EventEmitter<string>();

    customColoursDefined(): boolean {
        return typeof this.customColours != 'undefined' && this.customColours != null && this.customColours.length > 0;
    }

    reds = [];
    pinks = [];
    purples = [];
    deeppurples = [];
    indigos = [];
    blues = [];
    lightblues = [];
    cyans = [];
    teals = [];
    greens = [];
    lightgreens = [];
    limes = [];
    yellows = [];
    ambers = [];
    oranges = [];
    deeporanges = [];
    browns = [];
    bluegreys = [];
    greys = [];
    rows = [];

    constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
    }

    ngOnInit(): void {
        //build the colour arrays
        let steps1 = 5;
        let steps2 = 5;
        this.reds = this.interpolateColoursWithMidpoint(this.hexToRgb('#ff0000'), this.hexToRgb('#ffebee'), this.hexToRgb('#b71c1c'), steps1, steps2); //#ff1744
        this.pinks = this.interpolateColoursWithMidpoint(this.hexToRgb('#f50057'), this.hexToRgb('#fce4ec'), this.hexToRgb('#880e4f'), steps1, steps2);
        this.purples = this.interpolateColoursWithMidpoint(this.hexToRgb('#d500f9'), this.hexToRgb('#f3e5f5'), this.hexToRgb('#4a148c'), steps1, steps2);
        this.deeppurples = this.interpolateColoursWithMidpoint(this.hexToRgb('#651fff'), this.hexToRgb('#ede7f6'), this.hexToRgb('#311b92'), steps1, steps2);
        this.indigos = this.interpolateColoursWithMidpoint(this.hexToRgb('#0000ff'), this.hexToRgb('#e8eaf6'), this.hexToRgb('#1a237e'), steps1, steps2); //#3d5afe
        this.blues = this.interpolateColoursWithMidpoint(this.hexToRgb('#2979ff'), this.hexToRgb('#e3f2fd'), this.hexToRgb('#0d47a1'), steps1, steps2);
        this.lightblues = this.interpolateColoursWithMidpoint(this.hexToRgb('#00b0ff'), this.hexToRgb('#e1f5fe'), this.hexToRgb('#01579b'), steps1, steps2);
        this.cyans = this.interpolateColoursWithMidpoint(this.hexToRgb('#00e5ff'), this.hexToRgb('#e0f7fa'), this.hexToRgb('#006064'), steps1, steps2);
        this.teals = this.interpolateColoursWithMidpoint(this.hexToRgb('#1de9b6'), this.hexToRgb('#e0f2f1'), this.hexToRgb('#004d40'), steps1, steps2);
        this.greens = this.interpolateColoursWithMidpoint(this.hexToRgb('#00e676'), this.hexToRgb('#e8f5e9'), this.hexToRgb('#1b5e20'), steps1, steps2);
        this.lightgreens = this.interpolateColoursWithMidpoint(this.hexToRgb('#00ff00'), this.hexToRgb('#f1f8e9'), this.hexToRgb('#33691e'), steps1, steps2); //#76ff03
        this.limes = this.interpolateColoursWithMidpoint(this.hexToRgb('#c6ff00'), this.hexToRgb('#f9fbe7'), this.hexToRgb('#827717'), steps1, steps2);
        this.yellows = this.interpolateColoursWithMidpoint(this.hexToRgb('#ffff00'), this.hexToRgb('#fffde7'), this.hexToRgb('#f57f17'), steps1, steps2); //#ffea00
        this.ambers = this.interpolateColoursWithMidpoint(this.hexToRgb('#ffc400'), this.hexToRgb('#fff8e1'), this.hexToRgb('#ff6f00'), steps1, steps2);
        this.oranges = this.interpolateColoursWithMidpoint(this.hexToRgb('#ff9100'), this.hexToRgb('#fff3e0'), this.hexToRgb('#e65100'), steps1, steps2);
        this.deeporanges = this.interpolateColoursWithMidpoint(this.hexToRgb('#ff3d00'), this.hexToRgb('#fbe9e7'), this.hexToRgb('#bf360c'), steps1, steps2);
        this.browns = this.interpolateColoursWithMidpoint(this.hexToRgb('#8b4513'), this.hexToRgb('#efebe9'), this.hexToRgb('#3e2723'), steps1, steps2);
        this.bluegreys = this.interpolateColours(this.hexToRgb('#263238'), this.hexToRgb('#eceff1'), steps1 + steps2 - 1);
        this.greys = this.interpolateColours(this.hexToRgb('#000000'), this.hexToRgb('#ffffff'), steps1 + steps2 - 1);

        for (let i = 0; i < this.greys.length; i++) {
            this.rows.push([].concat(
                this.reds[i], this.pinks[i], this.purples[i], this.deeppurples[i], this.indigos[i],
                this.blues[i], this.lightblues[i], this.cyans[i], this.teals[i], this.greens[i],
                this.lightgreens[i], this.limes[i], this.yellows[i], this.ambers[i], this.oranges[i],
                this.deeporanges[i], this.browns[i], this.bluegreys[i], this.greys[i]
            )
            );
        }
    }

    // ngOnChanges(changes: SimpleChanges) {
    //     // if (changes && changes.orientation) {

    //     // }
    // }

    //BEGIN: ControlValueAccessor implementation
    onChange: any = () => { }
    onTouch: any = () => { }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: string) {
        this.value = value;
    }
    //END: ControlValueAccessor implementation

    interpolateColour(color1: number[], color2: number[], factor: number): string {
        if (arguments.length < 3) { factor = 0.5; }
        var result = color1.slice();
        for (var i = 0; i < 3; i++) {
            result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
        }
        return `rgb(${result[0]}, ${result[1]}, ${result[2]})`;
    }

    interpolateColours(color1: any, color2: any, steps: number): string[] {
        let stepFactor = 1 / (steps - 1);
        let colours: string[] = [];

        color1 = color1.match(/\d+/g).map(Number);
        color2 = color2.match(/\d+/g).map(Number);

        for (let i = 0; i < steps; i++) {
            let result = this.interpolateColour(color1, color2, stepFactor * i);
            colours.push(this.rgbToHex(result));
        }

        return colours;
    }

    interpolateColoursWithMidpoint(color1: any, color2: any, color3: any, steps1: number, steps2: number): string[] {
        let arr1 = this.interpolateColours(color1, color2, steps1);
        let arr2 = this.interpolateColours(color2, color3, steps2);
        arr1.pop();
        return arr1.concat(...arr2);
    }

    private hexToRgb(hex: string): string {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (!result) { return null; }
        return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)})`;
    }

    private rgbToHex(rgb: string): string {
        rgb = rgb.replace('rgb', '').replace('(', '').replace(')', '').replace(' ', '');
        let colours = rgb.split(',');
        let r = parseInt(colours[0].trim(), 10);
        let g = parseInt(colours[1].trim(), 10);
        let b = parseInt(colours[2].trim(), 10);
        let hex = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
        return (`#${hex}`).toUpperCase();
    }

    onKeyPress(event: any) {
        //TODO: take off readonly mode and allow user to type hex code;
        //note that this would need a custom colour selection box on the grid though...

        // console.log(event);
        // console.log(this.inputValue);
        // //hash: keyCode 35
        //a-f: keyCodes 97, 98, 99, 100, 101, 102
        //A-F: keyCodes 65, 66, 67, 68, 69, 70
        //0-9: keyCodes 48, 49, 50, 51, 52, 53, 54, 55, 56, 57
        // let hashKeyCode = 35;
        // let allowedKeyCodes = [ hashKeyCode, 97, 98, 99, 100, 101, 102, 65, 66, 67, 68, 69, 70, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57 ];
        // if (!allowedKeyCodes.find(k => k == event.keyCode)) { return false; }
        // if (event.keyCode == hashKeyCode && this.inputValue.indexOf('#') > -1) { return false; }

    }

    // onClick(toggle: any): void {
    //   //toggle the dropdown
    //   //let el: HTMLElement = this.toggleButton.nativeElement;
    //   let el: HTMLElement = toggle.nativeElement;
    //   el.click();
    // }

    colourClick(colour: string): void {
        this.value = colour;
        this.onChange(this.value); //<-- this is vital to pushing the changes out to the model binding
        this.change.emit(this.value);
    }

    //returns either black or white, depending on the intensity of the specified colour
    public getRecommendedTextColor(hex: string): string {
        if (!hex || hex.length < 3) { return ''; }
        hex = hex.toLowerCase();

        if (hex.indexOf('#') == 0) {
            hex = hex.slice(1);
        }

        if (hex.length == 3) {
            let parts = hex.split('');
            hex = `${parts[0]}${parts[0]}${parts[1]}${parts[1]}${parts[2]}${parts[2]}`;
        }

        if (hex.length != 6) { return ''; }

        var r = parseInt(hex[0] + hex[1], 16);
        var g = parseInt(hex[2] + hex[3], 16);
        var b = parseInt(hex[4] + hex[5], 16);

        //calculaute YIQ ratio
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        return (yiq >= 128) ? '#000000' : '#FFFFFF';
    }

    lookupCustomColourHex(value: string): string {
        if (!this.customColoursDefined()) { return ''; }
        return this.customColours.find(c => c.value == value)?.hex;
    }

    lookupCustomColourTitle(value: string): string {
        if (!this.customColoursDefined()) { return ''; }
        return this.customColours.find(c => c.value == value)?.title;
    }

    displayHex(): string {
        if (this.customColoursDefined()) {
            return this.lookupCustomColourHex(this.value) || '';
        } else {
            return this.value || '';
        }
    }

    displayTitle(): string {
        if (this.customColoursDefined()) {
            return this.lookupCustomColourTitle(this.value) || '';
        } else {
            return this.value || '';
        }
    }

    dropdownHeightStyle(): any {
        if (typeof this.dropdownHeight != 'undefined' && this.dropdownHeight > 0) {
            return {
                minHeight: `${this.dropdownHeight}px`,
                maxHeight: `${this.dropdownHeight}px`,
                height: `${this.dropdownHeight}px`
            };
        }
    }
}

export interface IElecoColourPickerCustomColour {
    hex: string;
    title: string;
    value: string;
}
