<div class="eleco-colour-picker" dropdown #dropdown="bs-dropdown" container="body" [autoClose]="true">
    <div class="input-group" dropdownToggle>
        <input type="text" [id]="id ? id : null" [name]="name ? name : null" class="form-control" maxlength="7" [value]="displayTitle()" readonly />
        <button type="button" class="dropdown-toggle" aria-controls="colour-dropdown">
            <i class="ds-icon-default-palette" [attr.aria-label]="'Colour' | localize"></i>
        </button>
        <span [hidden]="!value || value == ''" class="colour-block" [style.background-color]="displayHex()"></span>
    </div>
    <div id="colour-dropdown" *dropdownMenu class="dropdown-menu eleco-colour-picker-dropdown" [ngStyle]="dropdownHeightStyle()">
        <div class="colour-swatches" [hidden]="customColoursDefined() || orientation == 'horizontal'">
            <ul>
                <li *ngFor="let colour of reds" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of pinks" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of purples" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of deeppurples" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of indigos" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of blues" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of lightblues" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of cyans" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of teals" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of greens" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of lightgreens" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of limes" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of yellows" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of ambers" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of oranges" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of deeporanges" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of browns" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of bluegreys" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
            <ul>
                <li *ngFor="let colour of greys" [class.selected]="colour == value" [attr.data-hex]="colour" [style.background-color]="colour" [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
        </div>

        <div class="colour-swatches" [hidden]="customColoursDefined() || orientation == 'vertical'">
            <ul *ngFor="let row of rows">
                <li *ngFor="let colour of row" [class.selected]="colour == value" 
                    [attr.data-hex]="colour" [style.background-color]="colour" 
                    [title]="colour" (click)="colourClick(colour)"></li>
            </ul>
        </div>

        <div class="colour-swatches" [hidden]="!customColoursDefined()">
            <ul>
                <li *ngFor="let colour of customColours" [class.selected]="colour.value == value" 
                    [attr.data-hex]="colour.hex" [style.background-color]="colour.hex" 
                    [title]="colour.title" (click)="colourClick(colour.value)"></li>
            </ul>
        </div>
    </div>
</div>
