import { _isNumberValue } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'eleco-icon-picker',
    templateUrl: './eleco-icon-picker.component.html',
    styleUrls: ['./eleco-icon-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ElecoIconPickerComponent),
            multi: true
        }
    ]
})
export class ElecoIconPickerComponent implements OnInit {

    displayValue: string = '';

    @Input() id: string = '';
    @Input() name: string = '';

    private _value: string = '';
    @Input() get value(): string {
        return this._value;
    }
    set value(val: string) {
        this._value = val;
        this.setDisplayValue();
    }

    @Output() change = new EventEmitter<string>();

    icons: string[] = [
        'ds-icon-default-ab-filter', 'ds-icon-default-accounting', 'ds-icon-default-action', 'ds-icon-default-activity',
        'ds-icon-default-add', 'ds-icon-default-alert', 'ds-icon-default-arrow-down', 'ds-icon-default-arrow-left',
        'ds-icon-default-arrow-right', 'ds-icon-default-arrow-up', 'ds-icon-default-badge', 'ds-icon-default-badge-tick',
        'ds-icon-default-bag', 'ds-icon-default-bank', 'ds-icon-default-barcode', 'ds-icon-default-basket',
        'ds-icon-default-bicycle', 'ds-icon-default-block', 'ds-icon-default-blocked', 'ds-icon-default-blueprint',
        'ds-icon-default-board', 'ds-icon-default-bolt', 'ds-icon-default-book', 'ds-icon-default-book-delete',
        'ds-icon-default-bookmark', 'ds-icon-default-browser', 'ds-icon-default-brush', 'ds-icon-default-bubble',
        'ds-icon-default-bug', 'ds-icon-default-building', 'ds-icon-default-bulb', 'ds-icon-default-burger-menu',
        'ds-icon-default-burger-menu-alt', 'ds-icon-default-button', 'ds-icon-default-calculator',
        'ds-icon-default-calendar', 'ds-icon-default-calendar-add', 'ds-icon-default-calendar-schedule',
        'ds-icon-default-certificate', 'ds-icon-default-chat', 'ds-icon-default-check', 'ds-icon-default-checklist',
        'ds-icon-default-check-square', 'ds-icon-default-cheque', 'ds-icon-default-chevron-down',
        'ds-icon-default-chevron-left', 'ds-icon-default-chevron-right', 'ds-icon-default-chevron-up',
        'ds-icon-default-circle', 'ds-icon-default-circle-add', 'ds-icon-default-circle-close',
        'ds-icon-default-circle-disable', 'ds-icon-default-circle-information', 'ds-icon-default-circle-money',
        'ds-icon-default-circle-question', 'ds-icon-default-circle-tick', 'ds-icon-default-clip',
        'ds-icon-default-clipboard', 'ds-icon-default-clock', 'ds-icon-default-close', 'ds-icon-default-cloud',
        'ds-icon-default-cloud-server', 'ds-icon-default-cog', 'ds-icon-default-collection', 'ds-icon-default-colour',
        'ds-icon-default-comment', 'ds-icon-default-controls', 'ds-icon-default-crop', 'ds-icon-default-cursor',
        'ds-icon-default-database', 'ds-icon-default-dependancy-left', 'ds-icon-default-dependancy-right',
        'ds-icon-default-desktop-data', 'ds-icon-default-deviation', 'ds-icon-default-device-desktop',
        'ds-icon-default-device-list', 'ds-icon-default-device-mobile', 'ds-icon-default-device-tablet',
        'ds-icon-default-document-3d', 'ds-icon-default-document-cloud', 'ds-icon-default-document-delete',
        'ds-icon-default-document-folder', 'ds-icon-default-document-graph', 'ds-icon-default-document-pdf',
        'ds-icon-default-document-png', 'ds-icon-default-document-signature', 'ds-icon-default-document-word',
        'ds-icon-default-document-xls', 'ds-icon-default-document-zip', 'ds-icon-default-double-chevron-left',
        'ds-icon-default-double-chevron-right', 'ds-icon-default-double-cog', 'ds-icon-default-download',
        'ds-icon-default-estimate', 'ds-icon-default-estimate-sheet', 'ds-icon-default-expand', 'ds-icon-default-export',
        'ds-icon-default-file', 'ds-icon-default-file-block', 'ds-icon-default-file-chart', 'ds-icon-default-file-code',
        'ds-icon-default-file-download', 'ds-icon-default-file-estimate', 'ds-icon-default-file-upload',
        'ds-icon-default-file-video', 'ds-icon-default-filter', 'ds-icon-default-flag', 'ds-icon-default-folder',
        'ds-icon-default-form', 'ds-icon-default-gantt', 'ds-icon-default-gift', 'ds-icon-default-globe',
        'ds-icon-default-graph', 'ds-icon-default-graph-bump', 'ds-icon-default-grid', 'ds-icon-default-group',
        'ds-icon-default-guage', 'ds-icon-default-hat', 'ds-icon-default-heart', 'ds-icon-default-height',
        'ds-icon-default-help', 'ds-icon-default-hide', 'ds-icon-default-history', 'ds-icon-default-home',
        'ds-icon-default-image', 'ds-icon-default-industry', 'ds-icon-default-infographics', 'ds-icon-default-layout',
        'ds-icon-default-leaf', 'ds-icon-default-left-end', 'ds-icon-default-link', 'ds-icon-default-list',
        'ds-icon-default-lock', 'ds-icon-default-logout', 'ds-icon-default-mail', 'ds-icon-default-mailbox',
        'ds-icon-default-marker', 'ds-icon-default-measure', 'ds-icon-default-message', 'ds-icon-default-milestone',
        'ds-icon-default-money', 'ds-icon-default-moneypot', 'ds-icon-default-moon', 'ds-icon-default-move',
        'ds-icon-default-navigation', 'ds-icon-default-network', 'ds-icon-default-notification',
        'ds-icon-default-numerical', 'ds-icon-default-object-summary', 'ds-icon-default-open-book',
        'ds-icon-default-org', 'ds-icon-default-pagination', 'ds-icon-default-paint', 'ds-icon-default-palette',
        'ds-icon-default-paper-stack', 'ds-icon-default-password', 'ds-icon-default-payment-card',
        'ds-icon-default-pen', 'ds-icon-default-phone', 'ds-icon-default-pie', 'ds-icon-default-power',
        'ds-icon-default-present', 'ds-icon-default-presentation', 'ds-icon-default-printer',
        'ds-icon-default-process', 'ds-icon-default-puzzle', 'ds-icon-default-qr', 'ds-icon-default-radio',
        'ds-icon-default-redo', 'ds-icon-default-refresh', 'ds-icon-default-responsive', 'ds-icon-default-right-end',
        'ds-icon-default-robot', 'ds-icon-default-rows', 'ds-icon-default-search', 'ds-icon-default-search-settings',
        'ds-icon-default-server', 'ds-icon-default-share', 'ds-icon-default-share-social', 'ds-icon-default-shield-star',
        'ds-icon-default-sign', 'ds-icon-default-signature', 'ds-icon-default-spanner', 'ds-icon-default-square',
        'ds-icon-default-square-add', 'ds-icon-default-stack', 'ds-icon-default-star', 'ds-icon-default-summary',
        'ds-icon-default-sun', 'ds-icon-default-table', 'ds-icon-default-tag', 'ds-icon-default-target',
        'ds-icon-default-task-number', 'ds-icon-default-task-tick', 'ds-icon-default-text', 'ds-icon-default-tile',
        'ds-icon-default-tiles', 'ds-icon-default-toggle', 'ds-icon-default-transfer', 'ds-icon-default-trash',
        'ds-icon-default-trophy', 'ds-icon-default-undo', 'ds-icon-default-unlock', 'ds-icon-default-upload',
        'ds-icon-default-user', 'ds-icon-default-user-add', 'ds-icon-default-user-delete', 'ds-icon-default-user-group',
        'ds-icon-default-variable', 'ds-icon-default-verified', 'ds-icon-default-view', 'ds-icon-default-warning',
        'ds-icon-default-webinar', 'ds-icon-default-whiteboard', 'ds-icon-default-wifi', 'ds-icon-default-window-code',
        'ds-icon-default-window-data'
    ];

    constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
    }

    ngOnInit(): void {

    }

    //BEGIN: ControlValueAccessor implementation
    onChange: any = () => { }
    onTouch: any = () => { }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: string) {
        this.value = value;
    }
    //END: ControlValueAccessor implementation

    setDisplayValue(): void {
        if (this._value == null || typeof this._value == 'undefined') {
            this.displayValue = '';
            return;
        }
        if (this._value.indexOf('ds-icon-default-') == 0) {
            this.displayValue = this._value.substring(16);
        } else if (this._value.indexOf('ds-icon-') == 0) {
            this.displayValue = this._value.substring(8);
        } else {
            this.displayValue = this._value;
        }
    }

    iconClick(icon: string): void {
        this.value = icon;
        this.onChange(this.value); //<-- this is vital to pushing the changes out to the model binding
        this.change.emit(this.value);
    }

    sortAZ(arr: string[]): string[] {
        return arr.sort((a, b) => a > b ? 1 : a === b ? 0 : -1);
    }
}
