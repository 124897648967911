import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'eleco-tag',
  templateUrl: './eleco-tag.component.html',
  styleUrls: ['./eleco-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElecoTagComponent implements OnInit {

  @Input() tagTitle: string = '';
  @Input() tagColour: string = '';
  @Input() tagTextColour: string = '';
  @Input() tagIconClass: string = '';

  //note that if using the data property, changes to the values of that object will not propagate!
  @Input() data: any = {};

  constructor() {

  }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data) {
      this.processData();
    }
  }

  private processData(): void {
    if(!this.data || typeof this.data == 'undefined') { return; }

    let props = [ 'tagTitle', 'tagColour', 'tagTextColour', 'tagIconClass' ];

    props.forEach(prop => {
      if(this.data.hasOwnProperty(prop)) {
        this[prop] = this.data[prop];
      }
    });
  }
}
