<div [class]="footerStyle" id="kt_footer">
    <div [class]="containerClass + ' d-flex flex-column flex-md-row align-items-center justify-content-between'">
        <div class="text-dark order-2 order-md-1">
            <span class="text-muted fw-bold me-1">
                {{ copyright() }}
                <!-- <span *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName">
                    {{ appSession.tenant.edition.displayName }}
                </span>
                | API: v{{ appSession.application.version }} | Client: v{{ webAppGuiVersion }} [{{ releaseDate }}] -->
            </span>
        </div>
        <!-- <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1 footer-links">
            <li class="px-5"><a href="javascript:;">{{ 'ComplianceFooterText' | localize }}</a></li>
            <li class="px-5"><a href="https://elecosoft.com/documentation/policies-and-procedures/">{{ 'TandPFooterText' | localize }}</a></li>
            <li class="px-5">{{ 'VersionFooterText' | localize }}: {{ uiVersion }}</li>
        </ul> -->
    </div>
</div>