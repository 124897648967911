<!-- <div [@routerTransition] class="d-flex flex-column flex-root"> -->
<div class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
        <div id="kt_aside" class="aside aside-{{ currentTheme.baseSettings.menu.asideSkin }}"
            data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
            <default-brand></default-brand>
            <div class="aside-menu flex-column-fluid mt-5">
                <side-bar-menu #sideBarMenu></side-bar-menu>
            </div>
        </div>
        <div *ngIf="currentTheme.baseSettings.menu.allowAsideMinimizing" class="aside-toggle" 
            [class.active]="!sideMenuExpanded" (click)="toggleSideMenu()">
            <i class="ds-icon-default-chevron-left" [class.rotate-180]="!sideMenuExpanded"></i>
        </div>
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <div id="kt_header" class="header align-items-stretch bb-grey">
                <div class="{{ containerClass }} d-flex align-items-stretch justify-content-between">
                    <!-- begin aside toggler -->
                    <div title="{{ 'MainMenu' | localize }}" class="d-flex align-items-center d-lg-none ms-n3 me-1">
                        <div id="kt_aside_mobile_toggle" class="btn btn-icon btn-icon-fab btn-show-menu btn-active-light-primary w-30px h-30px w-md-40px h-md-40px">
                            <i class="ds-icon-default-burger-menu"></i>
                        </div>
                    </div>
                    <!-- end aside toggler-->

                    <!-- begin mobile logo -->
                    <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                        <default-logo [customHrefClass]="'d-lg-none'" skin="light-sm"></default-logo>
                    </div>
                    <!-- end mobile logo -->

                    <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                        <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                            <div class="d-flex align-items-stretch" id="kt_header_nav"></div>
                            <div class="d-flex align-items-stretch flex-shrink-0">
                                <div class="d-flex align-items-stretch flex-shrink-0">
                                    <div class="top-bar-page-title">
                                        <!-- Page Title -->
                                        <span class="noselect">{{ pageTitle }}</span>
                                        <!-- Breadcrumb Trail -->
                                        <div class="breadcrumb-container" *ngIf="breadcrumbs && breadcrumbs.length > 0">
                                            <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">
                                                <li class="breadcrumb-item" *ngFor="let breadcrumbItem of breadcrumbs">
                                                    <a *ngIf="breadcrumbItem.isLink()" (click)="goToBreadcrumb(breadcrumbItem)">
                                                        {{ breadcrumbItem.text }}
                                                    </a>
                                                    <span *ngIf="!breadcrumbItem.isLink()">{{ breadcrumbItem.text }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <!-- <active-delegated-users-combo *ngIf="!installationMode"></active-delegated-users-combo> -->
                                    <!-- <subscription-notification-bar></subscription-notification-bar> -->
                                    <help-icon #helpIcon [baseUrl]="helpBaseUrl" [language]="helpLanguage" [topic]="helpTopic"></help-icon>
                                    <!-- <language-switch-dropdown></language-switch-dropdown> -->
                                    <!-- <header-notifications></header-notifications> -->
                                    <!-- <chat-toggle-button></chat-toggle-button> -->
                                    <user-menu></user-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<!-- <div class="media-gauge text-align-center" style="font-size: 16px;position: absolute;left: 2px;top: 2px;">
    <span class="media-show-xl" style="background-color: darkred;color: white;">xl</span>
    <span class="media-show-lg" style="background-color: orangered;color: white;">lg</span>
    <span class="media-show-md" style="background-color: orange;color: black;">md</span>
    <span class="media-show-sm" style="background-color: yellow;color: black;">sm</span>
    <span class="media-show-xs" style="background-color: lightyellow;color: black;">xs</span>
</div> -->
            <div class="body" style="flex: 1;height: 100%;">
                <router-outlet></router-outlet>
            </div>
            <!-- <footer-bar></footer-bar> -->
        </div>
    </div>
</div>
