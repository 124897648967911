<div class="baseline-tasknote task-scale-{{ scale }}" [class.task-highlight]="highlight"  
    [style]="customStyles" [style.display]="hide ? 'none' : 'block'"
    [attr.data-task-id]="baselineTask.id" [attr.data-taskColumnCellIndex]="baselineTask.taskColumnCellIndex">
    <div class="baseline-tasknote-inner" 
        [super-tooltip]="taskTooltipContent" [escape]="false" [fitContent]="false" [showDelay]="800" tooltipStyleClass="tasknote-tooltip"
        (mouseenter)="onMouseEvents('mouseenter', $event)"
        (mouseleave)="onMouseEvents('mouseleave', $event)">
        <div class="baseline-tasknote-container" [style.backgroundColor]="swimlaneTeamColour" [style.color]="swimlaneTeamTextColour">
            <span class="content">{{ baselineTask?.taskTitle }}</span>
            <div class="icons-container">
                <div class="strike-through" [hidden]="!strikethrough"></div>
                <div class="at-risk" *ngIf="atRiskOrBlocked">
                    <i class="ds-icon-default-warning"></i>
                </div>
                <div class="deleted" *ngIf="baselineTask?.deleted">
                    <i class="ds-icon-default-blocked rotate-90"></i>
                </div>
            </div>
        </div>
    </div>
</div>