import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, 
    OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizationService } from 'abp-ng2-module';
import { ProjectTagDto } from '@shared/service-proxies/service-proxies';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'projecttag-select-dropdown',
    templateUrl: './projecttag-select-dropdown.component.html',
    styleUrls: ['./projecttag-select-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectTagSelectDropdownComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectTagSelectDropdownComponent extends AppComponentBase implements OnInit, OnChanges {

    @ViewChild('dropdown', { static: true }) dropdown: BsDropdownDirective;

    private _projectTagList: ProjectTagDto[] = [];
    @Input() get projectTagList() {
        return this._projectTagList;
    }
    set projectTagList(val: ProjectTagDto[]) {
        this._projectTagList = val;
        this.splitTagLists();
        if (val && typeof val != 'undefined') {
            this.updateSelectedCount();
            this.setFlags();
        }
    }

    @Input() id: string = '';
    @Input() name: string = '';
    @Input() multiSelect: boolean = false;
    @Input() showMultiSelectCount: boolean = true;
    @Input() projectId: number = 0;
    @Input() selectedId: number = 0; //only when multiSelect=false

    @Output() selectionChanged: EventEmitter<number> = new EventEmitter(); //count of selected items passed out

    _globalTags: ProjectTagDto[] = [];
    _projectTags: ProjectTagDto[] = [];
    //localization: LocalizationService;
    //sortedTagList: ProjectTagDto[] = [];
    displayCount: string = '';
    listCategory: string = '';
    showGlobal: boolean = true;
    countPhrase: string = '{0} selected';
    selectPhrase: string = '-- select --';
    selectedCount: number = 0;
    changesMade: boolean = false;
    hasGlobalTags: boolean = false;
    hasProjectTags: boolean = false;

    private _dropdownOpen: boolean = false;
    public get dropdownOpen(): boolean {
        return this._dropdownOpen;
    }

    get selectedTag() {
        let tag: ProjectTagDto = null;
        if (this.selectedId != null || typeof this.selectedId != 'undefined') {
            tag = this._projectTagList.find(t => t.id == this.selectedId);
        }
        return tag;
    }

    constructor(private _renderer: Renderer2, private _elementRef: ElementRef, 
        injector: Injector, private _change: ChangeDetectorRef) {
        super(injector);
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
    }

    ngOnInit(): void {
        this.countPhrase = this.l('TagSelectedCount');
        this.selectPhrase = this.l('SelectAProjectTag');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.showMultiSelectCount) {
            this.updateSelectedCount(); //this force setting the correct label
        }
    }

    //BEGIN: ControlValueAccessor implementation
    onChange: any = () => { }
    onTouch: any = () => { }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: ProjectTagDto[]) {
        this.projectTagList = value;
    }
    //END: ControlValueAccessor implementation

    splitTagLists(): void {
        let context = this;
        if (this.isNullOrUndefined(this._projectTagList)) {
            this._globalTags = [];
            this._projectTags = [];
        } else {
            this._globalTags = this._projectTagList
                .filter(tag => context.isNullOrUndefinedOrNaN(tag.tagProjectId))
                .sort((a, b) => context.textSort(a.tagCategory, b.tagCategory) || context.textSort(a.tagTitle, b.tagTitle));
            this._projectTags = this._projectTagList
                .filter(tag => !context.isNullOrUndefinedOrNaN(tag.tagProjectId))
                .sort((a, b) => context.textSort(a.tagCategory, b.tagCategory) || context.textSort(a.tagTitle, b.tagTitle));
        }
    }

    private textSort(a: string, b: string): number {
        if (this.isNullOrUndefined(a)) { a = ''; }
        if (this.isNullOrUndefined(b)) { b = ''; }
        return a.localeCompare(b);
    }

    trackCategory(): string {
        this.listCategory = '';
        return '';
    }

    isNewCategory(tag: ProjectTagDto): boolean {
        if (tag == null || typeof tag == 'undefined') { return false; }
        if (tag && tag.tagCategory != this.listCategory) {
            this.listCategory = tag.tagCategory;
            return true;
        } else {
            return false;
        }
    }

    selectChanged(): void {
        this.changesMade = true;

        //check for single-select - if so, ensure only this id is selected
        if (!this.multiSelect) {
            this._globalTags.forEach((tag) => {
                tag.selected = (tag.id == this.selectedId);
            });
            this._projectTags.forEach((tag) => {
                tag.selected = (tag.id == this.selectedId);
            });
            this.dropdown.hide();
        }

        //map the selections to the _projectTagList input param
        this._globalTags.forEach((tag) => {
            let result = this._projectTagList.find(t => t.id == tag.id);
            if (result) { result.selected = tag.selected; }
        });
        this._projectTags.forEach((tag) => {
            let result = this._projectTagList.find(t => t.id == tag.id);
            if (result) { result.selected = tag.selected; }
        });

        this.selectedCount = this.updateSelectedCount();
        this.onChange(this._projectTagList);
    }

    updateSelectedCount(): number {
        //show number of selections
        let count = 0;
        if (!this.isNullOrUndefined(this._globalTags)) {
            count += this._globalTags.filter(t => t.selected).length;
            count += this._projectTags.filter(t => t.selected).length;
        }
        if (this.multiSelect) {
            if (this.showMultiSelectCount) {
                this.displayCount = this.countPhrase.replace('{0}', count.toString());
            } else {
                this.displayCount = this.selectPhrase;
            }
        } else if (count == 0) {
            this.displayCount = this.selectPhrase;
        } else {
            this.displayCount = '';
        }
        return count;
    }

    dropdownChange(ev: boolean): void {
        //ev is a boolean where true=opened, false=closed
        if (ev) {
            //opened
            this.changesMade = false;
            this._dropdownOpen = true;
            //set the default tab in the list to Global
            this.showGlobal = true;
        } else {
            //closed
            this._dropdownOpen = false;
            if (this.changesMade) {
                this.selectionChanged.emit(this.multiSelect ? this.selectedCount : this.selectedId);
            }
        }
    }

    public clearSelections(): void {
        this.selectedId = 0;
        this.updateSelectedCount();
        if (!this.isNullOrUndefined(this._projectTagList)) {
            this._projectTagList.forEach(tag => tag.selected = false);
            this.writeValue(this._projectTagList);
        }
        this.splitTagLists();
        this._change.markForCheck(); //neede to force update of the input displayCount value
    }

    private setFlags(): void {
        this.hasGlobalTags = this.projectTagList.filter(tag => !tag.tagProjectId).length > 0;
        this.hasProjectTags = this.projectTagList.filter(tag => tag.tagProjectId).length > 0;
    }
}
