import { Component, Injector, Input, OnInit } from '@angular/core';
import { CustomComponentBase } from '@shared/common/custom-component-base';

@Component({
  selector: 'help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss']
})
export class HelpIconComponent extends CustomComponentBase implements OnInit {

  @Input() baseUrl: string = '';
  @Input() language: string = '';
  @Input() topic: string = '';

  private windowObject;

  constructor(private injector: Injector) {
    super(injector);
   }

  ngOnInit(): void {
  }

  onHelpClick(): void {
    this.openHelpPage();
  }

  openHelpPage(topic?: string) {
    //the topic argument is used when called from modal dialogs
    if (this.isNullOrUndefinedOrEmptyString(topic)) {
      topic = this.topic;
    }

    if (this.isNullOrUndefinedOrEmptyString(this.baseUrl)) { return; }
    if (this.isNullOrUndefinedOrEmptyString(this.language)) { this.language = 'english'; }
    if (this.isNullOrUndefinedOrEmptyString(topic)) { topic = 'Asta_Connect_Help.htm'; }

    //open new browser window at url
    let url = this.baseUrl.replace('{language}', this.language).replace('{topic}', topic);

    if (this.isNullOrUndefined(this.windowObject) || this.windowObject.closed) {
      this.windowObject = window.open(url, 'help');
    } else {
      this.windowObject = window.open(url, 'help');
      this.windowObject.focus();
    }
  }
}
