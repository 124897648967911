<div appBsModal #changeProfilePictureModal="bs-modal" class="modal fade"
    tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true"
    [config]="{ backdrop: 'static' }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #changeProfilePictureModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h2 class="modal-title">
                        <span>{{ 'ChangeProfilePicture' | localize }}</span>
                    </h2>
                    <button type="button" class="btn-modal-help" (click)="showHelp()" [title]="'Help' | localize" [attr.aria-label]="'Help' | localize">
                        <i class="ds-icon-default-circle-question"></i>
                    </button>
                    <button type="button" class="btn-close" (click)="close()" [title]="'Close' | localize" [attr.aria-label]="'Close' | localize" [disabled]="saving"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div [class]="bsGridCols(12, 12, 12, 12, 12)" *ngIf="canUseGravatar()">
                            <div class="form-group">
                                <label class="form-check form-check-custom form-check-solid py-1">
                                    <input type="checkbox" class="form-check-input" 
                                        id="Profile_UseGravatarProfilePicture" name="UseGravatarProfilePicture"
                                        [(ngModel)]="useGravatarProfilePicture" />
                                    <span class="form-check-label">
                                        {{ 'UseGravatarProfilePicture' | localize }}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div [class]="bsGridCols(12, 12, 12, 12, 12)">
                            <div class="form-group mb-3">
                                <input type="file" id="uploadProfilePicture" class="form-control" accept="image/*"
                                    [disabled]="useGravatarProfilePicture" [uploader]="fileUploader"
                                    ng2FileSelect (change)="fileChangeEvent($event)" />
                                <span class="form-text text-muted col-lg-12" [hidden]="useGravatarProfilePicture">
                                    {{ 'ProfilePicture_Change_Info' | localize: maxProfilPictureBytesUserFriendlyValue }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                        [aspectRatio]="4 / 4" [resizeToWidth]="128" format="png"
                        (imageCropped)="imageCroppedFile($event)" [hidden]="useGravatarProfilePicture">
                    </image-cropper>
                </div>
                <div class="modal-footer">
                    <div class="d-inline-block">
                        <button type="button" class="ds outlined dark me-3 min-width" [disabled]="saving" (click)="close()">
                            {{ 'Cancel' | localize }}
                        </button>
                        <button type="submit" class="ds min-width" *ngIf="permission.isGranted('Pages.PullPlanTeams.Edit')" 
                            [disabled]="!changeProfilePictureModalForm.form.valid || saving">
                            {{ 'Save' | localize }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
