import { PermissionCheckerService, FeatureCheckerService, LocalizationService, MessageService, AbpMultiTenancyService, NotifyService, SettingService } from 'abp-ng2-module';
import { Component, ElementRef, Injector, OnDestroy } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { ColourHelper } from '@shared/helpers/ColourHelper';
import { FlatPermissionDto, FlatPermissionWithLevelDto, UiCustomizationSettingsDto } from '@shared/service-proxies/service-proxies';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { PageTitleService } from '@shared/common/nav/page-title-service';
import { DateTime } from 'luxon';
import { BreadcrumbItem } from '@app/shared/common/sub-header/sub-header.component';
import { BreadcrumbService } from '@shared/common/nav/breadcrumb-service';
import { HelpTopicService } from '@shared/common/nav/help-topic-service';
import { SideMenuService } from './nav/side-menu-service';
import { ContextMenu } from 'primeng/contextmenu';
import { CommonHelpers } from './common.helpers';
import { UIDragService } from './nav/ui-drag-service';

interface AbpEventSubscription {
    eventName: string;
    callback: (...args: any[]) => void;
}

@Component({
    template: '',
})
export abstract class AppComponentBase extends CommonHelpers implements OnDestroy {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    //colourHelper: ColourHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    spinnerService: NgxSpinnerService;
    private ngxSpinnerTextService: NgxSpinnerTextService;
    eventSubscriptions: AbpEventSubscription[] = [];
    pageTitleService: PageTitleService;
    breadcrumbService: BreadcrumbService;
    helpTopicService: HelpTopicService;
    sideMenuService: SideMenuService;
    uiDragService: UIDragService;
    
    constructor(injector: Injector) {
        super();
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        //this.colourHelper = new ColourHelper();
        this.spinnerService = injector.get(NgxSpinnerService);
        this.ngxSpinnerTextService = injector.get(NgxSpinnerTextService);
        this.pageTitleService = injector.get(PageTitleService);
        this.breadcrumbService = injector.get(BreadcrumbService);
        this.helpTopicService = injector.get(HelpTopicService);
        this.sideMenuService = injector.get(SideMenuService);
        this.uiDragService = injector.get(UIDragService);
    }

    ngOnDestroy(): void {
        this.unSubscribeAllEvents();
    }

    setPageTitle(title: string): void {
        let context = this;
        Promise.resolve(null).then(() => context.pageTitleService.setPageTitle(title));
    }

    setBreadcrumbTrail(breadcrumbs: BreadcrumbItem[]): void {
        let context = this;
        Promise.resolve(null).then(() => context.breadcrumbService.setBreadcrumbs(breadcrumbs));
    }

    setHelpTopic(helpId: number): void {
        let context = this;
        Promise.resolve(null).then(() => context.helpTopicService.setHelpTopic(helpId));
    }

    showModalHelp(helpId: number): void {
        this.helpTopicService.showHelpPage(helpId);
    }

    collapseSideMenu(): void {
        this.sideMenuService.collapseMenu();
    }

    expandSideMenu(): void {
        this.sideMenuService.expandMenu();
    }

    uiDragStart() : void {
        this.uiDragService.dragStart();
    }

    flattenDeep(array) {
        return array.reduce(
            (acc, val) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)), []);
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    get containerClass(): string {
        if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid') {
            return 'container-fluid';
        } else if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid-xxl') {
            return 'container-xxl';
        }

        return 'container';
    }

    showMainSpinner(text?: string): void {
        this.ngxSpinnerTextService.setText(text);
        this.spinnerService.show();
    }

    hideMainSpinner(text?: string): void {
        this.spinnerService.hide();
    }

    protected subscribeToEvent(eventName: string, callback: (...args: any[]) => void): void {
        abp.event.on(eventName, callback);
        this.eventSubscriptions.push({
            eventName,
            callback,
        });
    }

    // 18/11/2022 PMc - added
    protected unSubscribeFromEvent(eventName: string): boolean {
        for (var i = 0; i < this.eventSubscriptions.length; i++) {
            let ev = this.eventSubscriptions[i];
            if (ev.eventName == eventName) {
                abp.event.off(ev.eventName, ev.callback);
                this.eventSubscriptions.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    private unSubscribeAllEvents() {
        this.eventSubscriptions.forEach((s) => abp.event.off(s.eventName, s.callback));
        this.eventSubscriptions = [];
    }

    /*
    * @param {number} xl extra-large size
    * @param {number} lg large size
    * @param {number} md medium size
    * @param {number} sm small size
    * @param {number} xs extra-small size
    */
    public bsGridCols(xl: number, lg: number, md: number, sm: number, xs: number): string {
        //generates the classes for bootstrap grid columns at each media breakpoint
        return `col col-xl-${xl} col-lg-${lg} col-md-${md} col-sm-${sm} col-${xs}`;
    }

    public getPosition(element: ElementRef) {
        let offsetLeft = 0;
        let offsetTop = 0;
        let el = element.nativeElement;
        while (el) {
            offsetLeft += el.offsetLeft;
            offsetTop += el.offsetTop;
            el = el.offsetParent;
        }
        return { offsetTop: offsetTop, offsetLeft: offsetLeft }
    }

    public filterOutUnusedPermissions(permissions: FlatPermissionDto[], elecoAdmin: boolean): FlatPermissionDto[] {
        //filter out the roles that are linked to features we are not using
        let  unusedFeatures = [
            'Pages.Administration.WebhookSubscription', 'Pages.Administration.AuditLogs',
            'Pages.Administration.Languages', 'Pages.Administration.DynamicProperties', 
            'Pages.Administration.DynamicPropertyValue', 'Pages.Administration.DynamicEntityProperties', 
            'Pages.Administration.DynamicEntityPropertyValue', 'Pages.DemoUiComponents',
            'Pages.Administration.Users.Impersonation', 'Pages.Administration.OrganizationUnits',
            //'Pages.Tenant.Dashboard', 
            'Pages.Administration.Tenant.SubscriptionManagement', 'Pages.Administration.UiCustomization',
            'Pages.DemoUiComponents', 'Pages.Editions', 'Pages.Administration.Host.Dashboard', 
            'Pages.Administration.HangfireDashboard', 'Pages.Administration.Host.Maintenance',
            'Pages.Tenants.Impersonation', 'Pages.Tenants.ChangeFeatures',
            'Pages.Administration.Currencies', 'Pages.ProjectSettings.Create', 'Pages.ProjectSettings.Delete'
        ];

        //, 'Pages.Administration.Tenant.Settings' //appears as Security in the permissions tree

        //can use elecoAdmin to determine if certain permissions should be filtered out or not
        // if (elecoAdmin) {

        // }

        permissions = permissions.filter(permission => {
            for(var i = 0; i < unusedFeatures.length; i++) {
                if (permission.name.indexOf(unusedFeatures[i]) == 0) {
                    return false;
                }
            }
            return true;
        });

        return permissions;
    }
}
