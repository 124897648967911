import { Injector, Component, OnInit, Inject, ViewChild } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppConsts } from '@shared/AppConsts';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { BreadcrumbItem } from '@app/shared/common/sub-header/sub-header.component';
import { Router } from '@angular/router';
import { HelpIconComponent } from '../../topbar/help-icon.component';
import { SideBarMenuComponent } from '../../nav/side-bar-menu.component';

@Component({
    templateUrl: './default-layout.component.html',
    selector: 'default-layout',
    animations: [appModuleAnimation()],
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {
    
    @ViewChild('sideBarMenu', { static: true }) sideBarMenu: SideBarMenuComponent;
    @ViewChild('helpIcon', { static: true }) helpIcon: HelpIconComponent;

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    pageTitle: string = '';
    breadcrumbs: BreadcrumbItem[] = [];
    helpBaseUrl: string = '';
    helpLanguage: string = '';
    helpTopic: string = '';
    sideMenuExpanded: boolean = true;

    constructor(
        injector: Injector, 
        private _router: Router, 
        _dateTimeService: DateTimeService
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/AstaConnectLogo.svg';
        
        let context = this;

        this.pageTitleService.title$.subscribe({ next: (value) => {
            if (typeof value != 'string') { return; }
            context.pageTitle = value;
        }});

        this.breadcrumbService.breadcrumbs$.subscribe({ next: (value) => {
            //if (typeof value != 'Object[]') { return }
            context.breadcrumbs = value;
        }});

        this.helpBaseUrl = this.s('GeneralSettings.HelpBaseUrl'); //s = this.setting.get(key)
        //this.helpLanguage //TODO: this needs setting whenever the language is changed
        this.helpTopicService.topic$.subscribe({ next: (value) => {
            context.helpTopic = value;
        }});

        this.helpTopicService.launch$.subscribe({ next: (topic) => {
            context.helpIcon.openHelpPage(topic);
        }});

        this.sideMenuService.collapse$.subscribe({ next: () => {
            context.collapseMenu();
        }});

        this.sideMenuService.expand$.subscribe({ next: () => {
            context.expandMenu();
        }});
    }

    goToBreadcrumb(breadcrumb: BreadcrumbItem): void {
        if (!breadcrumb.routerLink) {
            return;
        }

        if (breadcrumb.navigationExtras) {
            this._router.navigate([breadcrumb.routerLink], breadcrumb.navigationExtras);
        } else {
            this._router.navigate([breadcrumb.routerLink]);
        }
    }

    toggleSideMenu(): void {
        if (this.sideMenuExpanded == true) {
            this.collapseMenu();
        } else {
            this.expandMenu();
        }
    }
    private collapseMenu(): void {
        this.sideBarMenu.collapse();
        this.sideMenuExpanded = false;
    }

    private expandMenu(): void {
        this.sideBarMenu.expand();
        this.sideMenuExpanded = true;
    }
}
