<div *ngIf="maskVisible" [class]="maskStyleClass" [ngStyle]="maskStyle"
    [ngClass]="{
        'p-dialog-mask': true,
        'p-component-overlay p-component-overlay-enter': this.modal,
        'p-dialog-mask-scrollblocker': this.modal || this.blockScroll,
        'p-dialog-left': position === 'left',
        'p-dialog-right': position === 'right',
        'p-dialog-top': position === 'top',
        'p-dialog-top-left': position === 'topleft',
        'p-dialog-top-right': position === 'topright',
        'p-dialog-bottom': position === 'bottom',
        'p-dialog-bottom-left': position === 'bottomleft',
        'p-dialog-bottom-right': position === 'bottomright'
    }">
    <div #container role="dialog" [attr.aria-labelledby]="ariaLabelledBy" [attr.aria-modal]="true"
        [ngClass]="{ 'p-dialog p-component': true, 'p-dialog-rtl': rtl, 'p-dialog-draggable': draggable, 'p-dialog-resizable': resizable, 'p-dialog-maximized': maximized }"
        [ngStyle]="style" [class]="styleClass" *ngIf="visible" pFocusTrap [pFocusTrapDisabled]="focusTrap === false"
        [@animation]="{ value: 'visible', params: { transform: transformOptions, transition: transitionOptions } }"
        (@animation.start)="onAnimationStart($event)" (@animation.done)="onAnimationEnd($event)">
        <ng-container *ngIf="headlessTemplate; else notHeadless">
            <ng-container *ngTemplateOutlet="headlessTemplate"></ng-container>
        </ng-container>

        <ng-template #notHeadless>
            <div *ngIf="resizable" class="p-resizable-handle" (mousedown)="initResize($event)"></div>
            <div #titlebar class="p-dialog-header" (mousedown)="initDrag($event)" *ngIf="showHeader">
                <span [id]="ariaLabelledBy" class="p-dialog-title" *ngIf="!headerFacet && !headerTemplate">{{ header }}</span>
                <span [id]="ariaLabelledBy" class="p-dialog-title" *ngIf="headerFacet">
                    <ng-content select="p-header"></ng-content>
                </span>
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                <div class="p-dialog-header-icons">
                    <button *ngIf="maximizable" role="button" type="button"
                        [ngClass]="{ 'p-dialog-header-icon p-dialog-header-maximize p-link': true }"
                        (click)="maximize()" (keydown.enter)="maximize()" [attr.tabindex]="maximizable ? '0' : '-1'"
                        [attr.aria-label]="maximizeLabel" pRipple pButton>
                        <span *ngIf="maximizeIcon && !maximizeIconTemplate && !minimizeIconTemplate" class="p-dialog-header-maximize-icon" [ngClass]="maximized ? minimizeIcon : maximizeIcon"></span>
                        <ng-container *ngIf="!maximizeIcon">
                            <WindowMaximizeIcon *ngIf="!maximized && !maximizeIconTemplate" [styleClass]="'p-dialog-header-maximize-icon'" />
                            <WindowMinimizeIcon *ngIf="maximized && !minimizeIconTemplate" [styleClass]="'p-dialog-header-maximize-icon'" />
                        </ng-container>
                        <ng-container *ngIf="!maximized">
                            <ng-template *ngTemplateOutlet="maximizeIconTemplate"></ng-template>
                        </ng-container>
                        <ng-container *ngIf="maximized">
                            <ng-template *ngTemplateOutlet="minimizeIconTemplate"></ng-template>
                        </ng-container>
                    </button>
                    <button *ngIf="closable" type="button"
                        [ngClass]="{ 'p-dialog-header-icon p-dialog-header-close p-link': true }"
                        [attr.aria-label]="closeAriaLabel" (click)="close($event)" (keydown.enter)="close($event)"
                        pRipple pButton [attr.tabindex]="closeTabindex" [ngStyle]="{ 'min-width': 0 }">
                        <ng-container *ngIf="!closeIconTemplate">
                            <span *ngIf="closeIcon" class="p-dialog-header-close-icon" [ngClass]="closeIcon"></span>
                            <TimesIcon *ngIf="!closeIcon" [styleClass]="'p-dialog-header-close-icon'" />
                        </ng-container>
                        <span *ngIf="closeIconTemplate">
                            <ng-template *ngTemplateOutlet="closeIconTemplate"></ng-template>
                        </span>
                    </button>
                </div>
            </div>
            <div #content [ngClass]="'p-dialog-content'" [ngStyle]="contentStyle" [class]="contentStyleClass">
                <ng-content></ng-content>
                <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
            </div>
            <div #footer class="p-dialog-footer" *ngIf="footerFacet || footerTemplate">
                <ng-content select="p-footer"></ng-content>
                <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
            </div>
        </ng-template>
    </div>
</div>