import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppUrlService } from './nav/app-url.service';
import { AppUiCustomizationService } from './ui/app-ui-customization.service';
import { AppSessionService } from './session/app-session.service';
import { CookieConsentService } from './session/cookie-consent.service';
import { PageTitleService } from './nav/page-title-service';
import { BreadcrumbService } from './nav/breadcrumb-service';
import { HelpTopicService } from './nav/help-topic-service';
import { SideMenuService } from './nav/side-menu-service';
import { UIDragService } from './nav/ui-drag-service';
import { SuperDialogComponent } from './super-dialog/super-dialog.component';
import { FocusTrapModule } from 'primeng/focustrap';
import { WindowMinimizeIcon } from 'primeng/icons/windowminimize';
import { WindowMaximizeIcon } from 'primeng/icons/windowmaximize';
import { TimesIcon } from 'primeng/icons/times';

@NgModule({
    imports: [CommonModule, FocusTrapModule, WindowMinimizeIcon, WindowMaximizeIcon, TimesIcon],
    declarations: [SuperDialogComponent],
    exports: [SuperDialogComponent]
})
export class LastPlannerCommonModule {
    static forRoot(): ModuleWithProviders<CommonModule> {
        return {
            ngModule: CommonModule,
            providers: [
                AppUiCustomizationService, 
                CookieConsentService, 
                AppSessionService, 
                AppUrlService, 
                PageTitleService, 
                BreadcrumbService,
                HelpTopicService,
                SideMenuService,
                UIDragService
            ]
        };
    }
}
