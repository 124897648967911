import { stringSnakeToCamel } from "@metronic/app/kt/_utils";

export class ColourHelper {
    
    public static getColourFromString(value: string): string {
        if (value === undefined || value == null || value == '') { return '#333333'; }

        while (value.length < 3) {
            let code = Math.floor(this.addChars(value) / 2);
            value += String.fromCharCode(code);
        }

        const code0 = value.charAt(0).toLowerCase().charCodeAt(0);
        const code1 = value.charAt(1).toLowerCase().charCodeAt(0);
        const code2 = value.charAt(2).toLowerCase().charCodeAt(0);
        const colorNum = code0.toString() + code1.toString() + code2.toString();
       
        //console.log(`code0: ${code0}, code1: ${code1}, code2: ${code2}`);
       
        var num = Math.round(0xffffff * parseInt(colorNum, 10));
        var r = num >> 16 & 255;
        var g = num >> 8 & 255;
        var b = num & 255;
        
        var hexR = r.toString(16);
        if (hexR.length == 1) { hexR = '0' + hexR; }
        var hexG = g.toString(16);
        if (hexG.length == 1) { hexG = '0' + hexG; }
        var hexB = b.toString(16);
        if (hexB.length == 1) { hexB = '0' + hexB; }

        //return `rgb(${r}, ${g}, ${b})`;
        let hexColour = `#${hexR}${hexG}${hexB}`;
        return hexColour;
    }

    public static getRecommendedTextColor(hex: string): string {
        //returns either black or white, depending on the intensity of the specified colour

        if (!hex || hex.length < 3) { return ''; }
        hex = hex.toLowerCase();

        if (hex.indexOf('#') == 0) {
            hex = hex.slice(1);
        }
    
        hex = this.convert3Hexto6Hex(hex);

        if (hex.length != 6) { return ''; }
    
        var r = parseInt(hex[0] + hex[1], 16);
        var g = parseInt(hex[2] + hex[3], 16);
        var b = parseInt(hex[4] + hex[5], 16);
    
        //calculaute YIQ ratio
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
        let result = (yiq >= 128) ? '#000000' : '#FFFFFF';

        return result;
    }

    public static hexColourToRGBA(hex: string, alpha: number): string {
        hex = '' + hex.replace('#', '').trim().toUpperCase();
        if (hex == '') { return 'rgba(0,0,0,0)'; }

        hex = this.convert3Hexto6Hex(hex);

        var r = parseInt(hex[0] + hex[1], 16);
        var g = parseInt(hex[2] + hex[3], 16);
        var b = parseInt(hex[4] + hex[5], 16);

        let rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;

        return rgba;
    }

    public static convert3Hexto6Hex(hex: string): string {
        if (hex.length == 3) {
            let parts = hex.split('');
            hex = `${parts[0]}${parts[0]}${parts[1]}${parts[1]}${parts[2]}${parts[2]}`;
        }
        return hex;
    }

    private static addChars(value: string): number {
        let total = 0;
        for(let i = 0; i < value.length; i++) {
            total += value.charCodeAt(i);
        }
        return total;
    }
}
