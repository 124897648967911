import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'eleco-progressbar',
  templateUrl: './eleco-progressbar.component.html',
  styleUrls: ['./eleco-progressbar.component.scss']
})
export class ElecoProgressbarComponent implements OnInit { //OnChanges

  @Input() value: number;

  get widthPC(): string {
    return `${this.value}%`;
  }

  constructor() {

  }

  ngOnInit(): void {
    
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   // if (changes && changes.value) {
      
  //   // }
  // }

}
