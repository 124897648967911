<div class="eleco-pattern-picker" dropdown #dropdown="bs-dropdown" container="body" [autoClose]="true">
    <div class="input-group" dropdownToggle>
        <input type="text" [id]="id ? id : null" [name]="name ? name : null" class="form-control"
            maxlength="7" readonly />
        <button type="button" class="dropdown-toggle" aria-controls="icon-dropdown">
            <i class="ds-icon-default-chevron-down font-bold" [attr.aria-label]="'Icon' | localize"></i>
        </button>
        <!-- <div class="pattern-block" [style.backgroundImage]="displayValue" [style.backgroundColor]="bgColor"  -->
        <div class="pattern-block" [style.backgroundImage]="sampleBgImage" [style.backgroundColor]="sampleBgColor">
            <!-- [style.borderColor]="foreColor"> -->
            <div class="sample-text">{{ sampleText }}</div>
        </div>
    </div>
    <div id="pattern-dropdown" *dropdownMenu class="dropdown-menu">
        <div class="pattern-container">
            <div class="pattern-list">
                <i *ngFor="let pattern of patterns" [class.selected]="pattern.patternId == value" (click)="patternClick(pattern)">
                    <img [hidden]="pattern.patternId == 0 || pattern.patternId == 8" [src]="pattern.patternImgUrl" />
                    <div [hidden]="pattern.patternId != 0 && pattern.patternId != 8" 
                        [style.backgroundColor]="pattern.patternId == 0 ? bgColor : pattern.patternId == 8 ? foreColor : 'none'">
                    </div>
                </i>
            </div>
        </div>
    </div>
</div>
