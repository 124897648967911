import { Component, OnInit } from '@angular/core';

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PendingChangesConfirmationComponent } from './pending-changes-confirmation.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard  {

  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {};

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    if (!component.canDeactivate) { return true; }
    
    return component.canDeactivate() ?
      of(true) :
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355
      this.openConfirmDialog();
  }

  openConfirmDialog(): Observable<boolean> {
    this.modalRef = this.modalService.show(PendingChangesConfirmationComponent);
    return this.modalRef.content.onClose;
  }
}