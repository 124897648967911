export class SizeAndPositionModel {
    top: number;
    left: number;
    width: number;
    height: number;
    xOffset: number;
    yOffset: number;
    
    constructor() { }

    public static fromPosition(x: number, y: number): SizeAndPositionModel {
        let sap = new SizeAndPositionModel();
        sap.top = y;
        sap.left = x;
        sap.width = 0;
        sap.height = 0;
        sap.xOffset = 0;
        sap.yOffset = 0;
        return sap;
    }
}