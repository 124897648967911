import { Component, Output, EventEmitter, OnInit, Input, forwardRef, ChangeDetectionStrategy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DateTime } from 'luxon';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { update } from 'lodash-es';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    }
  ]
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit {

  @Input() disabled = false;
  @Input() readonly = false;

  private _value: DateTime;
  @Input()
  get value(): DateTime {
    return this._value;
  }
  set value(val: DateTime){
    this._value = val;
    this.splitValue();
  }

  // private _datepart: string;
  // get datepart(): string {
  //   return this._datepart;
  // }
  // set datepart(val: string) {
  //   this._datepart = val;
  //   this.buildValue();
  // }

  private _datepart: Date;
  get datepart(): Date {
    return this._datepart;
  }
  set datepart(val: Date) {
    this._datepart = val;
    this.buildValue();
  }

  private _timepart: string;
  get timepart():string {
    return this._timepart;
  }
  set timepart(val: string) {
    this._timepart = val;
    this.buildValue();
  }

  inputgroupfocus: boolean;
  private dateFocusFlag: boolean = false;
  private timeFocusFlag: boolean = false;

  constructor() {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit() : void {

  }

  splitValue(): void {
    if (!this._value) {
      this._datepart = null;
      this._timepart = null;  
      return;
    }
    // this._datepart = this._value.toFormat('yyyy-MM-dd');
    this._datepart = this._value.toJSDate();
    this._timepart = this._value.toFormat('HH:mm');
  }

  buildValue(): void {
    // if (!this._datepart) { this._datepart = DateTime.now().toFormat('yyyy-MM-dd'); }
    if (!this._datepart) { this._datepart = DateTime.now().toJSDate(); }
    if (!this._timepart) { this._timepart = DateTime.now().toFormat('HH:mm'); }
    let timeparts = this._timepart.split(':');
    let hours: number = parseInt(timeparts[0], 10);
    let minutes: number = parseInt(timeparts[1], 10);
    // this.value = DateTime.fromFormat(this._datepart, 'yyyy-MM-dd').set({ hour: hours, minute: minutes });
    this.value = DateTime.fromJSDate(this._datepart).set({ hour: hours, minute: minutes, second: 0, millisecond: 0 });
    this.onChange(this.value); //<-- this is vital to pushing the changes out to the model binding
  }
  
  //BEGIN: ControlValueAccessor implementation
  onChange: any = () => {}
  onTouch: any = () => {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: DateTime) {
    this.value = value;
  }
  //END: ControlValueAccessor implementation

  //BEGIN: methods for ensuring the border of the whole input group remains in sync when a control is focussed

  dateFocus() {
    this.dateFocusFlag = true;
    this.update();
  }

  dateBlur() {
    this.dateFocusFlag = false;
    this.update();
  }

  timeFocus() {
    this.timeFocusFlag = true;
    this.update();
  }

  timeBlur() {
    this.timeFocusFlag = false;
    this.update();
  }

  update() {
    this.inputgroupfocus = this.dateFocusFlag || this.timeFocusFlag;
  }
}
