import { Injectable, Injector, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class ProjectsSignalrService extends AppComponentBase {

    private readonly serviceUri = abp.appPath + 'signalr-messaging';
    private projectsHub: HubConnection;
    private isConnected = false;

    constructor(injector: Injector, public _zone: NgZone) {
        super(injector);
    }

    configureConnection(connection: HubConnection): void {
        this.projectsHub = connection;
        this.projectsHub.serverTimeoutInMilliseconds = 120 * 1000; //2 minutes
        this.projectsHub.keepAliveIntervalInMilliseconds = 60 * 1000; //1 minute

        // Reconnect loop
        let reconnectTime = 5000;
        let tries = 1;
        let maxTries = 8;

        function start() {
            return new Promise((resolve, reject) => {
                if (tries > maxTries) {
                    reject();
                } else {
                    if (tries > 1) {
                        this.log.warn(`projectsHub signalr connection - retry #${tries - 1}`);
                    }
                    connection.start()
                        .then(resolve)
                        .then(() => {
                            reconnectTime = 5000;
                            tries = 1;
                        })
                        .catch(() => {
                            setTimeout(() => {
                                start().then(resolve);
                            }, reconnectTime);
                            reconnectTime *= 2;
                            tries += 1;
                        });
                }
            });
        }

        // Reconnect if hub disconnects
        connection.onclose((e) => {
            this.isConnected = false;

            start().then(() => {
                this.isConnected = true;
            });
        });

        // Register to get notifications
        this.registerEvents(connection);
    }

    registerEvents(connection): void {
        connection.on('sendProjectImportProgressUpdate', (importFileToken: string, stage: number, progress: number, newProjectId?: number, exceptionMessage?: string) => {
            abp.event.trigger('app.project.sendProjectImportProgressUpdate', {
                importFileToken: importFileToken,
                stage: stage,
                progress: progress,
                newProjectId: newProjectId,
                exceptionMessage: exceptionMessage
            });
        });

    }

    associateJob(jobid: string): void {
        if (!this.isConnected) { return; }
        this.projectsHub.invoke("AssociateJob", jobid);
    }
    
    init(): void {
        this._zone.runOutsideAngular(() => {
            abp.signalr.autoConnect = true;
            (abp.signalr as any).autoReconnect = true;
            (abp.signalr as any).reconnectTime = 5000;

            abp.signalr.connect();
            abp.signalr.startConnection(this.serviceUri, (connection: HubConnection) => {
                    this.configureConnection(connection);
                })
                .then(() => {
                    abp.event.trigger('app.signalr-messaging.connected');
                    this.isConnected = true;
                });
        });
    }
}
