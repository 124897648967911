import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PageTitleService {

  private lasturl: string = '';
  private pageTitle$ = new BehaviorSubject<any>({});
  title$ = this.pageTitle$.asObservable();
  
  constructor(private router: Router) {
    router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (ev.url != this.lasturl) {
          this.lasturl = ev.url;
          this.setPageTitle('');
        }
      }
    });
  }

  setPageTitle(title: string) {
    this.pageTitle$.next(title);
  }
}