<div class="whiteboard-outer-container whiteboard-scale-{{ scale }}" #whiteboardOuterContainer>
    <div class="whiteboard-inner-container" [class.readonly]="readonly || multiSelectModeEnabled">
        <div class="cols">
            <div class="col-top-left">
                <div #linkDragImage class="link-drag-image"></div>
                <button type="button" class="gantt-row-toggle ds light outlined" (click)="toggleGanttRow()">
                    <i class="ds-icon-default-org"></i>
                    {{ 'WhiteboardGanttHeaderText' | localize }}
                </button>
                <button type="button" class="teams-legend ds light outlined" (click)="toggleLegend()">
                    <i class="ds-icon-default-puzzle"></i>
                    {{ 'WhiteboardTeamsLegendButton' | localize }}
                </button>
            </div>
            <div class="col-headers hidden-scroll" #colheaders>
                <div class="week-headers" [hidden]="!showWeekNumbers">
                    <div class="week-header" [class]="week.cssclass" *ngFor="let week of weekHeaders">{{ 'Week' | localize }}:&nbsp;{{ week.weekNumber ?? '' }}</div>
                </div>
                <div *ngFor="let column of columns; trackBy: trackColumn" class="col-header"
                    [class]="column.multiplierClass" [class.weekend]="column.isWeekend">
                    <button type="button" class="col-edit-btn" 
                        [hidden]="readonly || multiSelectModeEnabled || !(column.index == 0 || column.index == columns.length - 1)"
                        (click)="toggleColumnMenu($event, column)">
                        <i class="ds-icon-default-chevron-down"></i>
                    </button>
                    <span [hidden]="scale >= 3" [innerHTML]="column.title"></span>
                    <span [hidden]="scale <= 2" [innerHTML]="column.compactTitle"></span>
                    <div class="select-checkbox-container" [hidden]="!multiSelectModeEnabled" (click)="toggleColumnSelection(column)">
                        <div class="select-checkbox">
                            <i class="pi pi-check" [class.hidden]="!column.selected"></i>
                        </div>
                    </div>
                </div>
                <div class="col-header-end">&nbsp;</div>
            </div>
        </div>
        <div class="gantt-row" [class.expanded]="ganttRowVisible" 
            [class.collapsed]="!ganttRowVisible" [style.height]="ganttRowVisible ? ganttContentsMinHeightStyle : '0px'">
            <div class="gantt-row-header">
                <div class="gantt-row-header-scroll-container hidden-scroll" #ganttrowheaders>
                    <table class="gantt-row-header-grid">
                        <tr *ngFor="let activity of activities; trackBy: trackGanttActivity">
                            <td class="noselect" [class.editmode]="!readonly && !multiSelectModeEnabled && activity.activityType == ActivityTypeEnum.Activity"
                                [title]="activity.activityName">
                                {{ activity.activityName }}
                                <button type="button" class="gantt-row-edit-btn" title="" [hidden]="readonly || multiSelectModeEnabled || activity.activityType != ActivityTypeEnum.Activity"
                                    (click)="toggleGanttRowMenu($event, activity)">
                                    <i class="ds-icon-default-chevron-down"></i>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="gantt-row-contents">
                <div class="gantt-row-contents-scroll-container" #ganttcontents><!-- hidden-scroll-->
                    <!-- <table class="gantt-contents-grid">
                        <tr *ngFor="let activity of activities; trackBy: trackGanttActivity">
                            <td *ngFor="let column of columns; trackBy: trackColumn" [class]="column.multiplierClass" [class.weekend]="column.isWeekend"></td>
                            <td class="gantt-col-end">&nbsp;</td>
                        </tr>
                    </table> -->
                    <div class="gantt-grid-cols">
                        <div *ngFor="let column of columns; trackBy: trackColumn" class="gantt-grid-col" [class.weekend]="column.isWeekend" 
                            [style.width]="(column.multiplier * cellSize) + 'px'" [style.height]="(activities?.length * ganttActivityHeight) + 'px'"></div>
                        <div class="gantt-grid-col-end" [style.height]="(activities?.length * ganttActivityHeight) + 'px'"></div>
                    </div>
                    <div class="gantt-grid-rows" [style.width]="ganttGridWidth + 'px'">
                        <div *ngFor="let activity of activities; trackBy: trackGanttActivity" class="gantt-grid-row"></div>
                    </div>
                    <ng-container *ngFor="let activity of activities; let i = index"><!-- ; trackBy: trackGanttActivity; -->
                        <div class="gantt-activity" 
                            [class.gantt-type-summary]="activity.activityType == 1"
                            [class.gantt-type-activity]="activity.activityType == 0" 
                            [class.gantt-type-milestone]="activity.activityType == 2" 
                            [style.opacity]="ganttRowVisible ? 1 : 0" [hidden]="activity.left == ''"
                            [style.left]="activity.left" [style.top]="activity.top" [style.width]="activity.width"
                            (mouseenter)="onActivityMouseEvent($event, activity)" (mouseleave)="onActivityMouseEvent($event, activity)">
                            <span class="gantt-activity-text" [hidden]="activity.activityType == 2">{{ activity.activityName }}</span>
                        </div>
                        <gantt-activity-link #activityLink *ngFor="let link of activity.links; trackBy: trackGanttActivityLink" [id]="link.id"
                            [activityId]="link.linkActivityId" [parentActivityId]="link.linkPredecessorId" 
                            [linkType]="link.linkType" [linkLag]="link.linkLag" >
                        </gantt-activity-link>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="rows" [style.height]="'calc(100% - ' + columnHeadersHeight + 'px - ' + (ganttRowVisible ? ganttContentsMinHeightStyle : '0px') + ')'">
            <div class="row-headers hidden-scroll" #rowheaders>
                <!--if ((event.target.attributes['data-row-type'].value == 0 || event.target.attributes['data-row-type'].value == 2) && event.dataTransfer.effectAllowed == 'move') { event.preventDefault(); }"-->
                <div *ngFor="let row of rows; trackBy: trackRow;" #rowHeader class="row-header" [class]="row.multiplierClass"
                    [class.row-type-baseline]="row.type == RowItemType.BaselineProjectTeam || row.type == RowItemType.BaselineLocation" 
                    [class.location-row]="row.type == RowItemType.Location" [class.project-team-row]="row.type == RowItemType.ProjectTeam"
                    [attr.data-row-index]="row.index" [attr.data-row-type]="row.type" [attr.data-row-swimlane-id]="row.swimlane.id"
                    [attr.data-dropzone]="row.type == RowItemType.ProjectTeam || row.type == RowItemType.Location"
                    ondragover="if (event.target.attributes['data-dropzone'] && event.target.attributes['data-dropzone'].value == 'true') { event.preventDefault(); }"
                    (dragenter)="onRowHeaderDragEnter($event, row)" (dragleave)="onRowHeaderDragLeave($event, row)"
                    (drop)="onRowHeaderDrop($event, row)">
                    <button type="button" class="row-edit-btn" title="" 
                        [hidden]="readonly || multiSelectModeEnabled || !(row.type == RowItemType.ProjectTeam || row.type == RowItemType.Location)"
                        (click)="toggleRowMenu($event, row)">
                        <i class="ds-icon-default-chevron-down"></i>
                    </button>
                    <span class="row-header-text" [title]="row.title"
                        [attr.data-dropzone]="row.type == RowItemType.ProjectTeam || row.type == RowItemType.Location"
                        [hidden]="!row.swimlane.deleted && (row.type == RowItemType.BaselineProjectTeam || row.type == RowItemType.BaselineLocation)">
                        {{ row.title }}
                    </span>
                    <span class="row-header-team-colour" [style.backgroundColor]="row.swimlane.swimlaneTeamColour" 
                        [style.display]="displayMode == DisplayModeEnum.SwimlanesByProjectTeam ? 'block' : 'none'"></span>
                    <div class="select-checkbox-container" [hidden]="!multiSelectModeEnabled || !(row.type == RowItemType.ProjectTeam || row.type == RowItemType.Location)" (click)="toggleRowSelection(row)">
                        <div class="select-checkbox">
                            <i class="pi pi-check" [class.hidden]="!row.selected"></i>
                        </div>
                    </div>
                    <div class="swimlane-deleted" [hidden]="!row.swimlane.deleted" title="{{ 'Deleted' | localize }}">
                        <i class="ds-icon-default-blocked rotate-90"></i>
                    </div>
                </div>
                <div class="row-header-end">&nbsp;</div>
            </div>
            <div class="grid-contents" #gridcontents>
                <ng-container *ngFor="let cell of gridCells; trackBy: trackCell">
                    <div #gridcell class="grid-cell" [style]="cell.style" [class]="cell.classes" [attr.data-row-type]="cell.row.type"
                        [attr.data-row-index]="cell.row.index" [attr.data-col-index]="cell.column.index"
                        ondragover="if ((event.target.attributes['data-row-type'].value == 0 || event.target.attributes['data-row-type'].value == 2) && event.dataTransfer.effectAllowed == 'move') { event.preventDefault(); }"
                        (dragenter)="onGridCellDragEnter($event, cell)" (dragleave)="onGridCellDragLeave($event, cell)"
                        (drop)="onGridCellDrop($event, cell)" (click)="onGridCellClick($event, cell)">
                    </div>
                </ng-container>
                <ng-container *ngFor="let task of tasks">
                    <tasknote #pullPlanTask [pullPlanTask]="task" [source]="'whiteboard'" [hide]="true" [scale]="scale" [slide]="false" [inTaskList]="false"
                        [dragndrop]="!(readonly || multiSelectModeEnabled || task.taskLocked)" [allowTooltip]="true"
                        [dragndroplinks]="!readonly && !multiSelectModeEnabled" [allowGrips]="!readonly && !multiSelectModeEnabled && showLinks" 
                        [editable]="!readonly && !multiSelectModeEnabled" [hoverClick]="(!readonly || showBaselineContent) && !multiSelectModeEnabled"
                        [showSelectCheckbox]="multiSelectModeEnabled" [selected]="task.selected" [readonly]="readonly"
                        [showSnapshotComparisonIcons]="showSnapshotComparisonIcons" [displayMode]="displayMode"
                        [displayModeTeamFieldName]="displayModeTeamFieldName" [displayModeLocationFieldName]="displayModeLocationFieldName"
                        (SelectTask)="taskSelectionChanged($event)"
                        (DragEvent)="onTaskDragEvent($event)" (LinkDragEvent)="onTaskLinkDragEvent($event)" 
                        (MouseEvent)="onTaskMouseEvent($event, false)" (EditTask)="onTaskEdit($event)" (DuplicateTask)="onTaskDuplicate($event)" 
                        (RemoveTask)="onRemoveTask($event)" (DeleteTask)="onDeleteTask($event)"
                        (CompleteTask)="onCompleteTask($event)">
                    </tasknote>
                </ng-container>
                <ng-container *ngIf="showBaselineContent">
                    <ng-container *ngFor="let baselineTask of baselineTasks; trackBy: trackBaselineTask">
                        <baseline-tasknote #baselineTask [baselineTask]="baselineTask" [scale]="scale" [hide]="true"
                            (MouseEvent)="onTaskMouseEvent($event, true)">
                        </baseline-tasknote>
                    </ng-container>
                </ng-container>
                <ng-container>
                    <pullplan-task-link #taskLink *ngFor="let link of predecessorLinks; trackBy: trackTaskLinks" [id]="link.id" 
                        [taskId]="link.taskId" [parentTaskId]="link.predecessorTaskId" [visible]="false">
                    </pullplan-task-link>
                </ng-container>
                <pullplan-task-link #taskDragLink [id]="0" [visible]="true" [bringToForeground]="true"></pullplan-task-link>
            </div>
        </div>
    </div>
</div>
<p-menu #columnMenu [popup]="true" appendTo="body" [model]="columnMenuItems"></p-menu>
<p-menu #rowMenu [popup]="true" appendTo="body" [model]="rowMenuItems"></p-menu>
