<div appBsModal #changePasswordModal="bs-modal" class="modal fade" tabindex="-1"
    role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true"
    [config]="{ backdrop: 'static' }" (onShown)="onShown()">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #changePasswordModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h2 class="modal-title">
                        <span>{{ 'ChangePassword' | localize }}</span>
                    </h2>
                    <button type="button" class="btn-modal-help" (click)="showHelp()" [title]="'Help' | localize" [attr.aria-label]="'Help' | localize">
                        <i class="ds-icon-default-circle-question"></i>
                    </button>
                    <button type="button" class="btn-close" (click)="close()" [attr.aria-label]="'Close' | localize" [disabled]="saving"></button>
                </div>
                <div class="modal-body pt-0 pb-3">
                    <div class="mb-5">
                        <label for="CurrentPassword">{{ 'CurrentPassword' | localize }}</label>
                        <input type="password" id="CurrentPassword" name="CurrentPassword" class="form-control limit-width"
                        [(ngModel)]="currentPassword" #currentPasswordInput="ngModel" required />
                        <validation-messages [formCtrl]="currentPasswordInput"></validation-messages>
                    </div>
                    <div class="mb-5">
                        <label for="NewPassword">{{ 'NewPassword' | localize }}</label>
                        <input type="password" id="NewPassword" name="NewPassword" class="form-control limit-width"
                            [(ngModel)]="password" #NewPassword="ngModel" validateEqual="NewPasswordRepeat"
                            [requireDigit]="passwordComplexitySetting.requireDigit"
                            [requireLowercase]="passwordComplexitySetting.requireLowercase"
                            [requireUppercase]="passwordComplexitySetting.requireUppercase"
                            [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                            [requiredLength]="passwordComplexitySetting.requiredLength"
                            reverse="true" required />
                    </div>
                    <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                        <ul class="help-block text-danger" *ngIf="NewPassword.errors">
                            <li [hidden]="!NewPassword.errors.requireDigit">
                                {{ 'PasswordComplexity_RequireDigit_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPassword.errors.requireLowercase">
                                {{ 'PasswordComplexity_RequireLowercase_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPassword.errors.requireUppercase">
                                {{ 'PasswordComplexity_RequireUppercase_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPassword.errors.requireNonAlphanumeric">
                                {{ 'PasswordComplexity_RequireNonAlphanumeric_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPassword.errors.requiredLength">
                                {{ 'PasswordComplexity_RequiredLength_Hint' | localize: passwordComplexitySetting.requiredLength }}
                            </li>
                        </ul>
                    </div>
                    <div class="mb-5">
                        <label for="NewPasswordRepeat">{{ 'NewPasswordRepeat' | localize }}</label>
                        <input type="password" id="NewPasswordRepeat" name="NewPasswordRepeat" class="form-control limit-width"
                            [ngModel]="confirmPassword" #NewPasswordRepeat="ngModel" validateEqual="NewPassword"
                            [requireDigit]="passwordComplexitySetting.requireDigit"
                            [requireLowercase]="passwordComplexitySetting.requireLowercase"
                            [requireUppercase]="passwordComplexitySetting.requireUppercase"
                            [requireNonAlphanumeric]="passwordComplexitySetting.requireNonAlphanumeric"
                            [requiredLength]="passwordComplexitySetting.requiredLength"
                            reverse="false" required />
                    </div>
                    <div [hidden]="changePasswordModalForm.form.valid || changePasswordModalForm.form.pristine">
                        <ul class="help-block text-danger" *ngIf="NewPasswordRepeat.errors">
                            <li [hidden]="!NewPasswordRepeat.errors.requireDigit">
                                {{ 'PasswordComplexity_RequireDigit_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireLowercase">
                                {{ 'PasswordComplexity_RequireLowercase_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireUppercase">
                                {{ 'PasswordComplexity_RequireUppercase_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPasswordRepeat.errors.requireNonAlphanumeric">
                                {{ 'PasswordComplexity_RequireNonAlphanumeric_Hint' | localize }}
                            </li>
                            <li [hidden]="!NewPasswordRepeat.errors.requiredLength">
                                {{ 'PasswordComplexity_RequiredLength_Hint' | localize: passwordComplexitySetting.requiredLength }}
                            </li>
                            <li [hidden]="NewPasswordRepeat.valid">{{ 'PasswordsDontMatch' | localize }}</li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <div>
                        <button type="button" class="ds outlined dark me-3 min-width" (click)="close()" [disabled]="saving">
                            {{ 'Cancel' | localize }}
                        </button>
                        <button type="submit" class="ds min-width" [disabled]="!changePasswordModalForm.form.valid || saving">
                            {{ 'Save' | localize }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
