<div class="user-link-manager">
    <div class="user-add-controls" [hidden]="readonly">
        <user-select-dropdown [id]="id ? id : null" [name]="name ? name : null" #userSelectControl [multiSelect]="true"
            [userList]="userList" 
            (selectionChanged)="userSelectionChanged($event)"
            [showMultiSelectCount]="showMultiSelectCount">
        </user-select-dropdown>
    </div>
    <div class="user-link-manager-container" [style.height]="formatHeight()" [class]="readonly ? '' : 'mt-2'">
        <div *ngFor="let user of linkedUsers" class="userlink-row" [class.readonly]="readonly">
            <user-monogram [userName]="user.fullName"></user-monogram>
            <span class="username noselect">{{ user.fullName }}</span>
            <!-- <span class="receive-notifications {{ user.selected ? 'selected' : '' }}" role="button" 
                [title]="'TeamMemberReceiveNotifications' | localize" (click)="toggleNotifications(user)">
                <i class="ds-icon-default-notification notification-icon"></i>
                <i class="{{ user.selected ? 'ds-icon-default-circle-tick' : 'ds-icon-default-circle-close' }} selection-icon"></i>
            </span> -->
            <span *ngIf="!readonly" class="action-button delete" role="button" [title]="'Remove' | localize" (click)="removeUser(user.id)">
                <i class="ds-icon-default-trash"></i>
            </span>
        </div>
    </div>
</div>