import { Renderer2 } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import * as rtlDetect from 'rtl-detect';

export class ColumnDefinition {
    name: string;
    ordinalIndex: number;
    width: number;

    constructor(name: string, ordinalIndex: number, width: number) {
        this.name = name;
        this.ordinalIndex = ordinalIndex;
        this.width = width;
    }
}

export class FilterDefinition {
    name: string;
    selectedValue: any;

    constructor(name: string, selectedValue: any) {
        this.name = name;
        this.selectedValue = selectedValue;
    }
}

export class ColumnSortDefinition {
    columnName: string;
    ascending: boolean;

    constructor(columnName: string, ascending: boolean) {
        this.columnName = columnName;
        this.ascending = ascending;
    }
}

export class ListCache {
    filters: FilterDefinition[] = [];
    sort: ColumnSortDefinition = new ColumnSortDefinition('', true);
    pageSize: number = 10;
    pageIndex: number = 1;
}

export class PrimengTableHelper {

    public readonly SearchTextFilterName = '$$$SearchText';
    public readonly AdvancedFiltersEnabledFilterName = '$$$ShowAdvancedFilters';


    minColumnWidth: number = 80;

    predefinedRecordsCountPerPage = [5, 10, 25, 50, 100, 250, 500];
    defaultRecordsCountPerPage = 10;
    isResponsive = true;
    resizableColumns: false;
    totalRecordsCount = 0;
    records: any[];
    isLoading = false;

    private renderer: Renderer2;
    private dataTable: Table;
    columns: ColumnDefinition[] = [];
    private colwidths_storagekey_format = 'user.{0}.{1}.colwidths';
    private colwidths_storagekey = '';
    private list_storagekey_format = 'user.{0}.{1}.list';

    showLoadingIndicator(): void {
        setTimeout(() => {
            this.isLoading = true;
        }, 0);
    }

    hideLoadingIndicator(): void {
        setTimeout(() => {
            this.isLoading = false;
        }, 0);
    }

    getSorting(table: Table): string {
        let sorting = '';

        if (table.sortMode === 'multiple') {
            if (table.multiSortMeta) {
                for (let i = 0; i < table.multiSortMeta.length; i++) {
                    const element = table.multiSortMeta[i];
                    if (i > 0) {
                        sorting += ',';
                    }
                    sorting += element.field;
                    if (element.order === 1) {
                        sorting += ' ASC';
                    } else if (element.order === -1) {
                        sorting += ' DESC';
                    }
                }
            }
        } else {
            if (table.sortField) {
                sorting = table.sortField;
                if (table.sortOrder === 1) {
                    sorting += ' ASC';
                } else if (table.sortOrder === -1) {
                    sorting += ' DESC';
                }
            }
        }

        return sorting;
    }

    getMaxResultCount(paginator: Paginator, event: LazyLoadEvent): number {
        if (paginator.rows) { return paginator.rows; }
        if (!event) { return 0; }
        return event.rows;
    }

    getSkipCount(paginator: Paginator, event: LazyLoadEvent): number {
        if (paginator.first) { return paginator.first; }
        if (!event) { return 0; }
        return event.first;
    }

    shouldResetPaging(event: LazyLoadEvent): boolean {
        if (!event /*|| event.sortField*/) {
            // if you want to reset after sorting, comment out parameter
            return true;
        }
        return false;
    }

    adjustScroll(table: Table) {
        const rtl = rtlDetect.isRtlLang(abp.localization.currentLanguage.name);
        if (!rtl) { return; }

        const body: HTMLElement = table.el.nativeElement.querySelector('.p-datatable-scrollable-body');
        const header: HTMLElement = table.el.nativeElement.querySelector('.p-datatable-scrollable-header');

        body.addEventListener('scroll', () => {
            header.scrollLeft = body.scrollLeft;
        });
    }

    initialiseColumns(datatable: Table, renderer: Renderer2, userId: number, pageName: string, columns: ColumnDefinition[]): void {
        this.dataTable = datatable;
        this.renderer = renderer;
        this.colwidths_storagekey = this.colwidths_storagekey_format.replace('{0}', userId.toString()).replace('{1}', pageName);
        this.columns = columns;

        let savedState = JSON.parse(window.localStorage.getItem(this.colwidths_storagekey));
        if (savedState != null && savedState.length) {
            for (let i = 0; i < savedState.length; i++) {
                let savedColumn = savedState[i] as ColumnDefinition;
                let column = this.columns.find(c => c.name == savedColumn.name);
                if (column != null) {
                    column.width = savedColumn.width;
                }
            }
        }

        this.setColumnSizes(this.dataTable, this.renderer);
    }

    onColResize(data: any): void {
        //, callback?: (column: ColumnDefinition) => void
        let delta = data.delta;
        let ele = (data.element as HTMLElement);

        //can use either data-column or pSortableColumn to specify column name
        let colname = ele.getAttribute('data-column');
        if (colname == null) { colname = ele.getAttribute('pSortableColumn'); }
        if (colname == null) { return; }

        let column = this.columns.find(c => c.name == colname);
        if (column == null) { return; }

        column.width = this.calcColSize(column.width, delta);
        if (column.width == this.minColumnWidth) {
            this.dataTable.resizeTableCells(column.width, null);
        }

        let json = JSON.stringify(this.columns);
        window.localStorage.setItem(this.colwidths_storagekey, json);

        //if (column != null) { callback(column); }
    }

    calcColSize(currentWidth: number, delta: number): number {
        if (currentWidth + delta < this.minColumnWidth) {
            return this.minColumnWidth;
        } else {
            return currentWidth + delta;
        }
    }

    setColumnSizes(datatable: Table, renderer: Renderer2): void {
        let innerHTML = '';
        let tableWidth = 0;

        this.columns.forEach(col => {
            innerHTML += `
                #${datatable.id}-table > .p-datatable-thead > tr > th:nth-child(${col.ordinalIndex + 1}),
                #${datatable.id}-table > .p-datatable-tbody > tr > td:nth-child(${col.ordinalIndex + 1}),
                #${datatable.id}-table > .p-datatable-tfoot > tr > td:nth-child(${col.ordinalIndex + 1}) {
                    width: ${col.width}px !important; max-width: ${col.width}px !important; min-width: ${this.minColumnWidth}px !important;
                }
            `;
            tableWidth += col.width;
        });

        innerHTML += `#${datatable.id}-table { width: ${tableWidth}px;min-width: ${tableWidth}px; }`;

        datatable.destroyStyleElement();
        datatable.createStyleElement();

        renderer.setProperty(datatable.styleElement, 'innerHTML', innerHTML);
    }

    private getListStorageKey(userId: number, pageName: string): string {
        return this.list_storagekey_format.replace('{0}', userId.toString()).replace('{1}', pageName);
    }

    restoreCachedListSettings(dataTable: Table, paginator: Paginator, userId: number, pageName: string,
        defaultSortField: string, defaultSortAscending: boolean, filtersHandler: (filters: FilterDefinition[]) => void): void {
        let savedState = JSON.parse(window.localStorage.getItem(this.getListStorageKey(userId, pageName))) as ListCache;

        if (savedState != null) {
            if (savedState.filters == null) { savedState.filters = []; }

            filtersHandler(savedState.filters);

            if (savedState.sort == null) {
                dataTable.sortField = defaultSortField;
                dataTable.sortOrder = defaultSortAscending ? 1 : -1;
            } else {
                dataTable.sortField = savedState.sort.columnName;
                dataTable.sortOrder = savedState.sort.ascending ? 1 : -1;
            }

            paginator.rows = savedState.pageSize;
            paginator._page = savedState.pageIndex;
            paginator._first = savedState.pageIndex * savedState.pageSize;
        } else {
            dataTable.sortField = defaultSortField;
            dataTable.sortOrder = defaultSortAscending ? 1 : -1;

            paginator.rows = 10;
            paginator._page = 0; //zero-based index
            paginator._first = 0;
        }

        paginator.updatePaginatorState();

        dataTable.sortSingle(); //this ensures the datatable shows the correct sort icon, and triggers onSort()
    }

    saveCachedListSettings(dataTable: Table, paginator: Paginator, filters: FilterDefinition[], userId: number, pageName: string): void {
        let savedState = new ListCache();

        savedState.filters = filters;

        savedState.sort.columnName = dataTable.sortField;
        savedState.sort.ascending = (dataTable.sortOrder == 1);

        savedState.pageSize = paginator.rows;
        savedState.pageIndex = Math.floor(paginator._first / paginator.rows);

        let json = JSON.stringify(savedState);
        window.localStorage.setItem(this.getListStorageKey(userId, pageName), json);
    }
}
