import { LocalizationService, MessageService, NotifyService } from 'abp-ng2-module';
import { Component, ElementRef, Injector, OnDestroy } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { DateTime } from 'luxon';
import { HelpTopicService } from './nav/help-topic-service';
import { UIDragService } from './nav/ui-drag-service';

@Component({
    template: '',
})
export abstract class CustomComponentBase implements OnDestroy {

    public emptyDate: DateTime = DateTime.fromFormat('1900-01-01', 'yyyy-MM-dd');

    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    localization: LocalizationService;
    notify: NotifyService;
    message: MessageService;
    helpTopicService: HelpTopicService;
    uiDragService: UIDragService;
    
    constructor(injector: Injector, ) {
        this.localization = injector.get(LocalizationService);
        this.notify = injector.get(NotifyService);
        this.message = injector.get(MessageService);
        this.helpTopicService = injector.get(HelpTopicService);
        this.uiDragService = injector.get(UIDragService);
    }

    ngOnDestroy(): void {
        
    }

    flattenDeep(array) {
        return array.reduce(
            (acc, val) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)),
            []
        );
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    /*
    * @param {number} xl extra-large size
    * @param {number} lg large size
    * @param {number} md medium size
    * @param {number} sm small size
    * @param {number} xs extra-small size
    */
    public bsGridCols(xl: number, lg: number, md: number, sm: number, xs: number): string {
        //generates the classes for bootstrap grid columns at each media breakpoint
        return `col col-xl-${xl} col-lg-${lg} col-md-${md} col-sm-${sm} col-${xs}`;
    }

    public getPosition(element: ElementRef): any {
        let offsetLeft = 0;
        let offsetTop = 0;
        let el = element.nativeElement;
        while(el) {
            offsetLeft += el.offsetLeft;
            offsetTop += el.offsetTop;
            el = el.offsetParent;
        }
        return { offsetTop: offsetTop, offsetLeft: offsetLeft }
    }

    public isNullOrUndefined(value: any): boolean {
        return (value == null || typeof value == 'undefined');
    }

    public isNullOrUndefinedOrNaN(value: any): boolean {
        if (this.isNullOrUndefined(value)) { return true; }
        let val = parseInt(value, 10);
        if (isNaN(val)) { return true; }
        return false;
    }

    public isNullOrUndefinedOrNaNOrZero(value: any): boolean {
        if (this.isNullOrUndefinedOrNaN(value)) { return true; }
        if (value == 0) { return true; }
        return false;
    }

    public isNullOrUndefinedOrEmptyString(value: any): boolean {
        if (this.isNullOrUndefined(value)) { return true; }
        if (typeof value != 'string') { return true; }
        if (value == '') { return true; }
        return false;
    }

    public arrayHasItems(arr: any[]): boolean {
        if (this.isNullOrUndefined(arr)) { return false; }
        return (arr && arr.length > 0);
    }

    public dateIfNullOrEmpty(date: DateTime | null | undefined, defaultDate: DateTime): DateTime {
        if (this.isNullOrUndefined(date) || date <= this.emptyDate) {
            return defaultDate;
        } else {
            return date;
        }
    }

    public setMidnight(dt: DateTime): DateTime {
        if (this.isNullOrUndefined(dt)) { return null; }
        return dt.startOf('day');
    }

    public get today(): DateTime {
        return this.zeroTime(DateTime.now());
    }

    public get now(): DateTime {
        return DateTime.now();
    }

    public dateDiff (dt1: DateTime, dt2: DateTime): number {
        //return milliseconds difference
        //if dt1 is earlier than dt2 then this will produce a positive number
        //return dt2.valueOf() - dt1.valueOf();
        return +dt2 - +dt1;
    }

    public zeroTime(dt: DateTime): DateTime {
        return dt.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    public areDatesEqual(dt1: DateTime, dt2: DateTime) {
        return dt1.day === dt2.day && dt1.month === dt2.month && dt1.year === dt2.year;
    }

    public isDateTimeEarlierThan(dt: DateTime, compareTo: DateTime): boolean {
        return this.dateDiff(dt, compareTo) > 0;
    }

    public isDateTimeLaterThan(dt: DateTime, compareTo: DateTime): boolean {
        return this.dateDiff(dt, compareTo) < 0;
    }

    public roundToPlaces(value: number, places: number): number {
        return +parseFloat(Math.round(parseFloat(`${value}e+${places}`))  + "e-" + places);
    }

    public showModalHelp(helpId: number): void {
        this.helpTopicService.showHelpPage(helpId);
    }

    public isDecimal(value: number): boolean {
        return value % 1 !== 0;
    }
}
