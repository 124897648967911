<div #container [ngClass]="containerClass()" [class]="styleClass" [ngStyle]="style">
    <!-- <ng-template ngFor let-panel let-i="index" [ngForOf]="panels"> -->
        <div [ngClass]="panelContainerClass()" [class]="panelStyleClass" [ngStyle]="panelStyle">
            <ng-container *ngTemplateOutlet="panels[0]"></ng-container>
        </div>
        <div class="p-splitter-gutter" [ngStyle]="gutterStyle()" 
            (mousedown)="onGutterMouseDown($event, 0)" (touchstart)="onGutterTouchStart($event, 0)">
            <div class="p-splitter-gutter-handle"></div>
        </div>
        <div [ngClass]="panelContainerClass()" [class]="panelStyleClass" [ngStyle]="panelStyle">
            <ng-container *ngTemplateOutlet="panels[1]"></ng-container>
        </div>
    <!-- </ng-template> -->
</div>