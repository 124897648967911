<div class="eleco-icon-picker" dropdown #dropdown="bs-dropdown" [autoClose]="true">
    <div class="input-group" dropdownToggle>
        <input type="text" [id]="id ? id : null" [name]="name ? name : null" class="form-control"
            maxlength="7" [(ngModel)]="displayValue" readonly />
        <button type="button" #dropdownToggleButton class="dropdown-toggle" aria-controls="icon-dropdown">
            <i class="ds-icon-default-chevron-down font-bold" [attr.aria-label]="'Icon' | localize"></i>
        </button>
        <span class="icon-block" [class]="value"></span>
    </div>
    <div id="icon-dropdown" *dropdownMenu class="dropdown-menu">
        <div class="icon-container">
            <div class="icon-list">
                <i *ngFor="let icon of sortAZ(icons)" [class]="icon" [class.selected]="icon == value" (click)="iconClick(icon)"></i>
            </div>
        </div>
    </div>
</div>
