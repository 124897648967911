<div class="projecttag-link-manager">
    <div class="projecttag-add-controls" [hidden]="readonly">
        <projecttag-select-dropdown [id]="id ? id : null" [name]="name ? name : null"
            #projectTagSelectControl [multiSelect]="true"
            [projectTagList]="projectTagsList" [projectId]="linkedProjectId"
            (selectionChanged)="projectTagSelectionChanged($event)"
            [showMultiSelectCount]="showMultiSelectCount">
        </projecttag-select-dropdown>
    </div>
    <div class="projecttag-manager-container" [style.height]="formatHeight()" [class]="readonly ? '' : 'mt-2'">
        <div *ngFor="let link of linkedProjectTags" class="projecttag-row" [class.readonly]="readonly">
            <eleco-tag [data]="link"></eleco-tag>
            <span *ngIf="!readonly" class="action-button delete" role="button" [title]="'Remove' | localize" (click)="removeProjectTag(link.id)">
                <i class="ds-icon-default-trash"></i>
            </span>
        </div>
    </div>
</div>