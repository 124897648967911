<div class="gantt-container">
    <div class="gantt-toolbar">
        <div class="warning-msg pt-1">
            <span class="text-red" *ngIf="tasksReadonly">{{ 'WhiteboardLockedTaskReadonly' | localize }}</span>
        </div>
        <div class="row m-0 p-0" [class.mt-6]="tasksReadonly" style="flex-grow: 10;">
            <div class="{{ bsGridCols(5, 5, 5, 5, 5) }} nowrap">
                <div class="d-inline-block">
                    <label class="form-label my-0 pt-1" for="FromDateFilter">{{ 'GanttDateFilter' | localize }}</label>
                    <div class="d-inline-block float-end pt-1">
                        <button type="button" class="mini-button" id="setDateFilterToday" (click)="set6WeekFilter()">{{ 'Gantt6Week' | localize }}</button>
                    </div>
                    <div class="input-group mb-1">
                        <app-date-picker id="FromDateFilter" name="FromDateFilter" 
                            [(ngModel)]="fromDateFilter" (ngModelChange)="filterValueChanged('FromDateFilter', $event)">
                        </app-date-picker>
                        <span style="line-height: 40px;font-size: 10px;padding: 4px 8px 0 8px;border: none;">&mdash;</span>
                        <app-date-picker id="ToDateFilter" name="ToDateFilter" 
                            [(ngModel)]="toDateFilter" (ngModelChange)="filterValueChanged('ToDateFilter', $event)">
                        </app-date-picker>
                    </div>
                </div>
            </div>
            <div class="{{ bsGridCols(5, 5, 5, 5, 5) }}">
                <label class="form-label mt-0 mb-1 pt-1" for="ProjectTagsFilter">{{ 'ProjectTags' | localize }}</label>
                <div class="mb-1" style="max-width: 350px;">
                    <projecttag-select-dropdown id="ProjectTagsFilter" name="ProjectTagsFilter" class="" #projectTagSelectControl
                        [projectTagList]="projectTags" [projectId]="projectId" [multiSelect]="true" (change)="filterValueChanged('ProjectTagsFilter', $event)">
                    </projecttag-select-dropdown>
                </div>
            </div>
            <div class="{{ bsGridCols(2, 2, 2, 2, 2) }} nowrap text-center pt-9">
                <button type="button" class="ds outlined dark" (click)="clearFilters()">{{ 'ClearFilters' | localize }}</button>
            </div>
        </div>

        <div class="px-3" [class.mt-6]="tasksReadonly" style="flex-shrink: 1;">
            <div class="form-group">
                <label class="form-label pt-1" for="ScaleDays">{{ 'GanttScaleLabel' | localize }}</label>
                <div style="padding-top: 2px;">
                    <div class="btn-group btn-group-sm form-control" role="group">
                        <input type="radio" id="ScaleDays" name="ScaleDays" class="btn-check" autocomplete="off"
                            [(ngModel)]="selectedScaleIndex" (ngModelChange)="scaleChanged()" [value]="0" />
                        <label class="btn btn-sm btn-outline-dark" for="ScaleDays">Days</label>

                        <input type="radio" id="ScaleWeeks" name="ScaleWeeks" class="btn-check" autocomplete="off" 
                            [(ngModel)]="selectedScaleIndex" (ngModelChange)="scaleChanged()" [value]="1" />
                        <label class="btn btn-sm btn-outline-dark" for="ScaleWeeks">Weeks</label>
                        
                        <input type="radio" id="ScaleMonths" name="ScaleMonths" class="btn-check" autocomplete="off"
                            [(ngModel)]="selectedScaleIndex" (ngModelChange)="scaleChanged()" [value]="2" />
                        <label class="btn btn-sm btn-outline-dark" for="ScaleMonths">Months</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="gantt1" #ganttcontrol class="gantt-component" [class.readonly]="readonly" style="min-height: 200px;" [style.height]="ganttHeight"></div>
</div>
