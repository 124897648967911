<!-- <div [@routerTransition]> -->
    <div class="content d-flex flex-column flex-column-fluid">
        <div [class]="containerClass">
            <div class="card card-custom gutter-b">
                <div class="card-body">
                    <form class="form" autocomplete="off">
                        <div class="row mb-5">
                            <div class="{{ bsGridCols(5, 4, 5, 8, 12) }}">
                                <!-- <eleco-text-search id="filterText" name="filterText" class="search-field" 
                                    [button]="true" [autofocus]="true" [(ngModel)]="filterText" 
                                    [placeholder]="'SearchWithThreeDot' | localize" [label]="'Search' | localize"
                                    (search)="getNotifications()" (buttonclick)="getNotifications()">
                                </eleco-text-search> -->
                            </div>
                            <div class="{{ bsGridCols(7, 8, 7, 12, 12) }} text-right button-strip mt-md-0 mt-3">
                                <button type="button" class="ds dark me-4" (click)="setAllNotificationsAsRead()">
                                    <i class="ds-icon-default-checklist"></i>
                                    {{ 'SetAllAsRead' | localize }}
                                </button>
                                <button type="button" class="ds light outlined me-4" (click)="openNotificationSettingsModal()">
                                    <i class="ds-icon-default-cog"></i>
                                    {{ 'NotificationSettings' | localize }}
                                </button>
                                <button type="button" (click)="toggleFilters()" class="ds light outlined" [class.active]="advancedFiltersAreShown">
                                    <i class="ds-icon-default-filter"></i>
                                    {{ 'Filter' | localize }}
                                </button>
                                <!-- <button type="button" class="ds dark me-4" (click)="getNotifications()">
                                    <i class="ds-icon-default-refresh"></i>
                                    {{ 'Refresh' | localize }}
                                </button>
                                <button type="button" class="ds me-4" (click)="deleteNotifications()">
                                    <i class="ds-icon-default-close"></i>
                                    {{ 'DeleteListedNotifications' | localize }}
                                </button> -->
                            </div>
                        </div>

                        <!-- advanced filters -->
                        <div class="row adv-filters pb-sm-0 pb-4" [hidden]="!advancedFiltersAreShown">
                            <div class="{{ bsGridCols(5, 7, 6, 10, 12) }}">
                                <div class="form-group">
                                    <label class="form-label me-4" for="FromDateFilter">{{ 'NotificationsDateRange' | localize }}</label>
                                    <span class="">
                                        <button type="button" class="mini-button" id="setDateFilterToday" (click)="setDateFilter(0)">{{ 'Today' | localize }}</button>
                                        <button type="button" class="mini-button" id="setDateFilterLast7" (click)="setDateFilter(7)">{{ 'Last7Days' | localize }}</button>
                                        <button type="button" class="mini-button" id="setDateFilterLast30" (click)="setDateFilter(30)">{{ 'Last30Days' | localize }}</button>
                                    </span>
                                    <div class="input-group">
                                        <app-date-picker id="FromDateFilter" name="FromDateFilter" 
                                            [(ngModel)]="fromDateFilter" (ngModelChange)="filterValueChanged('FromDateFilter')">
                                        </app-date-picker>
                                        <span style="line-height: 40px; padding: 0 15px 0 15px;border: none;">&mdash;</span>
                                        <app-date-picker id="ToDateFilter" name="ToDateFilter" 
                                            [(ngModel)]="toDateFilter" (ngModelChange)="filterValueChanged('ToDateFilter')">
                                        </app-date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="{{ bsGridCols(2, 2, 3, 4, 6) }}">
                                <div class="form-group">
                                    <label class="form-label" for="ReadStateFilter">{{ 'NotificationsReadState' | localize }}</label>
                                    <select class="form-control" id="ReadStateFilter" name="readStateFilter"
                                        #TargetValueFilterSelectionCombobox [(ngModel)]="readStateFilter">
                                        <option value="ALL">{{ 'All' | localize }}</option>
                                        <option value="UNREAD">{{ 'Unread' | localize }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="{{ bsGridCols(2, 3, 3, 3, 3) }} button-strip pt-8">
                                <button type="button" class="ds outlined dark" (click)="clearFilters()">{{ 'ClearFilters' | localize }}</button>
                            </div>
                        </div>
                    </form>

                    <div class="row align-items-center">
                        <!--<Primeng-TurboTable-Start>-->
                        <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                            <p-table #dataTable styleClass="main-list" sortMode="single" (onLazyLoad)="getNotifications($event)"
                                [value]="primengTableHelper.records" rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                                [paginator]="false" [lazy]="true" [scrollable]="true"
                                ScrollWidth="100%" scrollDirection="horizontal">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [style]="getColumnWidth('actions', true)" class="text-center">
                                            {{ 'Actions' | localize }}
                                        </th>
                                        <th [style]="getColumnWidth('notification', false)">
                                            {{ 'Notification' | localize }}
                                        </th>
                                        <th [style]="getColumnWidth('severity', true)" class="text-center">
                                            {{ 'Severity' | localize }}
                                        </th>
                                        <th [style]="getColumnWidth('creationTime', true)">
                                            {{ 'NotificationReceived' | localize }}
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-record="$implicit">
                                    <tr class="no-hover">
                                        <td [style]="getColumnWidth('actions', true)" class="text-center">
                                            <button class="ds action-btn read" [disabled]="isRead(record)"
                                                [attr.title]="!isRead(record) ? l('SetAsRead') : ''" (click)="setAsRead(record)">
                                                <i class="ds-icon-default-check" *ngIf="isRead(record)" [title]="l('Read')"></i>
                                                <i class="ds-icon-default-circle-information" *ngIf="!isRead(record)" [title]="l('Unread')"></i>
                                            </button>
                                            <button class="ds action-btn" [attr.title]="l('Delete')"
                                                (click)="deleteNotification(record)">
                                                <i class="la la-times" [attr.aria-label]="l('Delete')"></i>
                                            </button>
                                        </td>
                                        <td [style]="getColumnWidth('notification', false)"  
                                            tooltip="{{ record.formattedNotification.text }}" 
                                            tooltipAppendToBody="true" tooltipFadeDuration="250" tooltipPlacement="top"
                                            adaptivePosition="true" container="body">
                                            <span class="p-column-title">{{ 'Notification' | localize }}</span>
                                            <a class="{{ getRowClass(record) }} text-ellipsis" *ngIf="record.formattedNotification.url"
                                                href="{{ record.formattedNotification.url }}">
                                                {{ record.formattedNotification.text }}
                                            </a>
                                            <span class="{{ getRowClass(record) }} text-ellipsis" *ngIf="!record.formattedNotification.url" >
                                                {{ record.formattedNotification.text }}
                                            </span>
                                        </td>
                                        <td [style]="getColumnWidth('severity', true)" class="text-center">
                                            <i class="{{ record.formattedNotification.icon }} {{ record.formattedNotification.iconFontClass }} severity-icon"
                                                title="{{ getNotificationTextBySeverity(record.formattedNotification.severity) }}"
                                            ></i>
                                        </td>
                                        <td [style]="getColumnWidth('creationTime', true)">
                                            <span class="{{ getRowClass(record) }}">
                                                {{ record.notification.creationTime | luxonFormat: 'F' }}
                                            </span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                                {{ 'NotificationsNoneToDisplay' | localize }}
                            </div>
                            <div class="primeng-paging-container">
                                <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                    #paginator (onPageChange)="getNotifications($event)"
                                    [totalRecords]="primengTableHelper.totalRecordsCount"
                                    [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                    [showCurrentPageReport]="true"
                                    [currentPageReportTemplate]="'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount">
                                </p-paginator>
                            </div>
                        </div>
                        <!--<Primeng-TurboTable-End>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- </div> -->
