<div id="#kt_aside_menu" data-kt-menu="true"
    class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
    <div class="menu-item mb-5" *ngIf="currentTheme.baseSettings.menu.searchActive"
        aria-haspopup="true" data-ktmenu-submenu-toggle="hover">
        <menu-search-bar></menu-search-bar>
    </div>
    <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
    <div class="menu-footer text-muted fw-bold me-1">
        {{ copyright }}
    </div>
</div>

<ng-template #menuListTemplate>
    <ng-container *ngFor="let child of menu.items">
        <ng-container *ngIf="showMenuItem(child)" [ngTemplateOutlet]="mMenuItem" 
            [ngTemplateOutletContext]="{ item: child, parentItem: null }">
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #mMenuItem let-item="item" let-parentItem="parentItem">
    <div *ngIf="showMenuItem(item)"
        [attr.data-kt-menu-trigger]="item.items.length ? 'click' : ''"
        [ngClass]="getItemCssClasses(item, parentItem)">
        <!-- if menu item hasn't submenu -->
        <a *ngIf="!item.items.length && !item.external" class="menu-link"
            [ngClass]="{ 'without-sub': !item.items.length }"
            [queryParams]="item.parameters" [routerLink]="item.route" routerLinkActive="active"
            [attr.aria-label]="l(item.name)"><!--iconMenu && parentItem == null ? -->
            <ng-container [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </a>
        <a *ngIf="!item.items.length && item.external" class="menu-link menu-toggle"
            [attr.href]="item.route" target="_blank">
            <ng-container [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
            </ng-container>
        </a>

        <!-- if menu item has submenu child  -->
        <div *ngIf="item.items.length">
            <a class="menu-link menu-toggle hassubmenu" [class]="{'active' : isMenuItemIsActive(item) }" 
                href="javascript:;" [attr.aria-label]="l(item.name)" (click)="popoutMenu.toggle($event)"><!--iconMenu && parentItem == null ? -->
                <ng-container [ngTemplateOutlet]="mMenuItemText"
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }">
                </ng-container>
            </a>
            <p-menu #popoutMenu [styleClass]="'sidemenu-submenu ' + (collapsed ? 'collapsed' : '')" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                [popup]="true" appendTo="body" [model]="item.pmenuitems"></p-menu>

            <!-- if menu item has submenu child then recursively call new menu item component -->
            <!--<div [class]="getSubMenuItemCssClass(item, parentItem)" 
                [style]="ui.getLeftAsideSubMenuStyles()" >
                <div class="menu-subnav">
                    <ng-container *ngFor="let child of item.items">
                        <ng-container [ngTemplateOutlet]="mMenuItem" 
                            [ngTemplateOutletContext]="{ item: child, parentItem: item }">
                        </ng-container>
                    </ng-container>
                </div>
            </div> -->
        </div>
    </div>
</ng-template>

<ng-template #mMenuItemText let-item="item" let-parentItem="parentItem">
    <span class="menu-item-here"></span>
    <!-- if menu item has icon -->
    <span class="menu-icon" *ngIf="item.icon" [attr.title]="item.name | localize">
        <i [ngClass]="item.icon"></i>
    </span>

    <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
        <!-- menu item title text -->
        <span class="menu-title" *ngIf="!iconMenu || parentItem != null">{{ item.name | localize }}</span>
    </ng-container>

    <ng-template #mMenuLinkBadge>
        <!-- menu item with badge -->
        <span class="menu-link-title">
            <span class="menu-link-wrap">
                <span class="menu-text">
                    {{ item.name | localize }}
                </span>
                <span class="menu-link-badge">
                    <span class="badge badge-primary" [ngClass]="item.badge.type">{{ item.badge.value }}</span>
                </span>
            </span>
        </span>
    </ng-template>

    <!-- if menu item has submenu child then put arrow icon -->
    <!-- <span *ngIf="item.items.length && (!iconMenu || parentItem != null)" class="menu-arrow"></span> -->
    <span *ngIf="item.items.length && (!iconMenu || parentItem != null)" style="transform: translateY(4px);">
        <i class="ds-icon-default-chevron-right"></i>
    </span>
</ng-template>
