import {
    AfterViewInit,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    Output,
    Type,
    ViewContainerRef
} from '@angular/core';

/*
  USAGE:
 
    <div myComponentOutlet
           [component]="inner.component"
           [injector]="inner.injector"
           (create)="componentCreated($event)"></div>
 */

export class ComponentCreateArgs<T> {
    componentRef: ComponentRef<T>;
    args: any;
    constructor(componentRef: ComponentRef<T>, args: any) {
        this.componentRef = componentRef;
        this.args = args;
    }
}

@Directive({
    selector: '[ComponentOutlet]',
})
export class ComponentOutletDirective<T> implements AfterViewInit, OnDestroy {

    @Input() component: Type<T>;
    @Input() injector: Injector;
    @Input() args: any;

    @Output() create = new EventEmitter<ComponentCreateArgs<T>>();
    @Output() destroy = new EventEmitter<ComponentCreateArgs<T>>();

    private componentRef: ComponentRef<T>;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
        private elRef: ElementRef,
        private globalInjector: Injector
    ) {
        this.injector = globalInjector;
    }

    ngAfterViewInit() {
        const injector = this.injector || this.globalInjector;
        const factory = this.resolver.resolveComponentFactory(this.component);

        //this.componentRef = this.viewContainerRef.createComponent(factory, 0, injector); //this method signature is deprecated - see new one below
        let options = { injector: this.injector };
        this.componentRef = this.viewContainerRef.createComponent(factory.componentType, options);

        //this next line causes the error: Failed to execute 'appendChild' on 'Node': This node type does not support this method
        //however, it is not needed as the element is added anyway!
        //this.elRef.nativeElement.appendChild(this.componentRef.location.nativeElement);

        this.create?.emit(new ComponentCreateArgs<T>(this.componentRef, this.args));

        this.componentRef.changeDetectorRef.detectChanges(); //important to avoid "expression changed.." error
    }

    ngOnDestroy(): void {
        this.destroy?.emit(new ComponentCreateArgs<T>(this.componentRef, this.args));
    }
}
