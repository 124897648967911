import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

export class CheckboxListItem {
    id: number;
    name: string;
    selected: boolean;

    constructor(id: number, name: string, selected: boolean) {
        this.id = id;
        this.name = name;
        this.selected = selected;
    }
}

@Component({
    selector: 'eleco-dropdown-checkboxlist',
    templateUrl: './eleco-dropdown-checkboxlist.component.html',
    styleUrls: ['./eleco-dropdown-checkboxlist.component.scss'],
})
export class ElecoDropdownCheckboxlistComponent extends AppComponentBase implements OnInit {

    private _items: CheckboxListItem[] = [];

    @Input() id: string = '';
    @Input() name: string = '';
    @Input() get items() {
        return this._items;
    }
    set items(val: CheckboxListItem[]) {
        let newItems = [];
        let anyItem = new CheckboxListItem(0, this.l('AnyFilterSelection'), false);

        if (this.isNullOrUndefined(val) || !this.arrayHasItems(val)) {
            anyItem.selected = true;
            newItems.push(anyItem);
        } else {
            let selected = val.filter(x => x.selected == true);
            if (this.isNullOrUndefined(selected) || !this.arrayHasItems(selected)) {
                anyItem.selected = true;
            }
            newItems = [...[anyItem], ...val];
        }

        this._items = newItems;
        this.updateSelected();
    }

    @Output() selectionChanged: EventEmitter<string> = new EventEmitter(); //count of selected items passed out

    changesMade: boolean = false;
    selectedCount: number = 0;
    selectionText: string = '';

    private _dropdownOpen: boolean = false;
    public get dropdownOpen(): boolean {
        return this._dropdownOpen;
    }

    public get selectedValues(): string {
        let arr = this.items?.filter(x => x.selected)?.map(x => x.id) || [];
        return arr.join(',');
    }

    constructor(injector: Injector,
        private _renderer: Renderer2,
        private _elementRef: ElementRef,
        private _change: ChangeDetectorRef
    ) {
        super(injector);
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
    }

    ngOnInit(): void {
    }

    getAnyItem(): CheckboxListItem {
        return this.items.find(x => x.id == 0);
    }

    getSelectedItems(): CheckboxListItem[] {
        return this.items.filter(x => x.selected == true);
    }

    isValueSelected(value: number): boolean {
        let item = this.items.find(x => x.id == value);
        if (this.isNullOrUndefined(item)) { return false; }
        return item.selected;
    }

    updateSelected(): void {
        let count = this.items.filter(x => x.selected).length;
        if (count == 1) {
            this.selectionText = this.items.find(x => x.selected).name;
        } else {
            this.selectionText = this.l('CheckboxListItemsSelected').replace('{0}', count.toString());
        }
        this.changesMade = true;
    }

    dropdownChange(ev: boolean): void {
        //ev is a boolean where true=opened, false=closed
        if (ev) {
            this.changesMade = false;
            this._dropdownOpen = true;
        } else {
            this._dropdownOpen = false;
            if (this.selectedValues.length == 0) {
                this.items.find(x => x.id == 0).selected = true;
                this.updateSelected();
            }
            if (this.changesMade) {
                this.changesMade = false;
                this.selectionChanged.emit(this.selectedValues);
            }
        }
    }

    selectChanged(ev: Event, itemId: number): void {
        let checked = (ev.target as any).checked;

        if (itemId == 0) {
            //user has checked the "Any" option, so deselect the rest
            setTimeout(() => {
                this.selectAnyOption(true);
            }, 10);
            return;
        }

        let project = this.items.find(x => x.id == itemId);
        project.selected = checked;

        if (checked) {
            //user has selected one or more items, so deselect the "Any" option
            this.deselectAnyOption();
        } else {
            let count = this.items.filter(x => x.selected).length;
            if (count == 0) {
                setTimeout(() => {
                    this.selectAnyOption(true);
                }, 10);
            }
        }

        this.updateSelected();
    }

    selectAnyOption(changesMade: boolean): void {
        this.items.forEach(x => x.selected = (x.id == 0));
        if (changesMade) {
            this.changesMade = true;
            this.updateSelected();
        }
    }

    deselectAnyOption(): void {
        this.items.find(x => x.id == 0).selected = false;
        this.changesMade = true;
    }

    toNumberArray(values: string): number[] {
        if (this.isNullOrUndefinedOrEmptyString(values)) { values = '0'; }
        let arr = values.split(',');
        return arr.map(x => parseInt(x, 10));
    }
}
