import { Attribute, Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[requiredNotWhitespace]', //[formControlName],[requiredNotWhitespace][formControl],[requiredNotWhitespace][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => RequiredNotWhitespaceValidator), multi: true }],
})
export class RequiredNotWhitespaceValidator implements Validator {
    constructor(
    ) { }

    validate(control: AbstractControl): { [key: string]: any } {
        const value = control.value;

        if (!value || value == '') {
            return { 'required': true };
        } else if (value.trim() == '') {
            return { 'requiredNotWhitespace': true };
        } else {
            return null;
        }
    }
}
