import { Component, Output, EventEmitter, OnInit, Input, forwardRef, ChangeDetectionStrategy, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { DateTime } from 'luxon';
import * as luxon from 'luxon';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { update } from 'lodash-es';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true
        }
    ]
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {

    @Input() id: string = '';
    @Input() name: string = '';
    @Input() disabled = false;
    @Input() readonly = false;

    // [value] is the property that will be used for model binding
    private _value: DateTime;
    @Input()
    get value(): DateTime {
        return this._value;
    }
    set value(val: DateTime) {
        this._value = this.splitValue(val);
    }

    // [datepart] is the property that the DatePicker is bound to (as this needs to be a JS Date)
    private _jsDate: Date;
    get jsdate(): Date {
        return this._jsDate;
    }
    set jsdate(val: Date) {
        this._jsDate = this.buildValue(val);
    }

    private DEBUG = false;
    inputgroupfocus: boolean;
    private dateFocusFlag: boolean = false;
    private timezoneOffset = 0;

    constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
    }

    ngOnInit(): void {
        this.timezoneOffset = luxon.DateTime.local().setZone('local').offset;
    }

    ngAfterViewInit(): void {
    }

    splitValue(val: DateTime): DateTime {
if (this.DEBUG) { console.log(`splitValue() val:`, this.quickDateTimeString(val)); }

        //this function is called when the [(ngModel)] property on this component is updated
        if (!val) {
            this._jsDate = null;
            return null;
        }

        //when we create the JS Date object from the DateTime object, JS Date will apply the timezone offset (annoyingly!)
        //so make a DateTime with the offset applied in the opposite direction first, then create the JS Date from this!
        let offsetVal = val.minus({ minutes: this.timezoneOffset });

if (this.DEBUG) { console.log(`splitValue() offsetVal:`, this.quickDateTimeString(offsetVal)); }

        this._jsDate = new Date(Date.UTC(offsetVal.year, val.month - 1, offsetVal.day, offsetVal.hour, offsetVal.minute, 0, 0));

if (this.DEBUG) { console.log(`splitValue() _jsDate:`, this.quickDateString(this._jsDate)); }

        return val;
    }

    buildValue(val: Date): Date {
if (this.DEBUG) { console.log(`buildValue() val:`, this.quickDateString(val)); }

        if (!val) {
            val = DateTime.now().toJSDate();
        }

        //let newVal = DateTime.fromJSDate(this._jsDate);
        let iso = `${val.getFullYear()}-${this.doubleDigits(val.getMonth() + 1)}-${this.doubleDigits(val.getDate())}T00:00:00.000`; //ISO 8601
        let newVal = DateTime.fromISO(iso);

if (this.DEBUG) { console.log(`buildValue() newVal:`, this.quickDateTimeString(newVal)); }
        newVal.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
if (this.DEBUG) { console.log(`buildValue() newVal:`, this.quickDateTimeString(newVal)); }

        this._value = newVal;

if (this.DEBUG) { console.log(`buildValue() _value:`, this.quickDateTimeString(this._value)); }

        this.onChange(this._value); //<-- this is vital to pushing the changes out to the model binding

        return val;
    }

    //BEGIN: ControlValueAccessor implementation
    onChange: any = () => { }
    onTouch: any = () => { }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    writeValue(value: DateTime) {
        this.value = value;
    }
    //END: ControlValueAccessor implementation

    //BEGIN: methods for ensuring the border of the whole input group remains in sync when a control is focussed
    dateFocus() {
        this.dateFocusFlag = true;
        this.update();
    }

    dateBlur() {
        this.dateFocusFlag = false;
        this.update();
    }

    update() {
        this.inputgroupfocus = this.dateFocusFlag;
    }
    //END:

    doubleDigits(value: number): string {
        return value < 10 ? `0${value}` : `${value}`;
    }

    quickDateString(dt: Date): string {
        return dt ? `${dt.getFullYear()}-${this.doubleDigits(dt.getMonth() + 1)}-${this.doubleDigits(dt.getDate())}T${this.doubleDigits(dt.getHours())}:${this.doubleDigits(dt.getMinutes())}:${this.doubleDigits(dt.getSeconds())}` : '';
    }

    quickDateTimeString(dt: DateTime): string {
        return dt ? dt.toFormat('yyyy-MM-dd HH:mm:ss ZZ') : '';
    }
}
