import { Component, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { CustomComponentBase } from '@shared/common/custom-component-base';
import { ColourHelper } from '@shared/helpers/ColourHelper';
import { initial, split } from 'lodash-es';

@Component({
  selector: 'user-monogram',
  templateUrl: './user-monogram.component.html',
  styleUrls: ['./user-monogram.component.scss']
})
export class UserMonogramComponent extends CustomComponentBase implements OnInit {

  //@Input() userId: number = 0; //might user this for click event
  @Input() userName: string = '';
  @Input() compact: boolean = false;

  monogram: string = '';
  colour: string = '';
  textColour: string = '';
  name: string = '';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.userName) {
      this.setUserMonogram();
    }
  }

  private setUserMonogram() {
    this.name = this.userName;
    if (this.isNullOrUndefinedOrEmptyString(this.name)) { this.name = '? ?'; }
    let initials = this.name.split(' ').map(n => n.charAt(0)).join('');
    this.monogram = initials;

    //we need three chars to create a fixed colour from
    if (initials.length < 3) {
      let chars = this.name.replace(' ','').split('').reverse().join('');
      if (chars.length < 3) { chars += 'QCZ'; }
      initials += chars;
    }

    if (initials.length > 3) {
      initials = initials.substring(0, 3);
    }

    let hexColour = ColourHelper.getColourFromString(initials);
    this.colour = ColourHelper.hexColourToRGBA(hexColour, 0.9);
    this.textColour = ColourHelper.getRecommendedTextColor(hexColour);
  }
}
