import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'eleco-text-search',
  templateUrl: './eleco-text-search.component.html',
  styleUrls: ['./eleco-text-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ElecoTextSearchComponent),
      multi: true
    }
  ]
})
export class ElecoTextSearchComponent implements ControlValueAccessor, OnInit {

  @ViewChild('searchTextInput') searchTextInput: ElementRef;

  @Input() id: string = '';
  @Input() name: string = '';
  @Input() class: string = '';
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() autofocus: boolean = false;
  @Input() button: boolean = false;
  @Input() placeholder: string = '';
  @Input() label: string = '';

  private _value: string = '';
  @Input() get value(): string {
    return this._value;
  }
  set value(val: string) {
    this._value = val;
    this.onChange(this.value);
  }

  @Output() search = new EventEmitter<string>();
  @Output() buttonclick = new EventEmitter();

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {
    this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
    this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    // debounce the search input textbox
    let context = this;
    
    fromEvent(this.searchTextInput.nativeElement, 'keyup')
        .pipe(
            filter(Boolean),
            debounceTime(400),
            distinctUntilChanged(),
            tap((text) => {
                context.search.emit(context.value);
            })
        )
        .subscribe();
  }

  //BEGIN: ControlValueAccessor implementation
  onChange: any = () => {}
  onTouch: any = () => {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.value = value;
  }
  //END: ControlValueAccessor implementation

  searchTextInput_SearchEvent(ev: any): void {
    if (this.searchTextInput.nativeElement.value == '') {
      //user has used the clear X button
      //this.value = this.searchTextInput.nativeElement.value;
      this.onChange(this.value); //<-- this is vital to pushing the changes out to the model binding
      //this.search.emit(this.value);
    }
  }

  searchButtonClick(ev: any): void {
    this.buttonclick.emit();
  }
}
