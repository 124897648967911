import {Component, ElementRef, EventEmitter, Inject, Injector, Input, NgZone, OnChanges, 
    OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { IGanttData } from '../interfaces/IGanttData';
import { IGanttTask } from '../interfaces/IGanttTask';
//import { IGanttLink, GanttLinkConstants } from '../interfaces/IGanttLink';
import { DateTime } from 'luxon';
//import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { ProjectTagDto } from '@shared/service-proxies/service-proxies';
import { DOCUMENT } from '@angular/common';
import 'dhtmlx-gantt';
import { CustomComponentBase } from 'shared/common/custom-component-base';
import { ProjectTagSelectDropdownComponent } from '../projecttag-select-dropdown/projecttag-select-dropdown.component';
//import { result } from 'lodash-es';
import { DynamicCssClassHelper } from '../dynamic-css-class-helper/dynamic-css-class.helper';
import { ProjectMasterPlanHelpers } from '@app/main/projectPlanning/projects/project-masterplan.helpers';
import { TaskStatus } from '@shared/PullPlanningEnums';
//import { Solver, SolverResult, Color, ColorStyler } from '../colorHelper/color.helper';

declare let gantt: any;

@Component({
    selector: 'app-gantt',
    templateUrl: './gantt.component.html',
    styleUrls: ['./gantt.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GanttComponent extends CustomComponentBase implements OnInit, OnChanges, OnDestroy {

    @Input() public height: number | undefined;
    @Input() public data: IGanttData;
    @Input() public readonly: boolean = false;
    @Input() public selectMode: boolean = false;
    @Input() public labels: any = { select: 'Select', add: 'Add', edit: 'Edit', name: 'Name', start: 'Start', finish: 'Finish', progress: 'Progress', now: 'now', location: 'Location' };
    @Input() public projectTags: ProjectTagDto[] = [];
    @Input() public projectId: number = 0;
    @Input() public allowTaskClick: boolean = false;
    @Input() public tasksReadonly: boolean = false;

    @Output() editBtnClick = new EventEmitter<{ id: number, action: string }>();
    @Output() newLinkAdded = new EventEmitter<{ sourceId: number, targetId: number, type: number, id: number }>();
    @Output() activityMoved = new EventEmitter<IGanttTask>();
    @Output() activityClicked = new EventEmitter<IGanttTask>();
    @Output() taskSelection = new EventEmitter<IGanttTask[]>();

    @ViewChild('ganttcontrol', { static: true }) ganttcontrolref: ElementRef;
    @ViewChild('projectTagSelectControl', { static: true }) projectTagSelectControl: ProjectTagSelectDropdownComponent;

    private scrollPos: any = { x: 0, y: 0 };
    private scrollPosFlag: boolean = false;
    private scrollPosTimer: any;

    private events: string[] = [];
    private dayScale = {
        minColumnWidth: 30, scaleHeight: 90, scales: [
            { unit: 'month', step: 1, format: '%F, %Y' },
            { unit: 'day', step: 1, format: this.dayNumScaleTemplate, css: this.daysStyle },
            { unit: 'day', step: 1, format: this.dayNameScaleTemplate, css: this.daysStyle }
        ]
    };
    private weekScale = {
        minColumnWidth: 40, scaleHeight: 90, scales: [
            { unit: 'year', step: 1, format: '%Y' },
            { unit: 'month', step: 1, format: '%F' },
            { unit: 'week', step: 1, format: this.weekScaleTemplate },
        ]
    };
    private monthScale = {
        minColumnWidth: 100, scaleHeight: 60, scales: [
            { unit: 'year', step: 1, format: '%Y' },
            { unit: 'month', step: 1, format: '%F' },
        ]
    };
    ganttScales = [this.dayScale, this.weekScale, this.monthScale];
    selectedScaleIndex: number = 0;

    private selectColumn: any = {
        header: this.getLabel(this.labels, 'select'),
        template: function (task: any) {
            return (`<input type="checkbox" id="chkTask_${task.id}" 
        class="gantt-checkbox ${task.isInActivePullPlan ? 'disabled' : ''}" 
        onclick="window.ganttproxy_editBtnClick(${task.id}, 'select', this)" 
        ${task.selected ? 'checked' : ''} ${task.isInActivePullPlan ? 'disabled' : ''} />`);
        },
        width: 50
    };

    private addColumn: any = {
        header: `<div class="gantt_grid_head_cell" role="button" onclick="window.ganttproxy_editBtnClick(null, 'add')"><i class="ds-icon-default-add custom-gantt-icon"></i></div>`,
        template: function (task: any) {
            return (`<i class="ds-icon-default-add custom-gantt-icon" style="${task.type != 'project' ? 'visibility: hidden;' : ''}" role="button" onclick="window.ganttproxy_editBtnClick(${task.id}, 'add')"></i>`);
        },
        width: 42
    };

    private editColumn: any = {
        header: this.getLabel(this.labels, 'edit'),
        //header: `<div class="gantt_grid_head_cell">Edit</div>`,
        template: function (task: any) {
            return (`<i class="ds-icon-default-pen custom-gantt-icon" role="button" onclick="window.ganttproxy_editBtnClick(${task.id}, 'edit')"></i>
				<i class="ds-icon-default-trash custom-gantt-icon" role="button" onclick="window.ganttproxy_editBtnClick(${task.id}, 'delete')"></i>`);
        },
        width: 80
    };

    private expandCollapseButtonsHTML: string = `<div class="expand-collapse-buttons">
            <div onclick="window.ganttproxy_collapseAllTasksBtnClick()" title="${this.l('CollapseAll')}"><i class="far fa-minus-square"></i></div>
            <br />
            <div onclick="window.ganttproxy_expandAllTasksBtnClick()" title="${this.l('ExpandAll')}"><i class="far fa-plus-square"></i></div>
        </div>`;

    filtersActive: boolean = false;
    ganttHeight: string = '100%';
    fromDateFilter: DateTime;
    toDateFilter: DateTime;
    private nowMarkerId: string = '';
    private document: Document;

    constructor(
        injector: Injector, 
        @Inject(DOCUMENT) document: Document,
        private dynamicCssClassHelper: DynamicCssClassHelper
    ) {
        super(injector);
        this.document = document;
    }

    ngOnInit(): void {
        let context = this;

        //make our component ganttproxy_editBtnClick function available from the window object,
        //so that the Gantt component templates for the edit buttons will work
        (<any>window).ganttproxy_editBtnClick = this.ganttproxy_editBtnClick.bind(this);
        (<any>window).ganttproxy_collapseAllTasksBtnClick = this.ganttproxy_collapseAllBtnClick.bind(this);
        (<any>window).ganttproxy_expandAllTasksBtnClick = this.ganttproxy_expandAllBtnClick.bind(this);

        //***************************/
        //set up the gantt chart
        gantt.plugins({
            marker: true,       //enable markers
            drag_timeline: true //make the grid draggable for easier scrolling
        });

        gantt.config.drag_links = !this.readonly;
        gantt.config.link_line_width = 2; //this is the default width
        gantt.config.drag_progress = false; //progress slider
        gantt.config.server_utc = true;

        //***************************/
        //set up the gantt chart rulers
        gantt.config.min_column_width = this.ganttScales[0].minColumnWidth;
        gantt.config.scale_height = this.ganttScales[0].scaleHeight;
        gantt.config.scales = this.ganttScales[0].scales;

        //***************************/
        //set up the excel style grid
        gantt.config.open_split_tasks = true;
        gantt.config.grid_width = 540;
        gantt.config.columns = [
            { width: 70, label: this.expandCollapseButtonsHTML, tree: true },
            { name: 'text', label: this.getLabel(this.labels, 'name'),
                template: (obj: any) => { return this.getGridTextTemplate(obj); }, width: '*', min_width: 90, max_width: 300, resize: true },
            { name: 'start_date', label: this.getLabel(this.labels, 'start'), 
                template: (obj: any) => { return this.localiseDate(obj.start_date); }, width: 76, min_width: 76, align: 'center' },
            { name: 'end_date', label: this.getLabel(this.labels, 'finish'), 
                template: (obj: any) => { return this.localiseDate(obj.end_date); }, width: 76, min_width: 76, align: 'center' },
            //{ name: 'duration', label: 'Duration', align: 'center' },
            { name: 'location', label: this.getLabel(this.labels, 'location'), 
                template: (obj: any) => { return this.getGridLocationTemplate(obj); }, width: 90, min_width: 90, max_width: 150, resize: true },
            { name: 'progress', label: this.getLabel(this.labels, 'progress'), 
                template: (obj: any) => { return this.progressPercent(obj.progress); }, width: 65, min_width: 65, align: 'center' },
        ];

        if (!this.readonly) {
            gantt.config.columns.splice(1, 0,
                { name: 'addCol', label: this.addColumn.header, width: this.addColumn.width, template: this.addColumn.template, align: 'center' }
            );
            gantt.config.columns.push(
                { name: 'editCol', label: this.editColumn.header, width: this.editColumn.width, template: this.editColumn.template, align: 'center' }
            );
        }

        if (this.selectMode) {
            gantt.config.columns.splice(1, 0,
                { name: 'selectCol', label: this.selectColumn.header, width: this.selectColumn.width, template: this.selectColumn.template }
            );
        }

        //cell styles for dates outside of the filter dates
        gantt.templates.timeline_cell_class = function (task: any, date: Date): string {
            let cssclass = '';
            if (context.filtersActive) {
                let dt = DateTime.fromJSDate(date).toUTC();

                if (dt.hour != 0 || dt.minute != 0) {
                    //gantt control offsets to utc - push the time back to midnight on the correct date
                    let totalminutes = (dt.hour * 60) + dt.minute;
                    if (totalminutes >= (12 * 60)) {
                        //round up to midnight
                        dt = dt.plus({ minutes: (24 * 60) - totalminutes });
                    } else {
                        //round down to midnight
                        dt = dt.minus({ minutes: totalminutes });
                    }
                }

                let from = context.setMidnight(context.fromDateFilter);
                if (from && dt < from) { cssclass = 'greyed-out'; }

                let to = context.setMidnight(context.toDateFilter);
                if (to && dt > to) { cssclass = 'greyed-out'; }
                
                //console.log(`timeline_cell_class(date: ${date}, dt: ${dt.toFormat('dd/MM/yyyy HH:mm')}) from: ${from}, to: ${to}, cssclass: ${cssclass}`);
            }
            return cssclass;
        };

        // keeps the width of the grid area when column is resized
        gantt.config.keep_grid_width = false;

        gantt.config.grid_resize = true; //deprecated - see below
        if (gantt.config.layout.rows.length >= 1 && gantt.config.layout.rows[0].cols.length >= 2) {
            gantt.config.layout.rows[0].cols[1].resizer = true; //allow resizing of the grid area
            gantt.config.layout.rows[0].cols[1].width = 3;
        }

        gantt.templates.grid_open = function (item) {
            if (item.$open) {
                return '<span class="gantt_close"><i class="pi pi-angle-down" style="font-size: 1.5em;padding: 0.4em;"></i></span>';
            } else {
                return '<span class="gantt_open"><i class="pi pi-angle-right" style="font-size: 1.5em;padding: 0.4em;"></i></span>';
            }
        };

        gantt.templates.task_text = function (start, end, task: IGanttTask) {
            let content = '';
            if (task.subtype == 'split-task-parent' || task.subtype == 'whiteboard-task') {
                content = '<div class="task-corner"></div>';
                if (task.subtype == 'whiteboard-task') {
                    let taskStatus = parseInt(task.key, 10);
                    if (taskStatus == TaskStatus.AtRiskOrBlocked) {
                        content += '<div class="task-at-risk"><i class="ds-icon-default-warning"></i></div>';
                    } else if (taskStatus == TaskStatus.Complete) {
                        content += '<div class="task-complete"></div>';
                    }
                }
            } else {
                let icons = '';
                if (task.isInActivePullPlan) {
                    icons += '<i class="task-icon ds-icon-default-window-data"></i>';
                }
                if (context.arrayHasItems(task.projectTags)) {
                    icons += '<i class="task-icon ds-icon-default-tag"></i>';
                }
                content = `<div>${icons}${task.text}</div>`;
            }
            return content;
        };

        gantt.templates.task_class = function (start, end, task: IGanttTask) {
            let barClass = context.getTaskBarStyleClass(task);
            return barClass;
        };

        //add a 'now' marker
        gantt.config.show_markers = true;
        context.nowMarkerId = gantt.addMarker({
            start_date: DateTime.now().toJSDate(),
            css: 'today',
            text: this.getLabel(this.labels, 'now'),
            title: DateTime.now().toFormat('dd LLL, yyyy')
        });

        //setup dragging of tasks
        gantt.config.round_dnd_dates = false; // stops it snapping to days
        gantt.config.time_step = 120; // 2h resolution
        //gantt.config.drag_project = true; // this doesn't work too well as the width contracts when dragging, then snaps back on drop

        // gantt.ext.inlineEditors.attachEvent("onEditEnd", (state) => {
        // });

        context.events.push(
            //for a complete list of gantt events: https://docs.dhtmlx.com/gantt/api__refs__gantt_events.html

            //disable dragging of tasks, but allow progress change
            gantt.attachEvent('onBeforeTaskDrag', (id: string, mode: any, e: any) => {
                return !context.readonly || mode != 'move'; // only allow in edit mode
                //return (mode == 'progress');
            }),

            // gantt.attachEvent('onTaskDrag', (id: string, mode: any, task: IGanttTask, original: IGanttTask, e: any) => {
            //   console.log('onTaskDrag task:', task);
            //   console.log('onTaskDrag original:', original);
            // }),

            gantt.attachEvent('onAfterTaskDrag', (id: string, mode: string, e: Event) => {
                //if (mode != 'move') { return; }
                context.activityMoved.emit(context.getTask(id));
            }),

            gantt.attachEvent('onTaskClick', (id: string, e: any) => {
                //rather crappily, the TaskClick event fires for clicking on a Task ROW as well as a Task itself!
                //and, event propagations from task row buttons fall through to this also....
                //filter to just clickin on a Gantt Task or its child contents
                //console.log(`onTaskClick(id: ${id}) e:`, e);
                if (e.target.className == 'gantt_task_content' || !this.isNullOrUndefined(e.target.closest('.gantt_task_content'))) {
                    context.activityClicked.emit(context.getTask(id));
                    return false;
                } else if (context.elementHasPredecessor(e.target, 'div.gantt_grid_data')) {
                    return true; //allow default behaviour e.g. open/close summaries
                }
            }),

            gantt.attachEvent('onTaskDblClick', (id: string, e: any) => {
                //trap this event to suppress the default inline editor
                return false;
                //   let task = gantt.getTask(id);
                //   if (task.type == 'project' || task.type == 'milestone') { return; }
                //   alert(`task: ${task.id}, ${task.text}, ${task.type}`);
            }),

            gantt.attachEvent('onLinkDblClick', (id: string, e: any) => {
                //trap this event to suppress the default inline editor
                //console.log(`onLinkDblClick() id: ${id}`);
                return false;
            }),

            // gantt.attachEvent('onTaskClick', (id: string) => {
            //     gantt.message(`onTaskClick: Task ID: ${id}`);
            //     return true;
            // }, '')

            gantt.attachEvent('onGanttRender', () => {
                // use this event to trigger restoring the scroll position
                // Note that this event actually fires 2-3 times!
                if (context.scrollPosFlag == true) {
                    context.scrollPosFlag = false;
                    this.restoreScrollPos();
                }
            }),

            gantt.attachEvent('onAfterLinkAdd', (id: string | number, item: any) => {
                context.newLinkAdded.emit({
                    sourceId: parseInt(item.source, 10),
                    targetId: parseInt(item.target, 10),
                    type: parseInt(item.type, 10),
                    id: item.id
                })
            }),

            // gantt.attachEvent('onAfterLinkUpdate', (id: string | number, item: any) => {
            //   console.log(`onAfterLinkUpdate() id: ${id}, item:`);  
            //   console.log(item);
            // }),
            // gantt.attachEvent('onBeforeLinkAdd', (id: string | number, item: any) => {
            //   console.log(`onBeforeLinkAdd() id: ${id}, item:`);  
            //   console.log(item);
            // }),
            // gantt.attachEvent('onBeforeLinkUpdate', (id: string | number, item: any) => {
            //   console.log(`onBeforeLinkUpdate() id: ${id}, item:`);  
            //   console.log(item);
            // })
            // gantt.attachEvent('onDataRender', () => {
            //   console.log(`onDataRender()`);
            // }),
            // gantt.attachEvent('onGanttLayoutReady', () => {
            //   console.log(`onGanttLayoutReady()`);
            // }),
            // gantt.attachEvent('onGanttReady', () => {
            //   console.log(`onGanttReady()`);
            // }),
        );

        this.ganttHeight = this.calcGanttHeight();

        //finally, initialise the gantt control
        if (this.data && this.data.start && this.data.finish) {
            gantt.config.start_date = this.data.start;
            gantt.config.end_date = this.data.finish;
        }
        gantt.init('gantt1');
    }

    ngOnDestroy(): void {
        gantt.clearAll();

        // detach all saved events
        while (this.events.length) {
            gantt.detachEvent(this.events.pop());
        }

        const tooltips = <HTMLElement[]><any>document.querySelectorAll('.gantt-info');
        tooltips.forEach(tooltip => tooltip.style.display = 'none');
    }

    ngOnChanges(changes: SimpleChanges) {
        let requireInit = false;

        // height property
        if (changes.height) {
            this.ganttHeight = this.calcGanttHeight();
        }

        // data property
        if (changes.data) {
            //note that this won't detect changes within the data, just changes to the assignment of the data property
            //note that parse() doesn't clear any existing data from the chart! see: http://disq.us/p/2bc9cmb
            gantt.clearAll();
            let newdata = changes.data.currentValue;
            if (!this.isNullOrUndefined(newdata)) {
                this.getScrollPos();
                this.createBarStyleClasses(newdata.tasks);
                this.calcGanttDateRange();
                gantt.parse({ data: newdata.tasks, links: newdata.links, start: this.data.start, finish: this.data.finish });
                requireInit = true;
            }
        }

        // readonly property
        if (changes.readonly) {
            if (changes.readonly.currentValue) {
                //hide the add/edit columns
                if (gantt.config.columns.length > 0) {
                    this.removeColumn('addCol');
                    this.removeColumn('editCol');
                    gantt.config.grid_width = 450;
                }
            } else {
                //show the add/edit column
                if (!this.readonly) {
                    gantt.config.columns.splice(1, 0,
                        { name: 'addCol', label: this.addColumn.header, width: this.addColumn.width, template: this.addColumn.template, align: 'center' }
                    );
                    gantt.config.columns.push(
                        { name: 'editCol', label: this.editColumn.header, width: this.editColumn.width, template: this.editColumn.template, align: 'center' }
                    );
                    gantt.config.grid_width = 550;
                }
            }
            gantt.config.drag_links = !changes.readonly.currentValue;
            requireInit = true;
        }

        //selectMode property
        if (changes.selectMode) {
            if (changes.selectMode.currentValue) {
                //show the select column
                gantt.config.columns.splice(1, 0,
                    { name: 'selectCol', label: this.selectColumn.header, width: this.selectColumn.width, template: this.selectColumn.template }
                );
                gantt.config.grid_width = 500;
            } else {
                //hide the select column
                this.removeColumn('selectCol');
                gantt.config.grid_width = 450;
            }
            this.deselectAllTasks();
            requireInit = true;
        }

        //labels property
        if (changes.labels) {
            for (let i = 0; i < gantt.config.columns.length; i++) {
                switch (gantt.config.columns[i].name.toLowerCase()) {
                    case 'text': gantt.config.columns[i].label = this.getLabel(changes.labels.currentValue, 'name'); return;
                    case 'start_date': gantt.config.columns[i].label = this.getLabel(changes.labels.currentValue, 'start'); return;
                    case 'end_date': gantt.config.columns[i].label = this.getLabel(changes.labels.currentValue, 'finish'); return;
                    case 'location': gantt.config.columns[i].label = this.getLabel(changes.labels.currentValue, 'location'); return;
                    case 'progress': gantt.config.columns[i].label = this.getLabel(changes.labels.currentValue, 'progress'); return;
                    //case 'addCol': gantt.config.columns[i].label = this.getLabel2(changes.labels.currentValue, 'Name'); return;
                    case 'editcol': gantt.config.columns[i].label = this.getLabel(changes.labels.currentValue, 'edit'); return;
                }
            }
            requireInit = true;
        }

        if (changes.tasksReadonly) {
            this.ganttHeight = this.calcGanttHeight();
        }

        if (requireInit) {
            this.reinitialize();
        }
    }

    //#region PUBLIC METHODS

    public ganttproxy_collapseAllBtnClick(): void {
        this.collapseAllTasks();
    }

    public ganttproxy_expandAllBtnClick(): void {
        this.expandAllTasks();
    }

    public ganttproxy_editBtnClick(id: number, action: string, ele?: any): void {
        if (action == 'select') {
            let task = this.getTask(id.toString());
            task.selected = ele.checked;

            //if the activity has children then de/select all its children too
            this.setChildTasksSelection(task, ele.checked);

            let selectedTasks = this.data.tasks.filter(t => t.selected);
            this.taskSelection.emit(selectedTasks);
        } else {
            this.editBtnClick.emit({ id, action });
        }
    }

    private setChildTasksSelection(task: IGanttTask, selected: boolean): void {
        this.data.tasks.forEach(t => {
            if (t.parent == task.id) {
                if (!selected || (selected && !t.isInActivePullPlan)) {
                    t.selected = selected;
                }

                //find the checkbox html element and ensure the checked state matches that of the task
                var taskCheckbox = this.document.getElementById(`chkTask_${t.id}`);
                if (!this.isNullOrUndefined(taskCheckbox)) {
                    (<HTMLInputElement>taskCheckbox).checked = t.selected;
                }

                this.setChildTasksSelection(t, t.selected);
            }
        });
    }

    // private findChildElements(parent: any, id: string, className: string): Array<any> {
    //   let results: any[] = [];
    //   if (this.isNullOrUndefined(parent) || this.isNullOrUndefined(parent.children) 
    //     || parent.children.length == 0) { return results; }

    //     console.log('got children!');

    //   parent.children.array.forEach(element => {
    //     if ((id != '' && element.id == id) || (className != '' && this.containsClass(element.class, className))) {
    //       results.push(element);
    //     } 

    //     if (element.child.length > 0) {
    //       var eles = this.findChildElements(element, id, className);
    //       eles.forEach(ele => { results.push(ele); });
    //     }
    //   });
    //   return results;
    // }

    // private containsClass(classes: string, className: string): boolean {
    //   let arr = classes.split(' ');
    //   if (arr.length == 0) { return false; }
    //   for(let i = 0; i < arr.length; i++) {
    //     if (arr[i] == className) { return true ;}
    //   }
    //   return false;
    // }

    public refresh() {
        if (this.data) {
            this.calcGanttDateRange();
            this.getScrollPos();
            gantt.parse({ data: this.data.tasks, links: this.data.links, start: this.data.start, finish: this.data.finish });
        }
    }
    
    private calcGanttDateRange(): any {
        //check that the gantt date range covers all of the tasks
        let minDate: Date = undefined;
        let maxDate: Date = undefined;
        this.data.tasks.forEach(task => {
            if (!this.isNullOrUndefined(task.start_date) && (this.isNullOrUndefined(minDate) || task.start_date.valueOf() < minDate.valueOf())) {
                minDate = task.start_date;
            }
            if (!this.isNullOrUndefined(task.end_date) && (this.isNullOrUndefined(maxDate) || task.end_date.valueOf() > maxDate.valueOf())) {
                maxDate = task.end_date;
            }
        });

        let minDateTime = DateTime.fromJSDate(minDate).minus({ days: 3 });
        let maxDateTime = DateTime.fromJSDate(maxDate).plus({ days: 3 });

        if (this.isNullOrUndefined(this.data.start) || this.data.start.valueOf() > minDateTime.valueOf()) {
            this.data.start = minDateTime.toJSDate();
        }
        if (this.isNullOrUndefined(this.data.finish) || this.data.finish.valueOf() < maxDateTime.valueOf()) {
            this.data.finish = maxDateTime.toJSDate();
        }
    }

    public deleteTask(taskId: string): void {
        if (this.isNullOrUndefined(gantt.getTask(taskId))) { return; }
        gantt.deleteTask(taskId);
    }

    public deleteLink(linkId: string): void {
        //console.log(`deleteLink(linkId: ${linkId}) called`);
        if (this.isNullOrUndefined(gantt.getLink(linkId))) { 
        //console.log(`getLink(linkId: ${linkId}) returned null!`);
            return;
        }
        gantt.deleteLink(linkId);
    }

    public getSelectedItems(): IGanttTask[] {
        return this.data.tasks.filter(t => t.selected);
    }

    public scaleChanged(): void {
        this.setGanttScale(this.selectedScaleIndex);
    }

    public calcGanttHeight(): string {
        let offset = 60;
        if (this.tasksReadonly) { offset += 20; }

        let h = '';
        if (this.height && this.height > 100) {
            h = `${this.height - offset}px`;
        } else {
            h = `calc(100% - ${offset}px)`;
        }

        return h;
    }

    public filterValueChanged(ctrl: string, ev: any): void {
        //the value has changed, but although the new value is available through "ev",
        //it has not updated the model parameter yet, so this event is pre, not post.

        //setTimeout(() => this.filterData(ctrl, ev), 10);
        Promise.resolve(null).then(() => this.filterData(ctrl, ev));
    }

    public clearFilters(): void {
        this.fromDateFilter = null;
        this.toDateFilter = null;
        if (this.data && this.data.start && this.data.finish) {
            gantt.config.start_date = this.data.start;
            gantt.config.end_date = this.data.finish;
        }
        this.projectTagSelectControl.clearSelections();
        //this.projectTags.forEach(tag => tag.selected = false);
        gantt.init('gantt1');
        gantt.clearAll();
        this.refresh();
    }

    public set6WeekFilter(): void {
        let now = DateTime.now(); //.set({ hour: 0, minute: 0, second: 0, millisecond: 0});
        let nextMonday = now.plus({ days: 8 - now.weekday }).set({ hour: 1, minute: 0, second: 0, millisecond: 0 });
        let sixWeeksSunday = nextMonday.plus({ weeks: 6 }).minus({ days: 1 }).set({ hour: 1, minute: 0, second: 0, millisecond: 0 });
        this.fromDateFilter = nextMonday;
        this.toDateFilter = sixWeeksSunday;
        this.filterData(null, null);
    }

    public refreshTask(id: string): void {
        gantt.refreshTask(id);
    }

    //#endregion

    //#region PRIVATE METHODS

    private getGridTextTemplate(obj: any): string {
        let html = `<span class="gantt-data-text text-ellipsis d-block" title="${obj.text}">`;
        if (obj.subtype == 'split-task-parent' && obj.text == obj.swimlaneTeamTitle) {
            html += '<span class="gantt-text-swimlane-icon"><i class="ds-icon-default-puzzle"></i></span>';
        } else if (obj.subtype == 'whiteboard-task') {
            html += '<span class="gantt-text-whiteboard-task-icon"><i class="ds-icon-default-file"></i></span>';
        }
        html += `<span>${obj.text}</span>`;
        html += '</span>';
        return html;
    }

    private getGridLocationTemplate(obj: any): string {
        return `<span class="text-ellipsis d-block" title="${obj.location}">${obj.location}</span>`;
    }

    private elementHasPredecessor(target: HTMLElement, selector: string): boolean {
        //walk up the element ancestors to find the specified selector (nodetype.class)
        let ele = target;
        do {
            if (this.isSelectorMatch(ele, selector)) { return true; }
            ele = ele.parentElement;
        } while (ele);
        return false;
    }

    private isSelectorMatch(ele: HTMLElement, selector: string): boolean {
        let parts = selector.toLowerCase().split('.');
        if (parts.length < 2) { return false; }
        if (ele.nodeName.toLowerCase() == parts[0]
            && ele.className.toLowerCase().indexOf(parts[1]) > -1) {
            return true;
        }
        return false;
    }
    
    private createBarStyleClasses(tasks: IGanttTask[]): void {
        if (!this.arrayHasItems(tasks)) { return; }

        tasks.forEach(task => {
            if (task.subtype == ProjectMasterPlanHelpers.SUBTYPE_GANTT_ACTIVITY && this.arrayHasItems(task.projectTags)) {
                let tags = task.projectTags.filter(tag => 
                    !this.isNullOrUndefinedOrEmptyString(tag.tagBarColour)
                    || !this.isNullOrUndefinedOrEmptyString(tag.tagBarTextColour)
                    || (!this.isNullOrUndefinedOrNaN(tag.tagBarPatternId) && tag.tagBarPatternId != 0));

                if (this.arrayHasItems(tags)) {
                    tags.forEach(tag => {
                        let selector = `.gantt-bar-project-tag-${tag.id}`;
                        //if (!this.dynamicCssClassHelper.isStyleDeclared(selector)) {
                            //task bar
                            let css = `${selector} { `;
                            css += `border: solid 1px #999999;`;
                            // if (!this.isNullOrUndefinedOrEmptyString(tag.tagBarTextColour)) {
                            //     css += `border: solid 1px ${tag.tagBarTextColour};`;
                            // }
                            css += ' } ';

                            //task content
                            css += `${selector} .gantt_task_content { `
                            // if (!this.isNullOrUndefinedOrEmptyString(tag.tagBarTextColour)) {
                            //     css += `color: ${tag.tagBarTextColour} !important;`;
                            // }
                            if (!this.isNullOrUndefinedOrEmptyString(tag.tagBarColour)) {
                                // tagBarPatternId = 0 should be Bar Background Colour
                                if (tag.tagBarPatternId == 0) {
                                    css += `background-color: ${tag.tagBarColour} !important;`;

                                } else if (tag.tagBarPatternId == 41) {
                                    //however, 41 is transparent, so we don't want to set a background colour
                                    css += `background-color: transparent !important;`;

                                } else {
                                    // tagBarPatternId = 8 should be Bar Fore Colour
                                    // all others are pattern mix of Fore and Background colours
                                    css += `background-color: ${tag.tagBarTextColour} !important;`;
                                }
                            }
                            if (!this.isNullOrUndefinedOrNaN(tag.tagBarPatternId)) {
                                //DEV NOTE: unfortunately, there is no filter css we can use to re-colour the background image!
                                // we can only have mix of fixed colour and transparency pixels
                                if (tag.tagBarPatternId != 0 && tag.tagBarPatternId != 8 && tag.tagBarPatternId != 41) {
                                    css += `background-image: url('/assets/common/images/BarPatterns/BarPattern_${tag.tagBarPatternId}.png');`;
                                }

                                //let color = Color.hexToColor(tag.tagBarTextColour);
                                //let solverResult = new Solver(color).solve();
                                //css += 'filter: ' + solverResult.filter;
                            }
                            css += ' } ';

                            // //task icon
                            // // css += `${selector} .gantt_task_content .task-icon { `
                            // // if (!this.isNullOrUndefinedOrEmptyString(tag.tagBarTextColour)) {
                            // //     css += `color: ${tag.tagBarTextColour};`;
                            // // }
                            // // css += ' } ';

                            //this.dynamicCssClassHelper.applyStyleClass(css);
                            this.dynamicCssClassHelper.applyStyleClass(selector, css);
                        //}
                    });
                }
            }
        });
    }

    private getTaskBarStyleClass(task: IGanttTask): string {
        let cssClass = '';

        // check if this is a child task representing a whiteboard task under a parent "split task"
        switch (task.subtype) {
            case ProjectMasterPlanHelpers.SUBTYPE_WHITEBOARD_TASK:
                cssClass = 'gantt-bar-whiteboard-task';
                if (this.allowTaskClick) { cssClass += ' task-clickable'; }
                break;
            case ProjectMasterPlanHelpers.SUBTYPE_GANTT_ACTIVITY:
                cssClass = 'gantt-activity';
                break;
            case ProjectMasterPlanHelpers.SUBTYPE_SPLIT_TASK_PARENT:
                cssClass = 'gantt-split-parent';
                break;
        }

        //check for swimlane class
        if (!this.isNullOrUndefinedOrNaN(task.projectTeamId) && !this.isNullOrUndefinedOrEmptyString(task.swimlaneTeamColour)) {
            let selector = `.swimlane-${task.projectTeamId} .gantt_task_content`;
            let css = `${selector} { background-color: ${task.swimlaneTeamColour} !important; }`

            //we should still overwrite the style even if it already exists as the user may have changed
            // the team colour in the UI, and since this is a SPA the style wouldn't be re-applied until
            // the browser page is refreshed!
            this.dynamicCssClassHelper.applyStyleClass(selector, css);
            // if (!this.dynamicCssClassHelper.isStyleDeclared(selector)) {
            //     let css = `${selector} { background-color: ${task.swimlaneTeamColour} !important; }`
            //     this.dynamicCssClassHelper.applyStyleClass(css);
            // }

            cssClass += ' ' + selector.substring(1);
        //} else {
            //console.log(`Task [${task.taskId}] "${task.text}" - no projectTeamId or swimlaneTeamColour`);
        }

        if (this.arrayHasItems(task.projectTags)) {
            let tags = task.projectTags.filter(tag => 
                !this.isNullOrUndefinedOrEmptyString(tag.tagBarColour)
                || !this.isNullOrUndefinedOrEmptyString(tag.tagBarTextColour)
                || (!this.isNullOrUndefinedOrNaN(tag.tagBarPatternId) && tag.tagBarPatternId != 0));

            if (this.arrayHasItems(tags)) {
                //TODO: if there are multiple tags (with bar styles) we don't have a rule
                // for choosing which tag gets precedence. In PP, this depends on many factors,
                // such as the current view and the sorting defined within.
                // For now, just pick the tag with the lowest number
                tags = tags.sort((a, b) => a.tagBarPatternId - b.tagBarPatternId);
                cssClass += ` gantt-bar-project-tag-${tags[0].id}`;
            }
        }

        return cssClass.trim();
    }

    private reinitialize(): void {
        this.getScrollPos();
        if (this.data && this.data.start && this.data.finish) {
            gantt.config.start_date = this.data.start;
            gantt.config.end_date = this.data.finish;
        }
        gantt.init('gantt1');
        this.restoreScrollPos();
    }

    private weekScaleTemplate(dt: Date) {
        // var dateToStr = gantt.date.date_to_str('%d %M');
        // var endDate = gantt.date.add(gantt.date.add(dt, 1, 'week'), -1, 'day');
        // return dateToStr(dt) + ' - ' + dateToStr(endDate);
        var d = DateTime.fromJSDate(dt);
        return `Wk ${d.weekNumber}`;
    }

    private dayNumScaleTemplate(dt: Date) {
        var dateToStr = gantt.date.date_to_str('%d');
        return parseInt(dateToStr(dt), 10).toString();
    }

    private dayNameScaleTemplate(dt: Date) {
        var dateToStr = gantt.date.date_to_str('%D');
        return dateToStr(dt).substr(0, 1);
    }

    private daysStyle(dt: Date) {
        // you can use gantt.isWorkTime(date)
        // when gantt.config.work_time config is enabled
        // In this sample it's not so we just check week days
        if (dt.getDay() === 0 || dt.getDay() === 6) { return 'weekend'; }
        return 'weekday';
    }

    private localiseDate(dt: Date): string {
        return dt.toLocaleDateString();
    }

    private progressPercent(pc: number): string {
        if (pc <= 0) { return '-'; }
        pc = this.roundToPlaces(pc, 2);
        return `${pc.toFixed(2)}%`;
    }

    private setGanttScale(index: number): void {
        if (index < 0 || index >= this.ganttScales.length) { return; }
        gantt.config.scale_height = this.ganttScales[index].scaleHeight;
        gantt.config.min_column_width = this.ganttScales[index].minColumnWidth;
        gantt.config.scales = this.ganttScales[index].scales;
        this.reinitialize();
    }

    private getScrollPos(): void {
        if (!this.isNullOrUndefined(this.scrollPosTimer)) { return; }
        //lookup up the gantt chart scroll settings and store in scrollPos
        let scrollX = this.ganttcontrolref.nativeElement.querySelectorAll('.gantt_hor_scroll');
        let scrollY = this.ganttcontrolref.nativeElement.querySelectorAll('.gantt_ver_scroll');
        if (scrollX && scrollX.length == 1) { this.scrollPos.x = scrollX[0].scrollLeft; }
        if (scrollY && scrollY.length == 1) { this.scrollPos.y = scrollY[0].scrollTop; }
        this.scrollPosFlag = true;
    }

    private restoreScrollPos(flag?: boolean): void {
        //throttle calls to this method
        if (this.isNullOrUndefined(flag)) {
            if (!this.isNullOrUndefined(this.scrollPosTimer)) { 
                clearTimeout(this.scrollPosTimer);
            }
            this.scrollPosTimer = setTimeout(() => this.restoreScrollPos(true), 10);
            return;
        }

        this.scrollPosTimer = null;

        //restore the gantt chart scroll settings from scrollPos
        let scrollX = this.ganttcontrolref.nativeElement.querySelectorAll('.gantt_hor_scroll');
        let scrollY = this.ganttcontrolref.nativeElement.querySelectorAll('.gantt_ver_scroll');
        if (scrollX && scrollX.length == 1) { scrollX[0].scrollLeft = this.scrollPos.x; }
        if (scrollY && scrollY.length == 1) { scrollY[0].scrollTop = this.scrollPos.y; }
        this.scrollPosFlag = false;
    }

    // private getLabel(name: string): string {
    //     if (this.labels.hasOwnProperty(name)) {
    //         return this.labels[name];
    //     } else if (this.labels.hasOwnProperty(name.toLowerCase())) {
    //         return this.labels[name.toLowerCase()];
    //     } else {
    //         return name;
    //     }
    // }

    private getLabel(labels: any, name: string): string {
        if (labels.hasOwnProperty(name)) {
            return labels[name];
        } else if (labels.hasOwnProperty(name.toLowerCase())) {
            return labels[name.toLowerCase()];
        } else {
            return name;
        }
    }

    private getTask(id: string) {
        for (let i = 0; i < this.data.tasks.length; i++) {
            if (this.data.tasks[i].id == id) {
                return this.data.tasks[i];
            }
        }
        return null;
    }

    private removeColumn(name: string): void {
        let idx = -1;
        for (let i = 0; i < gantt.config.columns.length; i++) {
            if (gantt.config.columns[i].name == name) {
                idx = i;
                i = gantt.config.columns.length;
            }
        }
        if (idx > -1) {
            gantt.config.columns.splice(idx, 1);
        }
    }

    private selectAllTasks(): void {
        if (this.data && this.data.tasks && this.data.tasks.length > 0) {
            this.data.tasks.forEach(t => t.selected = true);
        }
    }

    private deselectAllTasks(): void {
        if (this.data && this.data.tasks && this.data.tasks.length > 0) {
            this.data.tasks.forEach(t => t.selected = false);
        }
    }

    collapseAllTasks(): void {
        gantt.batchUpdate(() => {
            gantt.eachTask((task: any) => {
                gantt.close(task.id)
            })
        })
    }
     
    expandAllTasks(): void {
        gantt.batchUpdate(() => {
            gantt.eachTask((task: any) => {
                gantt.open(task.id)
            })
        })
    }

    private filterData(ctrl: string, ev: any): void {
        if (!this.data || !this.data.tasks) { return; }

        //populate the gantt chart with the filtered data, but leave the original data unchanged
        const context = this;
        let fromDate = context.fromDateFilter;
        let toDate = context.toDateFilter;
        let filterProjectTagIds: number[] = this.projectTags.filter(tag => tag.selected).map(tag => tag.id);

        switch (ctrl) {
            case 'FromDateFilter':
                fromDate = ev;
                break;
            case 'ToDateFilter':
                toDate = ev;
                break;
        }

        let from = context.setMidnight(fromDate);
        let to = context.setMidnight(toDate);

        let filteredTasks = context.data.tasks.filter(task => {
            if (from && context.setMidnight(DateTime.fromJSDate(task.end_date)) < from) {
                return false; //exclude
            }
            if (to && context.setMidnight(DateTime.fromJSDate(task.start_date)) > to) {
                return false; //exclude
            }
            if (this.arrayHasItems(filterProjectTagIds)) {
                if (!this.arrayHasItems(task.projectTags)) { return false; }
                //ALL behaviour:
                // if (task.projectTags.filter(tag => !this.isNullOrUndefined(filterProjectTagIds.find(id => id == tag.id))).length != filterProjectTagIds.length) {
                //   return false; //exclude
                // }
                //ANY bahaviour:
                let check = task.projectTags.filter(tag => !this.isNullOrUndefined(filterProjectTagIds.find(id => id == tag.id)));
                if (check.length == 0) {
                    return false;
                }
            }
            return true; //include
        }).map(task => ProjectMasterPlanHelpers.cloneGanttTask(task));

        //because filtering might remove parent activities, we will have to
        //remove the parent relationship for orphaned activities in the results
        filteredTasks.forEach(task => {
            if (task.parent != null && task.parent != '') {
                //check if the parent task is in the filtered results
                let check = filteredTasks.find(t => t.id == task.parent);
                if (this.isNullOrUndefined(check)) { task.parent = ''; }
            }
        });

        if (!this.data.links) { this.data.links = []; }

        //ensure that only links where both tasks ids are in the 
        //filtered data are included, otherwise the chart will error!
        let filteredLinks = context.data.links.filter(link => {
            let id1 = filteredTasks.find(task => task.taskId == link.source);
            let id2 = filteredTasks.find(task => task.taskId == link.target);
            if (!id1 || !id2) {
                return false;
            }
            return true;
        }).map(link => ProjectMasterPlanHelpers.cloneGanttLink(link));

        if (filteredTasks.length != context.data.tasks.length) {
            //set the new date range view for the gantt chart
            let earliestStart: Date;
            let latestEnd: Date;
            filteredTasks.forEach(task => {
                if (task.type != 'project') {
                    if (!earliestStart || task.start_date.valueOf() < earliestStart.valueOf()) {
                        earliestStart = task.start_date;
                    }
                    if (!latestEnd || task.end_date.valueOf() > latestEnd.valueOf()) { 
                        latestEnd = task.end_date;
                    }
                }
            });
            if (earliestStart) {
                gantt.config.start_date = context.setMidnight(DateTime.fromJSDate(earliestStart).minus({ days: 3 })).toJSDate();
            }
            if (latestEnd) {
                gantt.config.end_date = context.setMidnight(DateTime.fromJSDate(latestEnd).plus({ days: 3 })).toJSDate();
            }
        } else {
            gantt.config.start_date = context.data.start;
            gantt.config.end_date = context.data.finish;
        }

        context.filtersActive = !this.isNullOrUndefined(from) || !this.isNullOrUndefined(to) || filterProjectTagIds.length > 0;

        context.reinitialize();
        gantt.clearAll();
        gantt.parse({ data: filteredTasks, links: filteredLinks });
    }
}
