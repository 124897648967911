import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { LastPlannerCommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
//import { PerfectScrollbarModule } from '@craftsjs/perfect-scrollbar';

import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import { EditorModule } from 'primeng/editor';
import { SplitterModule } from 'primeng/splitter';
import { DialogModule } from 'primeng/dialog';

import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { Angular2CountoModule } from '@awaismirza/angular2-counto';
import { IMaskModule } from 'angular-imask';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { SingleLineStringInputTypeComponent } from './input-types/single-line-string-input-type/single-line-string-input-type.component';
import { ComboboxInputTypeComponent } from './input-types/combobox-input-type/combobox-input-type.component';
import { CheckboxInputTypeComponent } from './input-types/checkbox-input-type/checkbox-input-type.component';
import { MultipleSelectComboboxInputTypeComponent } from './input-types/multiple-select-combobox-input-type/multiple-select-combobox-input-type.component';
import { PasswordInputWithShowButtonComponent } from './password-input-with-show-button/password-input-with-show-button.component';
import { KeyValueListManagerComponent } from './key-value-list-manager/key-value-list-manager.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { ElecoColourPickerComponent } from './eleco-colour-picker/eleco-colour-picker.component';
import { ElecoIconPickerComponent } from './eleco-icon-picker/eleco-icon-picker.component';
import { ElecoTagComponent } from './eleco-tag/eleco-tag.component';
import { ElecoTextSearchComponent } from './eleco-text-search/eleco-text-search.component';
import { ProjectTagLinkManagerComponent } from './projecttag-link-manager/projecttag-link-manager.component';
import { ProjectTagSelectDropdownComponent } from './projecttag-select-dropdown/projecttag-select-dropdown.component';
import { GanttComponent } from './gantt-component/gantt.component';
import { TaskNoteComponent } from './tasknote/tasknote.component';
import { ElecoProgressbarComponent } from './eleco-progressbar/eleco-progressbar.component';
import { PullplanWhiteboardComponent } from './pullplan-whiteboard/pullplan-whiteboard.component';
import { TaskEditModalComponent } from './task-edit-modal/task-edit-modal.component';
import { PullplanTaskLinkComponent } from './pullplan-task-link/pullplan-task-link.component';
import { UserMonogramComponent } from './user-monogram/user-monogram.component';
import { UserSelectDropdownComponent } from './user-select-dropdown/user-select-dropdown.component';
import { UserLinkManagerComponent } from './user-link-manager/user-link-manager.component';
import { ElecoPatternPickerComponent } from './eleco-pattern-picker/eleco-pattern-picker.component';
import { ElecoSplitterComponent } from './eleco-splitter/eleco-splitter.component';
import { GanttActivityLinkComponent } from './gantt-activity-link/gantt-activity-link.component';
import { ComponentOutletDirective } from './component-outlet/component-outlet.directive';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ElecoDropdownCheckboxlistComponent } from './eleco-dropdown-checkboxlist/eleco-dropdown-checkboxlist.component';
import { BaselineTaskNoteComponent } from './baseline-tasknote/baseline-tasknote.component';
import { BuildLocationSelectDropdownComponent } from './buildlocation-select-dropdown/buildlocation-select-dropdown.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        LastPlannerCommonModule,
        TableModule,
        PaginatorModule,
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        //PerfectScrollbarModule,
        Angular2CountoModule,
        IMaskModule,
        AppBsModalModule,
        AutoCompleteModule,
        ContextMenuModule,
        MenuModule,
        EditorModule,
        SplitterModule,
        DialogModule
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        MultipleSelectComboboxInputTypeComponent,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        DatePickerComponent,
        DateTimePickerComponent,
        ElecoColourPickerComponent,
        ElecoIconPickerComponent,
        ElecoTagComponent,
        ElecoTextSearchComponent,
        ProjectTagLinkManagerComponent,
        ProjectTagSelectDropdownComponent,
        GanttComponent,
        ElecoProgressbarComponent,
        TaskNoteComponent,
        PullplanWhiteboardComponent,
        TaskEditModalComponent,
        PullplanTaskLinkComponent,
        UserMonogramComponent,
        UserSelectDropdownComponent,
        UserLinkManagerComponent,
        ElecoPatternPickerComponent,
        ElecoSplitterComponent,
        GanttActivityLinkComponent,
        ComponentOutletDirective,
        LoadingSpinnerComponent,
        ElecoDropdownCheckboxlistComponent,
        BaselineTaskNoteComponent,
        BuildLocationSelectDropdownComponent,
    ],
    exports: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        DatePickerComponent,
        DateTimePickerComponent,
        ElecoColourPickerComponent,
        ElecoIconPickerComponent,
        ElecoTagComponent,
        ElecoTextSearchComponent,
        ProjectTagLinkManagerComponent,
        ProjectTagSelectDropdownComponent,
        GanttComponent,
        ElecoProgressbarComponent,
        TaskNoteComponent,
        PullplanWhiteboardComponent,
        TaskEditModalComponent,
        PullplanTaskLinkComponent,
        UserMonogramComponent,
        UserSelectDropdownComponent,
        UserLinkManagerComponent,
        EditorModule,
        ElecoPatternPickerComponent,
        SplitterModule,
        ElecoSplitterComponent,
        GanttActivityLinkComponent,
        MenuModule,
        DialogModule,
        ComponentOutletDirective,
        LoadingSpinnerComponent,
        ElecoDropdownCheckboxlistComponent,
        BaselineTaskNoteComponent,
        BuildLocationSelectDropdownComponent,
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
        { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [AppAuthService, AppRouteGuard],
        };
    }
}
