<div class="projecttag-select-dropdown" dropdown #dropdown="bs-dropdown" [insideClick]="true" 
    [autoClose]="true" (isOpenChange)="dropdownChange($event)">
    <div class="input-group">
        <input type="text" [id]="id ? id : null" [name]="name ? name : null" id="displayCount" 
            class="form-control text-black" [(ngModel)]="displayCount" (click)="dropdownToggleButton.click()" readonly />
        <button type="button" dropdownToggle #dropdownToggleButton class="dropdown-toggle" aria-controls="projecttag-dropdown">
            <i class="ds-icon-default-chevron-down font-bold"></i>
        </button>
        <eleco-tag class="singleselect-tag" *ngIf="!multiSelect && selectedId != 0" [data]="selectedTag"></eleco-tag>
    </div>
    <div id="projecttag-dropdown" *dropdownMenu class="dropdown-menu">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <p class="nav-link" [class.active]="showGlobal" role="button" (click)="showGlobal = true">{{ 'Global' | localize }}</p>
            </li>
            <li class="nav-item">
                <p class="nav-link" [class.active]="!showGlobal" [class.disabled]="!projectId || projectId == 0" role="button" (click)="showGlobal = false">{{ 'ProjectSpecific' | localize }}</p>
            </li>
          </ul>
        <div class="projecttag-container">
            <div class="projecttag-list">
                <div *ngIf="showGlobal">
                    {{ trackCategory() }}
                    <div *ngFor="let tag of _globalTags;" [hidden]="tag.tagProjectId">
                        <p *ngIf="isNewCategory(tag)" class="category">{{ tag.tagCategory }}</p>
                        <label class="list-entry">
                            <input type="checkbox" name="chkProjectTagG" *ngIf="multiSelect" [(ngModel)]="tag.selected" (change)="selectChanged()" />
                            <input type="radio" id="{{ 'radProjectTagG_' + tag.id }}" name="radProjectTagG" [value]="tag.id" *ngIf="!multiSelect" 
                                [(ngModel)]="selectedId" (change)="selectChanged()" />
                            <eleco-tag [data]="tag"></eleco-tag>
                        </label>
                    </div>
                    <p [hidden]="hasGlobalTags">{{ 'NoneFound' | localize }}</p>
                </div>
                <div *ngIf="!showGlobal">
                    {{ trackCategory() }}
                    <div *ngFor="let tag of _projectTags;" [hidden]="!tag.tagProjectId">
                        <p *ngIf="isNewCategory(tag)" class="category">{{ tag.tagCategory }}</p>
                        <label class="list-entry">
                            <input type="checkbox" name="chkProjectTagP" *ngIf="multiSelect" [(ngModel)]="tag.selected" (change)="selectChanged()" />
                            <input type="radio" id="{{ 'radProjectTagP_' + tag.id }}" name="radProjectTagP" [value]="tag.id" *ngIf="!multiSelect" 
                                [(ngModel)]="selectedId" (change)="selectChanged()" />
                            <eleco-tag [data]="tag"></eleco-tag>
                        </label>
                    </div>
                    <p [hidden]="hasProjectTags">{{ 'NoneFound' | localize }}</p>
                </div>
            </div>
        </div>
    </div>
</div>