import { Component, ViewChild, Injector, Output, EventEmitter, OnInit, ElementRef, forwardRef, AfterViewInit, SimpleChanges, OnChanges, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ProjectTagDto, PullPlanTaskDto, PullPlanTaskLookupDto, ProjectTagLinksToTaskDto, IPullPlanTaskDto,
    PullPlanDto, ActivityDto, PullPlanTasksServiceProxy, PullPlanTaskPredecessorDto, SwimlaneDto, 
    RiskCategoryDto, RiskCategoriesServiceProxy, UserListDto, ProjectSettingDto, BuildLocationDto, 
    BuildLocationsServiceProxy, PullPlanTeamDto, PullPlanTeamsServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DateTime } from 'luxon';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { TaskStatus } from '@shared/PullPlanningEnums';
import { TaskNoteComponent } from '@app/shared/common/tasknote/tasknote.component';
import { IElecoColourPickerCustomColour } from '@app/shared/common/eleco-colour-picker/eleco-colour-picker.component';
import { ProjectTagLinkManagerComponent } from '../projecttag-link-manager/projecttag-link-manager.component';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { EntityTypeHistoryModalComponent } from '@app/shared/common/entityHistory/entity-type-history-modal.component';
import { filter as _filter } from 'lodash-es';
import { Table } from 'primeng/table';
import { UserSelectDropdownComponent } from '../user-select-dropdown/user-select-dropdown.component';
import { SwimlaneTypeEnum } from '../pullplan-whiteboard/pullplan-whiteboard.component';

@Component({
    selector: 'task-edit-modal',
    templateUrl: './task-edit-modal.component.html',
    styleUrls: ['./task-edit-modal.component.scss']
})
export class TaskEditModalComponent extends AppComponentBase implements AfterViewInit, OnInit, IPullPlanTaskDto {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @ViewChild('entityTypeHistoryModal', { static: true }) entityTypeHistoryModal: EntityTypeHistoryModalComponent;
    @ViewChild('taskPreview') taskPreview: TaskNoteComponent; //using static doesn't work with this for some reason!
    @ViewChild('projectTagLinkManager', { static: false }) projectTagLinkManager: ProjectTagLinkManagerComponent;
    @ViewChild('PullPlanTask_TaskTitle', { read: ElementRef }) taskTitleTextbox: ElementRef;
    @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;
    @ViewChild('predecessorLinksTable', { static: true }) predecessorLinksTable: Table;
    @ViewChild('successorLinksTable', { static: true }) successorLinksTable: Table;
    @ViewChild('taskRiskOwnerUserIdControl', { static: true}) taskRiskOwnerUserIdControl: UserSelectDropdownComponent;

    @Input() allProjectTags: ProjectTagDto[] = [];
    @Input() swimlanes: SwimlaneDto[] = [];
    @Input() pullPlan: PullPlanDto;
    @Input() readonly: boolean = false;
    
    @Output() modalSave = new EventEmitter<PullPlanTaskDto>(); //return the task
    @Output() modalDelete = new EventEmitter<number>(); //return the taskId to delete

    _entityTypeFullName: string = 'Elecosoft.LastPlanner.PullPlanning.PullPlanTask';
    entityHistoryEnabled: boolean = false;

    projectId: number = 0;
    activities: ActivityDto[] = [];
    activitiesForLinking: ActivityDto[] = [];
    taskLookupList: PullPlanTaskLookupDto[] = [];
    childLinkedTasks: PullPlanTaskLookupDto[] = [];
    projectSettings: ProjectSettingDto[] = [];
    riskCategories: RiskCategoryDto[] = [];
    userList: UserListDto[] = [];
    buildLocations: BuildLocationDto[] = [];
    taskProjectTeams: PullPlanTeamDto[] = [];

    //task properties
    id!: number;
    taskStatusId!: number;
    taskResponsibility!: string | undefined;
    taskTeamSize!: number | undefined;
    taskTitle!: string | undefined;
    taskDate!: DateTime;
    taskDuration!: number;
    taskProgress!: number;
    taskLocation!: string | undefined;
    taskLocationId!: number | undefined;
    taskLocationTitle: string | undefined;
    taskWorkArea!: string | undefined;
    taskPullPlanId!: number;
    taskSwimlaneId!: number | undefined; // 21/08/2024 PMc #7265 - deprecated
    taskParentTaskId!: number | undefined;
    taskTheme!: string | undefined;
    taskActualEffort: number;
    taskActualTeamSize: number | undefined;
    taskPullPlanActivityLinkId!: number | undefined;
    taskColumnCellIndex: number;
    swapCellIndexTaskId: number | undefined;
    swimlaneIndex: number | undefined;
    swimlaneTeamId: number | undefined;
    swimlaneTeamTitle: string | undefined;
    swimlaneTeamColour: string | undefined;
    swimlaneTeamTextColour: string | undefined;
    taskNotes: string = '';
    linkedActivityName: string = '';
    selected: boolean = false;
    deleted: boolean = false;
    taskLocked: boolean = false;
    uneditedTaskLocked: boolean = false; //store the value prior to editing
    uneditedTaskStatusId!: number;
    taskProjectTeamId: number | undefined; // 21/08/2024 PMc #7528
    locationLocked: boolean = false; // 27/09/2024 PMc #7528

    // 31/05/2023 PMc #648 - added
    taskRiskCategoryId!: number | undefined;
    taskRiskOwnerUserId!: number | undefined;
    taskRiskIdentifiedDate!: DateTime | undefined;
    taskRiskResolveByDate!: DateTime | undefined;
    taskRiskResolvedDate!: DateTime | undefined;
    taskRiskNotes!: string | undefined;
    riskCategoryName!: string | undefined;
    riskOwnerName!: string | undefined;
    taskRiskIdentifiedDateBeforeEdit: DateTime | undefined; //store this when the dialog is shown
    taskRiskHasBeenResolved: boolean = false; //this doesn't get saved

    projectTagLinks!: ProjectTagLinksToTaskDto[] | undefined;
    linkedProjectTagIds!: string | undefined; //csv of projectTagIds
    linkedProjectTags: ProjectTagDto[] = [];
    
    predecessorTaskIds!: string | undefined; //this is for passing back changes to the API
    successorTaskIds!: string | undefined; //this is for passing back changes to the API
    taskPredecessors: PullPlanTaskPredecessorDto[] | undefined; //this is for populating lists for editing
    taskSuccessors: PullPlanTaskPredecessorDto[] | undefined; //this is for populating lists for editing

    TaskStatusEnum: typeof TaskStatus = TaskStatus;
    changesMade: boolean = false;
    saving: boolean = false;
    editingPredecessorLink: boolean = false;
    editPredecessorLink: PullPlanTaskPredecessorDto;
    editingSuccessorLink: boolean = false;
    editSuccessorLink: PullPlanTaskPredecessorDto;
    nextNewId: number = -1;
    canSave: boolean = false;
    projectSettingTaskLockingEnabled: boolean = false;

    private readonly tab_details = 0;
    private readonly tab_dependencies = 1;
    private readonly tab_risklog = 2;
    private readonly tab_projecttags = 3;

    // get hasTaskRiskCategory(): boolean {
    //     return !this.isNullOrUndefinedOrNaNOrZero(this.taskRiskCategoryId);
    // }

    get taskRiskFieldsPopulated(): boolean {
        return !this.isNullOrUndefinedOrNaNOrZero(this.taskRiskCategoryId)
            && !this.isNullOrUndefined(this.taskRiskIdentifiedDate)
            && !this.isNullOrUndefinedOrNaNOrZero(this.taskRiskOwnerUserId)
            && !this.isNullOrUndefined(this.taskRiskResolveByDate);
    }

    get taskRiskResolved(): boolean {
        return this.taskRiskFieldsPopulated && !this.isNullOrUndefined(this.taskRiskResolvedDate);
    }

    get isTaskAtRisk(): boolean {
        return this.taskStatusId == TaskStatus.AtRiskOrBlocked;
    }

    get riskFieldsReadonly(): boolean {
        return this.taskStatusId != TaskStatus.AtRiskOrBlocked && this.taskRiskFieldsPopulated && this.taskRiskResolved;

        // return !this.isNullOrUndefined(this.taskRiskResolvedDate)
        //     || (this.taskStatusId != TaskStatus.AtRiskOrBlocked 
        //         && !this.isNullOrUndefinedOrNaNOrZero(this.taskRiskCategoryId)
        //     );
    }

    get riskFieldsDisabled(): boolean {
        return !this.isTaskAtRisk && !this.taskRiskFieldsPopulated;

        // return !this.isNullOrUndefined(this.taskRiskResolvedDate)
        //     || (this.taskStatusId != TaskStatus.AtRiskOrBlocked 
        //         && this.isNullOrUndefinedOrNaNOrZero(this.taskRiskCategoryId)
        //     );
    }

    get taskStatusLocked(): boolean {
        return this.isTaskAtRisk; // && !this.taskRiskResolved;

        // return this.taskStatusId == TaskStatus.AtRiskOrBlocked 
        //     && this.isNullOrUndefined(this.taskRiskResolvedDate);
    }

    customThemeColours: IElecoColourPickerCustomColour[] = [
        { hex: '#fcf195', title: this.l('TaskTheme_Yellow'), value: 'yellow' },
        { hex: '#ffcd70', title: this.l('TaskTheme_Orange'), value: 'orange' },
        { hex: '#fdd9e5', title: this.l('TaskTheme_Pink'), value: 'pink' },
        { hex: '#d6fa7a', title: this.l('TaskTheme_Green'), value: 'green' },
        { hex: '#8dd5fe', title: this.l('TaskTheme_Blue'), value: 'blue' },
    ];

    constructor(
        injector: Injector,
        private _dateTimeService: DateTimeService,
        private _pullPlanTasksServiceProxy: PullPlanTasksServiceProxy,
        private _riskCategoriesServiceProxy: RiskCategoriesServiceProxy,
        private _buildLocationsService: BuildLocationsServiceProxy,
        private _pullPlanTeamsServiceProxy: PullPlanTeamsServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.canSave = this.permission.isGranted('Pages.PullPlanTasks.Edit');
    }

    ngAfterViewInit(): void {
        //this.modal.config = { keyboard: false }; //disable using escape to close the modal
        this.refreshTaskPreview();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) { return; }

        if (changes.pullPlan) {
            this.pullPlanChanged();
        }
    }

    pullPlanChanged(): void {
        if (this.isNullOrUndefined(this.pullPlan)) {
            this.activities = [];
            this.activitiesForLinking = [];
            this.taskLookupList = [];
        } else {
            this.activities = this.pullPlan.activityLinks
                .filter(l => l.linkActivityFk.activityType != 1)
                .map(l => l.linkActivityFk);
            this.activitiesForLinking = this.activities.map(a => new ActivityDto(a));
            //build the text for the dropdown
            this.activitiesForLinking.forEach(a => a.activityName = this.buildActivityText(a));
        }
    }

    onChangesMade(prop?: string): void {
        this.changesMade = true;
        if (this.taskPreview != null && typeof this.taskPreview != 'undefined') {
            this.refreshTaskPreview();
        }
        if (prop == 'taskStatusId') {
            var checkRiskFields = false;
            if (this.taskStatusId == TaskStatus.Complete) {
                checkRiskFields = true;
                this.taskProgress = 100;
            } else if (this.taskStatusId == TaskStatus.ToDo || this.taskStatusId == TaskStatus.InProgress) {
                checkRiskFields = true;
                this.taskProgress = 0;
            } else if (this.taskStatusId == TaskStatus.AtRiskOrBlocked) {
                
                //check if the fields are already filled in (if so, don't change them)
                if (this.isNullOrUndefinedOrNaNOrZero(this.taskRiskCategoryId)) {
                    this.taskRiskCategoryId = 0; //--select--
                    this.taskRiskIdentifiedDate = this.today;
                    this.taskRiskOwnerUserId = undefined;
                    this.taskRiskResolveByDate = undefined;
                    this.taskRiskResolvedDate = undefined;
                }
                
                //switch tabs to the Risk Log
                this.staticTabs.tabs[this.tab_risklog].active = true;
            }

            if (checkRiskFields && this.isNullOrUndefined(this.taskRiskIdentifiedDateBeforeEdit)) {
                //clear the risk log fields
                this.taskRiskCategoryId = undefined;
                this.taskRiskIdentifiedDate = undefined;
                this.taskRiskOwnerUserId = undefined;
                this.taskRiskOwnerUserIdControl.clearSelections();
                this.taskRiskResolveByDate = undefined;
                this.taskRiskResolvedDate = undefined;
                this.taskRiskNotes = undefined;
                this.taskRiskHasBeenResolved = false;
            }
            
        } else if (prop == 'taskProgress') {
            if (this.taskStatusId != TaskStatus.AtRiskOrBlocked) {
                if (this.taskProgress == 100) {
                    this.taskStatusId = TaskStatus.Complete;
                } else {
                    this.taskStatusId = TaskStatus.InProgress;
                }
            }
        //} else if (prop == 'taskSwimlaneId') {
        } else if (prop == 'taskProjectTeamId') {
            let index = 0;
            let title = '';
            let colour = '';
            let textColour = '';

            //if (this.isNullOrUndefinedOrNaN(this.taskSwimlaneId)) {
            if (this.isNullOrUndefinedOrNaN(this.taskProjectTeamId)) {
                //blank the responsibility field
                //this.taskResponsibility = ''; // field is deprecated
            } else {
                //let swimlane = this.swimlanes.find(s => s.id == this.taskSwimlaneId);
                let swimlane = this.swimlanes.find(s => s.swimlaneTeamId == this.taskProjectTeamId);
                if (!this.isNullOrUndefined(swimlane)) {
                    index = swimlane.swimlaneIndex;
                    title = swimlane.swimlaneTeamTitle;
                    colour = swimlane.swimlaneTeamColour;
                    textColour = swimlane.swimlaneTeamTextColour;
                }
            }

            //this is mainly to colour the task note preview
            this.swimlaneIndex = index;
            this.swimlaneTeamId = this.taskProjectTeamId;
            this.swimlaneTeamTitle = title;
            this.swimlaneTeamColour = colour;
            this.swimlaneTeamTextColour = textColour;

            this.refreshTaskPreview();

        } else if (prop == 'taskPullPlanActivityLinkId') {
            let linkedActivity = this.getActivity(this.taskPullPlanActivityLinkId);
            this.linkedActivityName = linkedActivity?.activityName;
            if (!this.isNullOrUndefined(linkedActivity) && !this.isNullOrUndefinedOrNaNOrZero(linkedActivity.activityLocationId)) {
                this.taskLocationId = linkedActivity.activityLocationId;
                this.locationLocked = true;
                this.updateBuildLocationTitle();
            } else {
                this.locationLocked = false;
            }

        } else if (prop == 'taskRiskOwnerUserId') {
            let user = this.userList.filter(u => u.selected); //should only be one selected
            if (this.arrayHasItems(user) && !this.isNullOrUndefinedOrNaNOrZero(user[0].id)) {
                this.taskRiskOwnerUserId = user[0].id;
            } else {
                this.taskRiskOwnerUserId = undefined;
            }

        } else if (prop == 'taskRiskHasBeenResolved') {
            if (this.taskRiskHasBeenResolved && !this.taskRiskResolved) {
                //default the resolved date to today
                this.taskRiskResolvedDate = this.today;
            } else {
                this.taskRiskResolvedDate = undefined;
            }
        } else if (prop == 'taskLocationId') {
            if (!this.isNullOrUndefinedOrNaNOrZero(this.taskLocationId)) {
                this.updateBuildLocationTitle();
            } else {
                this.taskLocationTitle = '';
            }
        }
    }

    private loadRiskCategories(): void {
        let context = this;
        this._riskCategoriesServiceProxy.getAllRiskCategories().subscribe((results) => {
            context.riskCategories = results;
        });
    }

    private loadBuildLocationsForPullPlan(pullPlanId): void {
        let context = this;
        this._buildLocationsService.getBuildLocationsForPullPlan(pullPlanId).subscribe((results) => {
            context.buildLocations = results;
        });
    }

    private loadProjectTeamsForPullPlan(): void {
        let context = this;
        this._pullPlanTeamsServiceProxy.getProjectTeamsForPullPlan(this.pullPlan.id).subscribe((results) => {
            context.taskProjectTeams = results;
        });
    }

    loadUsers(): void {
        let context = this;
        this._pullPlanTasksServiceProxy.getUserList().subscribe((result) => {
            context.userList = result;
            context.updateTaskRiskOwnerUserSelection();
        });
    }

    onModalShown(ev: any): void {
        this.taskRiskIdentifiedDateBeforeEdit = this.taskRiskIdentifiedDate; //stash this value
        this.taskTitleTextbox.nativeElement.focus();
    }

    private refreshTaskPreview(): void {
        if (this.isNullOrUndefined(this.taskPreview)) { return; }
        this.taskPreview.refresh();
    }

    private getTaskLookupList(): void {
        let context = this;
        this._pullPlanTasksServiceProxy.getTaskLinkLookupList(this.taskPullPlanId, this.id).subscribe((result) => {
            context.taskLookupList = result;
            context.updateLinkedTaskTitles();
        });
    }

    private getTaskTitle(taskId: number): string {
        if (taskId == this.id) { return this.taskTitle; }

        let task = this.taskLookupList.find(t => t.id == taskId);
        if (!this.isNullOrUndefined(task)) { return task.taskTitle; }

        return this.l("SelectATask");
    }

    private updateLinkedTaskTitles(): void {
        let context = this;

        if (!this.isNullOrUndefined(this.taskPredecessors) && this.taskPredecessors.length > 0) {
            this.taskPredecessors.forEach(p => {
                p.taskTitle = context.getTaskTitle(p.taskId);
                p.predecessorTaskTitle = context.getTaskTitle(p.predecessorTaskId);
            });
        }

        if (!this.isNullOrUndefined(this.taskSuccessors) && this.taskSuccessors.length > 0) {
            this.taskSuccessors.forEach(s => {
                s.taskTitle = context.getTaskTitle(s.taskId);
                s.predecessorTaskTitle = context.getTaskTitle(s.predecessorTaskId);
            });
        }
    }

    private createDefaultNewTask(): PullPlanTaskDto {
        let model = new PullPlanTaskDto();
        model.id = 0;
        model.linkedProjectTagIds = '';
        model.projectTagLinks = [];
        model.taskDate = this.today;
        model.taskDuration = 1;
        model.taskLocation = '';
        model.taskLocationId = undefined;
        model.taskLocationTitle = '';
        model.taskProgress = 0;
        model.taskPullPlanId = this.taskPullPlanId;
        model.taskResponsibility = '';
        model.taskStatusId = this.TaskStatusEnum.ToDo;
        model.taskSwimlaneId = undefined; // 21/08/2024 PMc #7528 - deprecated
        model.taskParentTaskId = undefined;
        model.taskTeamSize = 1;
        model.taskTheme = 'yellow';
        model.taskTitle = '';
        model.taskWorkArea = '';
        model.taskPullPlanActivityLinkId = null;
        model.taskColumnCellIndex = 0;
        model.taskActualEffort = 0;
        model.taskActualTeamSize = null;
        model.predecessorTaskIds = ''; //this is for passing back changes to the API
        model.successorTaskIds = ''; //this is for passing back changes to the API
        model.taskPredecessors = []; //this is for populating lists for editing
        model.taskSuccessors = []; //this is for populating lists for editing
        model.swimlaneTeamTitle = '';
        model.swimlaneTeamColour = '';
        model.swimlaneTeamTextColour = '';
        model.taskNotes = '';
        model.linkedActivityName = '';
        // 31/05/2023 PMc #648 - added
        model.taskRiskCategoryId = undefined;
        model.taskRiskOwnerUserId = undefined;
        //lookup the Team assigned to this SwimlanetaskRiskIdentifiedDate = undefined;
        model.taskRiskResolveByDate = undefined;
        model.taskRiskResolvedDate = undefined;
        model.taskRiskNotes = undefined;
        model.taskLocked = false;
        model.taskProjectTeamId = undefined; // 21/08/2024 PMc #7528 - added
        return model;
    }

    showFromWhiteboard(pullPlan: PullPlanDto, allProjectTags: ProjectTagDto[], allSwimlanes: SwimlaneDto[],
        allRiskCategories: RiskCategoryDto[], allTeams: PullPlanTeamDto[], task: PullPlanTaskDto, taskDate: DateTime | undefined,
        swimlaneId: number | undefined, projectSettings: ProjectSettingDto[], buildLocations: BuildLocationDto[]): void {
        this.saving = false;
        this.allProjectTags = allProjectTags;
        this.swimlanes = allSwimlanes;
        this.pullPlan = pullPlan;
        this.riskCategories = allRiskCategories;
        //filter the teams to only those there is a swimlane for
        let swimlaneTeamIds = allSwimlanes.filter(s => s.swimlaneType == SwimlaneTypeEnum.ProjectTeam).map(s => s.swimlaneTeamId);
        this.taskProjectTeams = allTeams.filter(t => swimlaneTeamIds.find(id => id == t.id) || (task != null && t.id == task.taskProjectTeamId));
        //filter the locations to only those there is a swimlane for
        let swimlaneLocationIds = allSwimlanes.filter(s => s.swimlaneType == SwimlaneTypeEnum.Location).map(s => s.swimlaneLocationId);
        this.buildLocations = buildLocations.filter(l => swimlaneLocationIds.find(id => id == l.id) || (task != null && l.id == task.taskLocationId));

        this.pullPlanChanged();
        this.show(pullPlan.id, pullPlan.pullPlanProjectId, task, taskDate, swimlaneId, projectSettings);
    }

    show(pullPlanId: number, projectId: number, task: PullPlanTaskDto, taskDate: DateTime | undefined, 
        swimlaneId: number | undefined, projectSettings: ProjectSettingDto[]): void {
        this.saving = false;
        this.taskPullPlanId = pullPlanId;
        this.projectId = projectId;
        this.projectSettings = projectSettings;

        let lockTasksSetting = this.getProjectSettingValue('ProjectSetting_LockCompletedTasks');
        this.projectSettingTaskLockingEnabled = (lockTasksSetting != null && this.parseBoolean(lockTasksSetting));

        this.entityHistoryEnabled = this.setIsEntityHistoryEnabled();
        if (!this.isNullOrUndefined(this.staticTabs)) {
            this.staticTabs.tabs[this.tab_details].active = true; //Details tab
        }
        this.editingPredecessorLink = false;
        this.editingSuccessorLink = false;
        this.taskRiskOwnerUserIdControl.clearSelections();

        this.loadBuildLocationsForPullPlan(pullPlanId);

        if (!this.arrayHasItems(this.riskCategories)) {
            this.loadRiskCategories();
        }

        if (!this.arrayHasItems(this.userList)) {
            this.loadUsers();
        }

        if (!this.arrayHasItems(this.taskProjectTeams)) {
            this.loadProjectTeamsForPullPlan();
        }

        this.getTaskLookupList();

        if (this.isNullOrUndefined(task)) {
            this.setupFormForNewTask(taskDate, swimlaneId);
            return;
        }

        if (!this.isNullOrUndefinedOrNaNOrZero(task.taskPullPlanActivityLinkId)
            && this.isNullOrUndefinedOrEmptyString(task.linkedActivityName)) {
            let link = this.pullPlan.activityLinks.find(l => l.linkActivityId == task.taskPullPlanActivityLinkId);
            if (!this.isNullOrUndefined(link) && !this.isNullOrUndefined(link.linkActivityFk)) {
                task.linkedActivityName = link.linkActivityFk.activityName;
            }
        }

        this.mapTaskProperties(task, this);
        this.linkedProjectTags = task.projectTagLinks.map(l => l.linkProjectTagFk);
        this.uneditedTaskLocked = task.taskLocked;
        this.uneditedTaskStatusId = task.taskStatusId;

        if (this.isNullOrUndefinedOrNaN(this.taskProgress)) {
            this.taskProgress = 0;
        }

        if (this.isNullOrUndefinedOrNaN(this.taskStatusId)) {
            this.taskStatusId = this.TaskStatusEnum.ToDo;
        }

        if (this.arrayHasItems(this.userList) && !this.isNullOrUndefinedOrNaNOrZero(this.taskRiskOwnerUserId)) {
            this.updateTaskRiskOwnerUserSelection();
        }

        this.taskRiskHasBeenResolved = !this.isNullOrUndefined(this.taskRiskResolvedDate);

        this.locationLocked = false;
        if (!this.isNullOrUndefinedOrNaNOrZero(this.taskPullPlanActivityLinkId)) {
            let linkedActivity = this.getActivity(this.taskPullPlanActivityLinkId);
            if (!this.isNullOrUndefinedOrNaNOrZero(linkedActivity?.activityLocationId)) {
                this.taskLocationId = linkedActivity.activityLocationId;
                this.locationLocked = true;
            }
        }

        this.updateBuildLocationTitle();

        this.modal.show();
    }

    private setIsEntityHistoryEnabled(): boolean {
        let customSettings = (abp as any).custom;
        return (
            this.isGrantedAny('Pages.Administration.AuditLogs') &&
            customSettings.EntityHistory &&
            customSettings.EntityHistory.isEnabled &&
            _filter(
                customSettings.EntityHistory.enabledEntities,
                (entityType) => entityType === this._entityTypeFullName
            ).length === 1
        );
    }

    private mapTaskProperties(source: IPullPlanTaskDto, target: IPullPlanTaskDto): void {
        let model = this.createDefaultNewTask(); //use this as it initialises all properties
        for (var property in model) {
            if (model.hasOwnProperty(property)) {
                (<any>target)[property] = (<any>source)[property];
            }
        }
        this.updateLinkedTaskTitles();
    }

    private setupFormForNewTask(taskDate: DateTime | undefined, swimlaneId: number | undefined): void {
        //let taskDate: DateTime = this._dateTimeService.getStartOfDay(); //today

        if (this.isNullOrUndefined(taskDate) && !this.isNullOrUndefined(this.pullPlan)) {
            //get the earliest Task Start Date so we have something meaningful to default to
            let activityStarts = this.pullPlan.activityLinks?.map(l => l.linkActivityFk?.activityStart);
            if (!this.isNullOrUndefined(activityStarts) && activityStarts.length > 0) {
                let minDate = 0;
                activityStarts.forEach(dt => {
                    if (minDate == 0 || dt.toMillis() < minDate) { minDate = dt.toMillis(); }
                });
                if (minDate > 0) {
                    taskDate = DateTime.fromMillis(minDate);
                }
            }
        }

        let task = this.createDefaultNewTask();
        task.taskPullPlanId = this.taskPullPlanId;
        task.taskDate = this.dateTimeToUtc(taskDate);
        //task.taskSwimlaneId = swimlaneId;
        task.taskTheme = 'yellow';

        //lookup the Team assigned to this Swimlane
        let swimlane = this.swimlanes.find(s => s.id == swimlaneId);
        if (!this.isNullOrUndefined(swimlane)) {
            if (!this.isNullOrUndefined(swimlane.swimlaneTeamId)) {
                let team = this.taskProjectTeams.find(t => t.id == swimlane.swimlaneTeamId);
                if (!this.isNullOrUndefined(team)) {
                    task.taskProjectTeamId = team.id;
                }
            }
            if (!this.isNullOrUndefined(swimlane.swimlaneLocationId)) {
                let location = this.buildLocations.find(l => l.id == swimlane.swimlaneLocationId);
                if (!this.isNullOrUndefined(location)) {
                    task.taskLocationId = location.id;
                }
            }
        }

        this.mapTaskProperties(task, this);

        this.linkedProjectTags = [];

        this.modal.show();
    }

    save(counter?: number): void {
        //clicking on the save button when a dropdown is active 
        //executes the save click action before the drop-up action,
        //which can result in lost values, so use setTimeout() to
        //allow for propagated events to happen first.
        //It's ugly, but can find no way around it!
        if (this.projectTagLinkManager.dropdownOpen || this.taskRiskOwnerUserIdControl.dropdownOpen) {
            if (!counter) { counter = 1; }
            if (counter < 10) {
                setTimeout(() => this.save(counter++), 20);
                return;
            }
        }

        this.saving = true;

        //validate risk fields
        if (this.isTaskAtRisk) {
            //mandatory fields
            
            let requiredFields = '';
            if (this.isNullOrUndefinedOrNaNOrZero(this.taskRiskCategoryId)) {
                requiredFields += `<li>${this.l('RiskCategory')}</li>`;
            }
            if (this.isNullOrUndefined(this.taskRiskIdentifiedDate)) {
                requiredFields += `<li>${this.l('TaskRiskIdentifiedDate')}</li>`;
            }
            if (this.isNullOrUndefinedOrNaNOrZero(this.taskRiskOwnerUserId)) {
                requiredFields += `<li>${this.l('TaskRiskOwner')}</li>`;
            }
            if (this.isNullOrUndefined(this.taskRiskResolveByDate)) {
                requiredFields += `<li>${this.l('TaskRiskResolveByDate')}</li>`;
            }
            if (this.taskRiskHasBeenResolved && this.isNullOrUndefined(this.taskRiskResolvedDate)) {
                requiredFields += `<li>${this.l('TaskRiskResolvedDate')}</li>`;
            }
            
            if (requiredFields != '') {
                this.message.warn(`<div style="text-align: left;line-height: 1.3em;">${this.l('WarningRequiredFields')}<ul>${requiredFields}</ul></div>`);
                this.saving = false;
                return;
            }
        }

        //pack the linked ProjectTag Ids into a csv to pass back to the API
        this.linkedProjectTagIds = '';
        if (!this.isNullOrUndefined(this.linkedProjectTags)) {
            this.linkedProjectTagIds = this.linkedProjectTags
                .map((tag) => tag.id)
                .join(',');
        }

        //pack the linked predecessor Task Ids into a csv to pass back to the API
        this.predecessorTaskIds = '';
        if (!this.isNullOrUndefined(this.taskPredecessors)) {
            this.predecessorTaskIds = this.taskPredecessors
                .filter(link => !link.deleted)
                .map(link => link.predecessorTaskId)
                .join(',');
        }

        //pack the linked successor Task Ids into a csv to pass back to the API
        this.successorTaskIds = '';
        if (!this.isNullOrUndefined(this.taskSuccessors)) {
            this.successorTaskIds = this.taskSuccessors
                .filter(link => !link.deleted)
                .map(link => link.taskId).join(',');
        }

        //check for any taskIds that are in both the predecessor and successor lists (not allowed)
        let predecessors = this.taskPredecessors.filter(link => !link.deleted).map((link) => link.predecessorTaskId);
        let successors = this.taskSuccessors.filter(link => !link.deleted).map((link) => link.taskId);
        let check = predecessors.filter(value => successors.includes(value));
        if (!this.isNullOrUndefined(check) && check.length > 0) {
            this.notify.error(this.l("TaskPredecessorSuccessorDuplicate"));
            this.saving = false;
            return;
        }

        let task = new PullPlanTaskDto();
        this.mapTaskProperties(this, task);
        task.taskPredecessors = this.taskPredecessors;
        task.taskSuccessors = this.taskSuccessors;

        //if task status is "At Risk/Blocked" but is now marked as "Resolved", then set the new status dependent on Progress
        if (this.isTaskAtRisk && this.taskRiskResolved) {
            //set new status
            if (this.taskProgress == 0) {
                task.taskStatusId = TaskStatus.ToDo;
            } else if (this.taskProgress == 100) {
                task.taskStatusId = TaskStatus.Complete;
            } else {
                task.taskStatusId = TaskStatus.InProgress;
            }
        }

        // 20/09/2023 PMc #2596 - this is handled server-side
        // if (this.projectSettingTaskLockingEnabled && task.taskStatusId == TaskStatus.Complete) {
        //     task.taskLocked = true;
        // }

        this.modalSave.emit(task);
    }

    close(): void {
        this.modal.hide();
    }

    onKeyPressMaxLength(ev: KeyboardEvent, maxlength: number): boolean {
        return ((ev.target as HTMLInputElement).value.toString().length < maxlength);
    }

    buildActivityText(activity: ActivityDto): string {
        if (this.isNullOrUndefined(activity)) { return '???'; }
        if (this.areDatesEqual(activity.activityStart, activity.activityFinish)) {
            return `${activity.activityName} (${activity.activityStart.toFormat('D')})`;
        } else {
            return `${activity.activityName} (${activity.activityStart.toFormat('D T')} - ${activity.activityFinish.toFormat('D T')})`;
        }
    }

    getActivity(id: number): ActivityDto {
        let activity = this.activities?.find(l => l.id == id);
        if (this.isNullOrUndefined(activity)) { return undefined; }
        return activity;
    }

    showHistory(): void {
        this.close();
        this.entityTypeHistoryModal.show({
            entityId: this.id.toString(),
            entityTypeFullName: this._entityTypeFullName,
            entityTypeDescription: '',
        });
    }

    deleteTask(): void {
        this.saving = true;

        let context = this;
        this.message.confirm(this.l('AreYouSure'), this.l('WhiteboardDeleteTask'), (isConfirmed) => {
            if (isConfirmed) {
                context.modalDelete.emit(this.id);
                //context.close(); //close handled by parent component      
            } else {
                context.saving = false;
            }
        });
    }

    unlockTask(): void {
        if (this.permission.isGranted('Pages.PullPlanTasks.LockUnlock')) {
            this.taskLocked = false;
        }
    }

    addNewTaskPredecessorLink(): void {
        //check first if there are any links in the list where the task has not been selected
        let check = this.taskPredecessors.filter(p => p.predecessorTaskId == 0);
        if (!this.isNullOrUndefined(check) && check.length > 0) { return; }

        let newlink = new PullPlanTaskPredecessorDto();
        newlink.id = --this.nextNewId;
        newlink.taskId = this.id;
        newlink.taskTitle = this.taskTitle;
        newlink.predecessorTaskId = 0;
        newlink.predecessorTaskTitle = this.l("SelectATask");
        this.taskPredecessors.push(newlink);

        this.startPredecessorTaskEdit(newlink);
    }

    startPredecessorTaskEdit(link: PullPlanTaskPredecessorDto): void {
        this.predecessorLinksTable.initRowEdit(link);
        this.editingPredecessorLink = true;
    }

    editTaskPredecessorLink(linkId: number): void {
        this.editPredecessorLink = this.getPredecessorLink(linkId);
        this.editingPredecessorLink = true;
    }

    acceptEditTaskPredecessorLink(linkId: number): void {
        this.updateLinkedTaskTitles();
        this.editPredecessorLink = null;
        this.editingPredecessorLink = false;
    }

    cancelEditTaskPredecessorLink(linkId: number): void {
        this.editPredecessorLink = null;
        this.editingPredecessorLink = false;
    }

    deleteTaskPredecessorLink(linkId: number): void {
        this.taskPredecessors.forEach(l => {
            if (l.id == linkId) {
                l.deleted = !l.deleted;
                return;
            }
        });
    }

    getPredecessorLink(id: number): PullPlanTaskPredecessorDto {
        for (let i = 0; i < this.taskPredecessors.length; i++) {
            if (this.taskPredecessors[i].id == id) {
                return this.taskPredecessors[i];
            }
        }
        return null;
    }

    addNewTaskSuccessorLink(): void {
        //check first if there are any links in the list where the task has not been selected
        let check = this.taskSuccessors.filter(p => p.taskId == 0);
        if (!this.isNullOrUndefined(check) && check.length > 0) { return; }

        let newlink = new PullPlanTaskPredecessorDto();
        newlink.id = --this.nextNewId;
        newlink.taskId = 0;
        newlink.taskTitle = this.l("SelectATask");
        newlink.predecessorTaskId = this.id; //if this is a new Task then this should be a negative number
        newlink.predecessorTaskTitle = this.taskTitle;
        this.taskSuccessors.push(newlink);

        this.startSuccessorTaskEdit(newlink);
    }

    startSuccessorTaskEdit(link: PullPlanTaskPredecessorDto): void {
        this.successorLinksTable.initRowEdit(link);
        this.editingSuccessorLink = true;
    }

    editTaskSuccessorLink(linkId: number): void {
        this.editSuccessorLink = this.getSuccessorLink(linkId);
        this.editingSuccessorLink = true;
    }

    acceptEditTaskSuccessorLink(linkId: number): void {
        this.updateLinkedTaskTitles();
        this.editSuccessorLink = null;
        this.editingSuccessorLink = false;
    }

    cancelEditTaskSuccessorLink(linkId: number): void {
        this.editSuccessorLink = null;
        this.editingSuccessorLink = false;
    }

    deleteTaskSuccessorLink(linkId: number): void {
        this.taskSuccessors.forEach(l => {
            if (l.id == linkId) {
                l.deleted = !l.deleted;
                return;
            }
        });
    }

    getSuccessorLink(id: number): PullPlanTaskPredecessorDto {
        for (let i = 0; i < this.taskSuccessors.length; i++) {
            if (this.taskSuccessors[i].id == id) {
                return this.taskSuccessors[i];
            }
        }
        return null;
    }

    showHelp(): void {
        if (this.isNullOrUndefinedOrNaN(this.id) || this.id == 0) {
            this.showModalHelp(190);
        } else {
            this.showModalHelp(200);
        }
    }

    updateTaskRiskOwnerUserSelection(): void {
        if (!this.isNullOrUndefinedOrNaNOrZero(this.taskRiskOwnerUserId)) {
            let user = this.userList.find(u => u.id == this.taskRiskOwnerUserId);
            if (!this.isNullOrUndefined(user)) {
                user.selected = true; 
                this.userList = [ ...[], ...this.userList ];
            }
        }
    }

    // onTaskNoteEditorLinkClick(ev: any): void {

    // }

    // onTaskNoteSelectionChange(ev: any): void {

    // }

    getProjectSettingValue(settingName: string): string {
        let setting = this.projectSettings.find(s => s.settingName.toLowerCase() == settingName.toLowerCase());
        return setting?.settingValue;
    }

    updateBuildLocationTitle(): void {
        if (!this.isNullOrUndefinedOrNaNOrZero(this.taskLocationId)) {
            let location = this.buildLocations.find(l => l.id == this.taskLocationId);
            this.taskLocationTitle = location?.locationTitle ?? '';
        } else {
            this.taskLocationTitle = '';
        }
    }
}
