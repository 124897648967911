import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbItem } from '@app/shared/common/sub-header/sub-header.component';

@Injectable()
export class BreadcrumbService {

  private lasturl: string = '';
  private pageBreadcrumbs$ = new BehaviorSubject<any>({});
  breadcrumbs$ = this.pageBreadcrumbs$.asObservable();
  
  constructor(private router: Router) {
    router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if (ev.url != this.lasturl) {
          this.lasturl = ev.url;
          this.setBreadcrumbs([]);
        }
      }
    });
  }

  setBreadcrumbs(breadcrumbs: BreadcrumbItem[]) {
    this.pageBreadcrumbs$.next(breadcrumbs);
  }
}