import { Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomComponentBase } from '@shared/common/custom-component-base';
import { UserListDto } from '@shared/service-proxies/service-proxies';
import { UserSelectDropdownComponent } from '../user-select-dropdown/user-select-dropdown.component';

@Component({
    selector: 'user-link-manager',
    templateUrl: './user-link-manager.component.html',
    styleUrls: ['./user-link-manager.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserLinkManagerComponent),
            multi: true
        }
    ]
})
export class UserLinkManagerComponent extends CustomComponentBase implements OnInit { //OnChanges

    //this component won't add/remove/create links, it will either
    //just display the existing links, or also allow the user to
    //add/remove links from the collection - it is up to the host
    //component to update the links from the collection if the user
    //chooses to save the changes

    @ViewChild('userSelectControl', { static: true }) userSelectControl: UserSelectDropdownComponent;


    private MIN_HEIGHT: string = '250px';

    @Input() id: string = '';
    @Input() name: string = '';
    @Input() readonly: boolean = false;
    @Input() showMultiSelectCount: boolean = true;
    @Input() height: string = this.MIN_HEIGHT;

    private _linkedUsers: UserListDto[] = [];
    @Input() get linkedUsers() {
        return this._linkedUsers;
    }
    set linkedUsers(value: UserListDto[]) {
        this._linkedUsers = value;
        this.refreshUserList();
    }

    private _allUsers: UserListDto[] = [];
    @Input() get allUsers() {
        return this._allUsers;
    }
    set allUsers(value: UserListDto[]) {
        this._allUsers = value;
        this.refreshUserList();
    }

    @Output() changesMade = new EventEmitter();

    userList: UserListDto[] = [];
    addSelectedUserId: number = 0;

    public get dropdownOpen(): boolean {
        return this.userSelectControl ? this.userSelectControl.dropdownOpen : false;
    }

    constructor(private injector: Injector, private _renderer: Renderer2, private _elementRef: ElementRef) {
        super(injector);
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'id');
        this._renderer.removeAttribute(this._elementRef.nativeElement, 'name');
    }

    ngOnInit(): void {

    }

    // ngOnChanges(changes: SimpleChanges): void {

    // }

    refreshUserList(): void {
        //get the difference after intesecting the two arrays
        if (this._linkedUsers == null) { this._linkedUsers = []; }
        if (this._allUsers == null) { this._allUsers = []; }
        try {
            this.userList = this._allUsers.filter(x => !this._linkedUsers.map(t => t.id).includes(x.id)).map(u => { u.selected = false; return u; });
        } catch (error) {
        }
    }

    userSelectionChanged(data: number) {
        this.addSelectedUser();
    }

    formatHeight(): string {
        if (this.height == null || typeof this.height === 'undefined') {
            this.height = this.MIN_HEIGHT;
        }

        this.height = this.height.trim().toLowerCase();

        if (this.height.indexOf('px') == this.height.length - 2) { return this.height; }
        if (this.height.indexOf('%') == this.height.length - 1) { return this.height; }
        if (!isNaN(parseInt(this.height, 10))) { return `${this.height}px`; }

        return this.MIN_HEIGHT;
    }

    getUserById(id: number): UserListDto {
        let user = this._allUsers.find(t => t.id == id);
        if (user == null || typeof user == 'undefined') { return null; }
        return user;
    }

    getSelectedUsers(): UserListDto[] {
        return this.userList.filter(user => user.selected);
    }

    addSelectedUser(): void {
        if (this.userSelectControl.multiSelect) {
            let users = this.getSelectedUsers();
            if (users.length == 0) { return; }
            users.forEach(user => this._linkedUsers.push(user));
        } else {
            if (this.addSelectedUserId == 0) { return; }
            let user = this.getUserById(this.addSelectedUserId);
            if (user == null) { return; }
            this._linkedUsers.push(user);
        }

        this.userSelectControl.clearSelections();
        this.refreshUserList();

        this.changesMade.emit();
    }

    removeUser(id: number): void {
        let idx = -1;
        for (let i = 0; i < this._linkedUsers.length; i++) {
            if (this._linkedUsers[i].id == id) {
                idx = i;
                i = this._linkedUsers.length;
            }
        }

        if (idx < 0) { return; }

        this._linkedUsers.splice(idx, 1);

        this.userSelectControl.clearSelections();
        this.refreshUserList();

        this.changesMade.emit();
    }

    toggleNotifications(user: UserListDto): void {
        user.selected = !user.selected;
    }
}
