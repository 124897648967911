<div class="swal2-container swal2-center">
    <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container" class="swal2-popup swal2-modal swal2-icon-warning swal2-show" 
        tabindex="-1" role="dialog" aria-live="assertive" aria-modal="true" style="display: grid;">
        <div class="swal2-icon swal2-warning swal2-icon-show" style="display: flex;">
            <div class="swal2-icon-content">!</div>
        </div>
        <h2 class="swal2-title" id="swal2-title" style="display: block;">
            <b>{{ l('GeneralUnsavedChangesWarning') }}</b>
            <br /><br />
            {{ l('GeneralUnsavedChangesConfirmation') }}
        </h2>
        <div class="swal2-actions" style="display: flex;">
            <div class="swal2-loader"></div>
            <button type="button" class="swal2-confirm swal2-styled" aria-label="" style="display: inline-block;" (click)="onConfirm()">{{ l('Yes') }}</button>
            <!-- <button type="button" class="swal2-deny swal2-styled" aria-label="" style="display: none;">No</button> -->
            <button type="button" class="swal2-cancel swal2-styled" aria-label="" style="display: inline-block;" (click)="onCancel()">{{ l('Cancel') }}</button>
        </div>
    </div>
</div>