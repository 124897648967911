import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {}

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            // new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'ds-icon-default-desktop-data', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'ds-icon-default-desktop-data', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'ds-icon-default-server', '/app/admin/tenants'), //now renamed to Customers
            new AppMenuItem('Projects', 'Pages.Projects', 'ds-icon-default-building', '/app/main/projectPlanning/projects'),
            new AppMenuItem('PullPlans', 'Pages.PullPlans', 'ds-icon-default-window-data', '/app/main/pullPlanning/pullPlans'),
            new AppMenuItem('ProjectTags', 'Pages.ProjectTags', 'ds-icon-default-tag', '/app/main/projecttags'),
            new AppMenuItem('PullPlanTeams', 'Pages.PullPlanTeams', 'ds-icon-default-puzzle', '/app/main/pullPlanning/pullPlanTeams'),
            new AppMenuItem('RiskCategories', 'Pages.RiskCategories', 'ds-icon-default-warning', '/app/main/pullPlanning/riskCategories'),
            
            new AppMenuItem('BuildLocations', 'Pages.BuildLocations', 'ds-icon-default-table', '/app/main/building/buildLocations'),
            //new AppMenuItem('Editions', 'Pages.Editions', 'ds-icon-default-stack', '/app/admin/editions'),
            
            new AppMenuItem('Administration', '', 'ds-icon-default-list', '', [], [
                // new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'ds-icon-default-org', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'ds-icon-default-certificate', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'ds-icon-default-user-group', '/app/admin/users'),
                //new AppMenuItem('Currencies', 'Pages.Administration.Currencies', 'ds-icon-default-cheque', '/app/admin/general/currencies'),
                // new AppMenuItem('Languages', 'Pages.Administration.Languages', 'ds-icon-default-message', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
                // new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'ds-icon-default-history', '/app/admin/auditLogs'),
                //new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'ds-icon-default-controls', '/app/admin/maintenance'),
                // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'ds-icon-default-certificate', '/app/admin/subscription-management'),
                // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                // new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                // new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
                new AppMenuItem('UserMultiTenantMappingMenu', 'Pages.Administration.Host.UserMultiTenantMapping', 'ds-icon-default-share-social rotate-90', '/app/admin/user-multi-tenant-mappings'), //premission: Pages.Administration.Host.UserMultiTenantMapping
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'ds-icon-default-cog', '/app/admin/hostSettings'),
                new AppMenuItem('Security', 'Pages.Administration.Tenant.Settings', 'ds-icon-default-cog', '/app/admin/tenantSettings'),
            ]),
            // new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components'),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
