<div dropdown class="d-flex align-items-center header-notifications" data-toggle="dropdown" aria-expanded="true" container="body">
    <button type="button" id="notificationIcon" class="notification-icon" dropdownToggle title="{{ 'Notifications' | localize }}">
        <!-- <i *ngIf="unreadNotificationCount" [class]="iconStyle"></i> -->
        <span *ngIf="unreadNotificationCount" class="badge badge-circle badge-warning">
            {{ unreadNotificationCount }}
        </span>
        <!-- standard icon - no unread notifications -->
        <i class="ds-icon-default-notification"></i><!-- *ngIf="!unreadNotificationCount" -->
    </button>
    
    <div *dropdownMenu class="dropdown-menu dropdown-menu-right notifications-menu p-0 m-0">
        <div class="menu-scroll-container"><!-- perfectScrollbar -->
            <div class="d-flex flex-column" style="min-height: 50px;background-color: #A0A0A0;">
                <h4 class="d-flex flex-center" style="margin: 10px;align-items: center; justify-content: space-between">
                    <h3 class="text-white my-0">{{ 'Notifications' | localize }}</h3>
                    <span class="btn btn-text btn-white btn-sm font-weight-bold btn-font-md ms-2">
                        {{ unreadNotificationCount }} {{ 'New' | localize }}
                    </span>
                </h4>
            </div>
            <div class="px-4">
                <div class="scroll pt-5" data-scroll="true" data-height="300" data-mobile-height="200" style="height: 300px; overflow: hidden">
                    <div *ngFor="let notification of notifications" class="d-flex align-items-sm-center mb-7 user-notification-item-clickable user-notification-item-unread"
                        (click)="gotoUrl(notification.url)" [ngClass]="{ 'user-notification-item-clickable': notification.url, 'user-notification-item-unread': notification.isUnread }">
                        <div class="symbol symbol-50px me-5">
                            <span class="symbol-label">
                                <!-- icon for each  notification -->
                                <i class="{{ notification.icon }} {{ notification.iconFontClass }} icon-lg"></i>
                            </span>
                        </div>
                        <div class="d-flex align-items-center flex-row-fluid flex-wrap">
                            <div class="flex-grow-1 me-2">
                                <a class="text-gray-800 text-hover-primary fs-6 fw-bolder">
                                    {{ notification.text }}
                                </a>
                            </div>
                            <span class="text-muted fw-bold d-block fs-7">
                                {{ notification.creationTime | luxonFromNow }}
                            </span>
                            <span *ngIf="notification.isUnread" class="btn btn-link-success text-success set-notification-as-read fs-7 py-0 my-0 set-notification-as-read"
                                (click)="setNotificationAsRead(notification); $event.preventDefault(); $event.stopPropagation()">
                                {{ 'SetAsRead' | localize }}
                            </span>
                        </div>
                    </div>
                    <span *ngIf="!notifications.length" class="notification-empty-text text-center p-5">
                        {{ 'ThereAreNoNotifications' | localize }}
                    </span>
                </div>
                <hr *ngIf="notifications.length" />
                <div *ngIf="notifications.length" class="m-1 mb-4 d-flex" style="align-items: center; justify-content: space-between">
                    <!-- <a *ngIf="unreadNotificationCount" id="setAllNotificationsAsReadLink" class="btn btn-secondary col-md-6 col-xs-12"
                        href="javascript:;" (click)="setAllNotificationsAsRead(); $event.preventDefault(); $event.stopPropagation()">
                        {{ 'SetAllAsRead' | localize }}
                    </a> -->
                    <button type="button" class="ds light outlined me-4" (click)="showNotifications()">
                        {{ 'SeeAllNotifications' | localize }}
                    </button>
                    <button type="button" class="ds light outlined" (click)="openNotificationSettingsModal()">
                        <i class="ds-icon-default-cog"></i>
                        {{ 'NotificationSettings' | localize }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
