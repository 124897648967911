<div class="d-flex align-items-center top-user-menu">
    <button type="button" id="kt_quick_user_toggle" class="top-user-menu-toggler"  data-kt-menu-trigger="click" 
        data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" title="{{ 'UserAccount' | localize }}">
        <i class="ds-icon-default-user"></i>
    </button>

    <!-- dropdown menu for user options -->
    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary user-dropdown-menu fw-bold fs-6"
        data-kt-menu="true">
        <div class="card">
            <div class="card-header py-3">
                <div class="d-flex align-items-center px-3">
                    <div class="symbol symbol-50px me-5">
                        <img class="avatar" alt="Logo" [src]="profilePicture" />
                    </div>
                    <div class="d-flex flex-column">
                        <span class="customer-name">{{ tenantName }}</span>
                        <span class="user-name">{{ userName }}</span>
                        <span class="user-email">{{ emailAddress }}</span>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <!-- Back to My Account -->
                <div id="UserProfileBackToMyAccountButton" class="menu-item" *ngIf="isImpersonatedLogin">
                    <a href="javascript:;" (click)="backToMyAccount()" class="menu-link">
                        <i class="ds-icon-default-chevron-left smaller"></i>
                        <span>{{ 'BackToMyAccount' | localize }}</span>
                    </a>
                </div>
                <!-- Linked Accounts -->
                <!-- <div class="menu-item" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                    <a class="menu-link linked-accounts" href="javascript:;" >
                        <i class="ds-icon-default-link"></i>
                        <span class="me-3">{{ 'ManageLinkedAccounts' | localize }}</span>
                        <i class="ds-icon-default-chevron-right smaller"></i>
                    </a>
                    <div class="menu-sub menu-sub-dropdown w-255px py-4">
                        <div id="ManageLinkedAccountsLink" class="menu-item">
                            <a href="javascript:;" (click)="showLinkedAccounts()" class="menu-link">
                                {{ 'ManageLinkedAccounts' | localize }}
                            </a>
                        </div>
                        <div id="RecentlyUsedLinkedUsers" class="linked-accounts" role="menu">
                            <div class="menu-item" *ngFor="let linkedUser of recentlyLinkedUsers">
                                <a href="javascript;" (click)="switchToLinkedUser(linkedUser)" class="menu-link recently-linked-user">
                                    <i class="ds-icon-default-user-group"></i>
                                    {{ getShownUserName(linkedUser) }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- User Delegation -->
                <!-- <div class="menu-item" *ngIf="appSession.application.userDelegationIsEnabled && !isImpersonatedLogin">
                    <a id="ManageUserDelegations" class="menu-link" href="javascript:;" (click)="showUserDelegations()">
                        <i class="ds-icon-default-unlock"></i>
                        {{ 'ManageUserDelegations' | localize }}
                    </a>
                </div> -->
                <!-- Change Password -->
                <div class="menu-item">
                    <a id="UserProfileChangePasswordLink" class="menu-link" href="javascript:;" (click)="changePassword()">
                        <i class="ds-icon-default-password"></i>
                        <span>{{ 'ChangePassword' | localize }}</span>
                    </a>
                </div>
                <!-- Login Attempts -->
                <div class="menu-item">
                    <a id="ShowLoginAttemptsLink" class="menu-link" href="javascript:;" (click)="showLoginAttempts()">
                        <i class="ds-icon-default-warning"></i>
                        <span>{{ 'LoginAttempts' | localize }}</span>
                    </a>
                </div>
                <!-- Profile Picture -->
                <div class="menu-item">
                    <a id="UserProfileChangePictureLink" class="menu-link" href="javascript:;" (click)="changeProfilePicture()">
                        <i class="ds-icon-default-image"></i>
                        <span>{{ 'ChangeProfilePicture' | localize }}</span>
                    </a>
                </div>
                <!-- My Settings -->
                <div class="menu-item">
                    <a id="UserProfileMySettingsLink" class="menu-link" href="javascript:;" (click)="changeMySettings()">
                        <i class="ds-icon-default-double-cog"></i>
                        <span>{{ 'MySettings' | localize }}</span>
                    </a>
                </div>
                <!-- Visual Settings -->
                <!-- <div class="menu-item" *ngIf="!('Pages.Administration.UiCustomization' | permission)">
                    <a id="UserProfileVisualSettingsLink" class="menu-link" routerLink="admin/ui-customization">
                        <i class="ds-icon-default-image"></i>
                        {{ 'VisualSettings' | localize }}
                    </a>
                </div> -->
                <!-- GDPR -->
                <!-- <div class="menu-item">
                    <a id="UserDownloadCollectedDataLink" class="menu-link" href="javascript:;" (click)="downloadCollectedData()">
                        <i class="ds-icon-default-document-cloud"></i>
                        {{ 'DownloadCollectedData' | localize }}
                    </a>
                </div> -->
                <div class="menu-item">
                    <a id="TermsAndPolicies" class="menu-link" href="https://elecosoft.com/documentation/policies-and-procedures/" target="_blank">
                        <i class="ds-icon-default-sign"></i>
                        <span>{{ 'TandPFooterText' | localize }}</span>
                    </a>
                </div>
            </div>
            <div class="card-footer">
                <span class="version">{{ 'VersionPrefix' | localize }}: {{ uiVersion }}</span>
                <button class="ds dark float-end" (click)="logout()" >
                    <i class="ds-icon-default-arrow-right"></i>
                    {{ 'Logout' | localize }}
                </button>
            </div>
        </div>
    </div>
</div>
