import { Component, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GetProjectForViewDto, NotificationServiceProxy, UserNotification, UserNotificationState } from '@shared/service-proxies/service-proxies';
import { DateTime } from 'luxon';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { IFormattedUserNotification, UserNotificationHelper } from './UserNotificationHelper';
import { finalize } from 'rxjs/operators';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { Router } from '@angular/router';

@Component({
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
})
export class NotificationsComponent extends AppComponentBase {
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    advancedFiltersAreShown = false;
    filterText = '';
    readStateFilter = 'ALL';
    fromDateFilter: DateTime;
    toDateFilter: DateTime;
    initialised: boolean = false;
    loading = false;

    private columnWidths: { [key: string]: number } = {
        actions: 130,
        severity: 110,
        notification: 600,
        creationTime: 220
    };

    constructor(
        injector: Injector,
        private _router: Router,
        private _notificationService: NotificationServiceProxy,
        private _userNotificationHelper: UserNotificationHelper,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.setPageTitle(this.l('Notifications'));
        this.setHelpTopic(380);
        this.clearFilters();
    }

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }

    getColumnWidth(colname: string, fixedWidth: boolean): string {
        let css = `width: ${this.columnWidths[colname]}px;min-width: ${this.columnWidths[colname]}px;`;
        if (fixedWidth) {
            css += `max-width: ${this.columnWidths[colname]}px;`;
        }
        return css;
    }

    clearFilters(): void {
        this.filterText = '';
        this.fromDateFilter = this.setStartOfDay(DateTime.now());
        this.toDateFilter = this.setEndOfDay(DateTime.now());
        this.filterValueChanged();
    }

    // onRowClick(event: Event, record: GetProjectForViewDto): void {
    //     this._router.navigate(['/app/main/projectPlanning/projects', record.project.id, 'details']);
    // }

    setAsRead(record: any): void {
        this.setNotificationAsRead(record, () => {
            this.reloadPage();
        });
    }

    isRead(record: any): boolean {
        return record.formattedNotification.state === 'READ';
    }

    fromNow(date: DateTime): string {
        return this._dateTimeService.fromNow(date);
    }

    formatRecord(record: any): IFormattedUserNotification {
        return this._userNotificationHelper.format(record, false);
    }

    formatNotification(record: any): string {
        const formattedRecord = this.formatRecord(record);
        return abp.utils.truncateStringWithPostfix(formattedRecord.text, 120);
    }

    formatNotifications(records: any[]): any[] {
        const formattedRecords = [];
        for (const record of records) {
            record.formattedNotification = this.formatRecord(record);
            formattedRecords.push(record);
        }
        return formattedRecords;
    }

    truncateString(text: any, length: number): string {
        return abp.utils.truncateStringWithPostfix(text, length);
    }

    filterValueChanged(ctrl?: string): void {
        if (this.initialised) {
            setTimeout(() => this.getNotifications(), 5);
        }
    }

    toggleFilters(): void {
        this.advancedFiltersAreShown = !this.advancedFiltersAreShown;
        this.filterValueChanged();
    }

    getNotifications(event?: LazyLoadEvent): void {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            if (this.paginator.currentPage() != 0) {
                this.paginator.changePage(0);
                return;
            }
        }

        this.primengTableHelper.showLoadingIndicator();

        let context = this;
        let startDate = this.setStartOfDay(this.fromDateFilter);
        let endDate = this.setEndOfDay(this.toDateFilter);

        //Note: API endpoint would need modifying to accept filterText

        this._notificationService.getUserNotifications(
            this.readStateFilter === 'ALL' ? undefined : UserNotificationState.Unread,
            startDate,
            endDate,
            this.primengTableHelper.getMaxResultCount(this.paginator, event),
            this.primengTableHelper.getSkipCount(this.paginator, event)).subscribe({
                next: (result) => {
                    context.primengTableHelper.totalRecordsCount = result.totalCount;
                    context.primengTableHelper.records = context.formatNotifications(result.items);
                    context.primengTableHelper.hideLoadingIndicator();
                    context.initialised = true;
                },
                error: (err) => {
                    context.primengTableHelper.hideLoadingIndicator();
                }
            });
    }

    setAllNotificationsAsRead(): void {
        let context = this;
        this._userNotificationHelper.setAllAsRead(() => {
            context.notify.success(context.l('NotificationsAllMarkedRead'));
            context.getNotifications();
        });
    }

    openNotificationSettingsModal(): void {
        this._userNotificationHelper.openSettingsModal();
    }

    setNotificationAsRead(userNotification: UserNotification, callback: () => void): void {
        this._userNotificationHelper.setAsRead(userNotification.id, () => {
            if (callback) { callback(); }
        });
    }

    deleteNotification(userNotification: UserNotification): void {
        this.message.confirm(this.l('NotificationDeleteWarningMessage'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this._notificationService.deleteNotification(userNotification.id).subscribe(() => {
                    this.reloadPage();
                    this.notify.success(this.l('SuccessfullyDeleted'));
                });
            }
        });
    }

    deleteNotifications() {
        this.message.confirm(this.l('DeleteListedNotificationsWarningMessage'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this._notificationService
                    .deleteAllUserNotifications(
                        this.readStateFilter === 'ALL' ? undefined : UserNotificationState.Unread,
                        this.setStartOfDay(this.fromDateFilter),
                        this.setEndOfDay(this.toDateFilter)
                    )
                    .subscribe(() => {
                        this.reloadPage();
                        this.notify.success(this.l('SuccessfullyDeleted'));
                    });
            }
        });
    }

    private setStartOfDay(dt: DateTime): DateTime {
        return this.zeroTime(dt);
    }

    private setEndOfDay(dt: DateTime): DateTime {
        return this.zeroTime(dt).plus({ days: 1 }).minus({ seconds: 1 });
    }

    public getRowClass(formattedRecord: IFormattedUserNotification): string {
        return formattedRecord.state === 'READ' ? 'notification-read' : '';
    }

    getNotificationTextBySeverity(severity: abp.notifications.severity): string {
        switch (severity) {
            case abp.notifications.severity.SUCCESS:
                return this.l('Success');
            case abp.notifications.severity.WARN:
                return this.l('Warning');
            case abp.notifications.severity.ERROR:
                return this.l('Error');
            case abp.notifications.severity.FATAL:
                return this.l('Fatal');
            case abp.notifications.severity.INFO:
            default:
                return this.l('Info');
        }
    }

    setDateFilter(days: number): void {
        switch (days) {
            case 0: //today
                this.fromDateFilter = this.setStartOfDay(DateTime.now());
                this.toDateFilter = this.setEndOfDay(DateTime.now());
                break;
            case 7: //last 7 days
                this.fromDateFilter = this.setStartOfDay(DateTime.now()).minus({ days: 6 });
                this.toDateFilter = this.setEndOfDay(DateTime.now());
                break;
            case 30: //last 30 days
                this.fromDateFilter = this.setStartOfDay(DateTime.now()).minus({ days: 29 });
                this.toDateFilter = this.setEndOfDay(DateTime.now());
                break;
        }
    }
}
