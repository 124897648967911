<div
    appBsModal
    #modal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modal"
    (onShown)="shown()"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" *ngIf="options">
                    {{
                        ('ChangeLogs' | localize) +
                            ':' +
                            (options.entityTypeFullName | localize) +
                            '(' +
                            options.entityTypeDescription +
                            ')'
                    }}
                </h4>
                <button type="button" class="btn-close" (click)="close()" [attr.aria-label]="l('Close')">
                </button>
            </div>
            <div class="modal-body">
                <!--<Primeng-TurboTable-Start>-->
                <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                    <p-table
                        #dataTable
                        (onLazyLoad)="getRecordsIfNeeds($event)"
                        [value]="primengTableHelper.records"
                        rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                        [paginator]="false"
                        [lazy]="true"
                    >
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 15%">
                                    {{ 'Select' | localize }}
                                </th>
                                <th>
                                    {{ 'Action' | localize }}
                                </th>
                                <th>
                                    {{ 'UserName' | localize }}
                                </th>
                                <th>
                                    {{ 'Time' | localize }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-record="$implicit">
                            <tr>
                                <td>
                                    <button
                                        class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                        (click)="showEntityChangeDetails(record)"
                                        title="{{ 'Select' | localize }}"
                                    >
                                        <i class="la la-chevron-circle-right" [attr.aria-label]="l('Select')"></i>
                                    </button>
                                </td>
                                <td>
                                    <span class="p-column-title">{{ 'Action' | localize }}</span>
                                    {{ record.changeTypeName }}
                                </td>
                                <td>
                                    <span class="p-column-title">{{ 'UserName' | localize }}</span>
                                    {{ record.userName }}
                                </td>
                                <td>
                                    <span class="p-column-title">{{ 'Time' | localize }}</span>
                                    {{ record.changeTime | luxonFormat: 'F' }}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                        {{ 'NoData' | localize }}
                    </div>
                    <div class="primeng-paging-container">
                        <p-paginator
                            [rows]="primengTableHelper.defaultRecordsCountPerPage"
                            #paginator
                            (onPageChange)="getRecordsIfNeeds($event)"
                            [totalRecords]="primengTableHelper.totalRecordsCount"
                            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                            [showCurrentPageReport]="true"
                            [currentPageReportTemplate]="
                                'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                            "
                        ></p-paginator>
                    </div>
                </div>
                <!--<Primeng-TurboTable-End>-->
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-light-primary font-weight-bold" (click)="close()">
                    {{ 'Close' | localize }}
                </button>
            </div>
        </div>
    </div>
</div>
<entityChangeDetailModal #entityChangeDetailModal></entityChangeDetailModal>
