import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DynamicCssClassHelper {

    private _document: Document;
    private _stylesheet: HTMLStyleElement;

    constructor(@Inject(DOCUMENT) document: Document) {
        this._document = document;
        if (this._document.getElementsByTagName("head").length > 0) {
            let headElement = this._document.getElementsByTagName("head")[0];
  
            // Create the style sheet element.
            this._stylesheet = this._document.createElement("style");

            //styleSheetElement.type = "text/css"; //<-- apparently .type is deprecated!
            let attrType = this._document.createAttribute('type');
            attrType.value = 'text/css';
            this._stylesheet.attributes.setNamedItem(attrType);

            let attrDynamic = this._document.createAttribute('title');
            attrDynamic.value = 'dynamic-styles';
            this._stylesheet.attributes.setNamedItem(attrDynamic);

            // Append the style sheet element to the head.
            headElement.appendChild(this._stylesheet);
        }
    }

    public isStyleDeclared(selector: string): boolean {
        let found = false;
        for (let i = 0; i < this._stylesheet.sheet.cssRules.length; i++) {
            let rule = this._stylesheet.sheet.cssRules[i];
            let parts = rule.cssText.split('{');
            if (parts[0].indexOf(selector) > -1) { 
                found = true;
                break;
            }
        }
        return found;
    }

    public applyStyleClass(selector: string, style: string): void {
        for (let i = 0; i < this._stylesheet.sheet.cssRules.length; i++) {
            let rule = this._stylesheet.sheet.cssRules[i];
            let parts = rule.cssText.split('{');
            //if (parts[0].indexOf(selector) > -1) {
            if (parts[0].trim() == selector.trim()) { 
                this._stylesheet.sheet.deleteRule(i);
                break;
            }
        }
        this._stylesheet.appendChild(this._document.createTextNode(style));
    }
}