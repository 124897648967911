import { Injectable, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'environments/environment';

@Injectable()
export class Insights {
    private angularPlugin = new AngularPlugin();
    private appInsights;

    constructor(private router: Router) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsights.instrumentationKey,
                extensions: [this.angularPlugin],
                extensionConfig: {
                    [this.angularPlugin.identifier]: {
                        router: this.router,
                        errorServices: [new ErrorHandler()],
                    },
                },
                enableAutoRouteTracking: true
            },
        });
        this.appInsights.loadAppInsights();
    }

    // expose methods that can be used in components and services
    logPageView(name?: string, url?: string) { // option to call manually
//console.log(`AppInsights service::logPageView(name: ${name}, url: ${url})`);
        this.appInsights.trackPageView({ name: name, uri: url, properties: this.AddGlobalProperties() });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
//console.log(`AppInsights service::logEvent(name: ${name})`);
        this.appInsights.trackEvent({ name: name }, this.AddGlobalProperties(properties));
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
//console.log(`AppInsights service::logMetric(name: ${name}, average: ${average})`);
        this.appInsights.trackMetric({ name: name, average: average }, this.AddGlobalProperties(properties));
    }

    logException(exception: Error, severityLevel?: number) {
//console.log(`AppInsights service::logException(severityLevel: ${severityLevel}) exception:`, exception);
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel, properties: this.AddGlobalProperties() });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
//console.log(`AppInsights service::logTrace(message: ${message})`);
        this.appInsights.trackTrace({ message: message }, this.AddGlobalProperties(properties));
    }

    private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
        if (!properties) { properties = {}; }
    
        //static properties
        properties.UIVersion = environment.uiVersion;
    
        return properties;
    }
}